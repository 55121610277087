import React from "react";
import PropTypes from "prop-types";

import "./style.less";

const Statistic = ({ icon, children }) => {
  return (
    <div className="statistic">
      {!!icon && <div className="statistic-icon">{icon}</div>}
      <div className="statistic-content">{children}</div>
    </div>
  );
};

Statistic.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default Statistic;
