import React from "react";
import PropTypes from "prop-types";

import logoDark from "../../../assets/images/app-logo/oracula-logo-dark.svg";
import logoWhite from "../../../assets/images/app-logo/oracula-logo-white.svg";

import "./styles.less";

const AppLogo = ({ alt, isDark }) => {
  return isDark ? (
    <img src={logoDark} className="app-logo" alt={alt} />
  ) : (
    <img src={logoWhite} className="app-logo" alt={alt} />
  );
};

AppLogo.defaultProps = {
  alt: "Logo",
  isDark: true,
};

AppLogo.propTypes = {
  alt: PropTypes.string,
  isDark: PropTypes.bool,
};

export default AppLogo;
