import React, { useState, useEffect } from "react";

import { scroller } from "react-scroll";
import { useSelector } from "react-redux";
// import { format } from "date-fns";
// import { utcToZonedTime } from "date-fns-tz";
import api from "../api";
import * as S from "../store/selectors";

import {
  Grid,
  Row,
  Col,
  Space,
  Pagination,
  Radio,
  Typography,
  Spin,
} from "antd";
import { FireIcon, TrendingUpIcon } from "../components/Icons";
import AppPage from "../components/App/AppPage";
import Container from "../components/Container";
import EventCategories from "../components/Event/EventCategories";
import { EventCardTopic, EventCard } from "../components/Card";

const { useBreakpoint } = Grid;
const { Title } = Typography;

const EVENTS_PAGE_SIZE = 9;

const Home = () => {
  const breakpoint = useBreakpoint();

  const sortOptions = useSelector(S.app.selectConfig).eventSortOptions;
  const categories = useSelector(S.event.selectCategories);
  const tags = useSelector(S.event.selectTags);

  const [topics, setTopics] = useState([]);
  const [isTopicsLoading, setIsTopicsLoading] = useState(true);

  const [trendingEvents, setTrendingEvents] = useState([]);
  const [isTrendingEventsLoading, setIsTrendingEventsLoading] = useState(true);

  const [eventsPage, setEventsPage] = useState(1);
  const [eventsPageSize] = useState(EVENTS_PAGE_SIZE);
  const [eventsSort, setEventsSort] = useState(
    localStorage.getItem("event_sort_option") || sortOptions[0].value
  );
  const [eventsCount, setEventsCount] = useState(0);
  const [events, setEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(true);
  const [isEventsUpdate, setIsEventsUpdate] = useState(false);

  useEffect(() => {
    (async () => {
      const { result } = await api.event.getTopics();
      setTopics(result);
      setIsTopicsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { result } = await api.event.getTrendingEvents();
      setTrendingEvents(result.list);
      setIsTrendingEventsLoading(false);
    })();
  }, []);

  const onChangeEventsPage = (page) => {
    setEventsPage(page);
    scroller.scrollTo("allEventsTitle", {
      duration: 500,
      delay: 0,
      offset: -30,
      smooth: true,
    });
  };

  useEffect(() => {
    (async () => {
      setIsEventsUpdate(true);

      const {
        result: { count, list },
      } = await api.event.getEvents({
        page: eventsPage,
        pageSize: eventsPageSize,
        sort: eventsSort,
        isResultSet: false,
        isRefund: false,
        // gte: format(
        //   utcToZonedTime(new Date(), "UTC"),
        //   "yyyy-MM-dd'T'HH:mm:ss.SSS"
        // ),
      });

      setEventsCount(count);
      setEvents(list);
      setIsEventsUpdate(false);
      setIsEventsLoading(false);
    })();
  }, [eventsPage, eventsPageSize, eventsSort]);

  if (isTopicsLoading || isTrendingEventsLoading || isEventsLoading) {
    return <Spin className="page-loading" />;
  }

  return (
    <AppPage className="page-home">
      <Row gutter={[0, { md: 60, sm: 50, xs: 50 }]}>
        <Col span={12}>
          <EventCategories categories={categories} tags={tags} />
        </Col>

        {!!topics.length && (
          <Col span={12}>
            <Container>
              <Row gutter={[0, { sm: 30, xs: 20 }]}>
                <Col span={12}>
                  <Title level={3}>
                    <Space>
                      Topics on fire now
                      <FireIcon />
                    </Space>
                  </Title>
                </Col>

                <Col span={12}>
                  <Row gutter={20}>
                    {topics.map((item) => (
                      <Col key={item.id} span={6}>
                        <EventCardTopic {...item} />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        )}

        {!!trendingEvents.length && (
          <Col span={12}>
            <Container>
              <Row gutter={[0, { sm: 30, xs: 20 }]}>
                <Col span={12}>
                  <Title level={3}>
                    <Space>
                      Trending events
                      <TrendingUpIcon />
                    </Space>
                  </Title>
                </Col>

                <Col span={12}>
                  <Row gutter={[20, 20]}>
                    {trendingEvents.map((item) => (
                      <Col key={item.id} lg={4} md={6} span={12}>
                        <EventCard {...item} />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        )}

        {!!events.length && (
          <Col span={12}>
            <Container>
              <Row gutter={[0, { sm: 30, xs: 20 }]}>
                <Col span={12}>
                  <Title id="allEventsTitle" level={3}>
                    <Space
                      direction={breakpoint.md ? "horizontal" : "vertical"}
                    >
                      All events
                      <Radio.Group
                        size="small"
                        defaultValue={eventsSort}
                        onChange={({ target }) => {
                          localStorage.setItem(
                            "event_sort_option",
                            target.value
                          );
                          setEventsSort(target.value);
                        }}
                      >
                        {sortOptions.map((item, index) => (
                          <Radio.Button key={index} value={item.value}>
                            {item.title}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Space>
                  </Title>
                </Col>

                <Col span={12}>
                  <Spin spinning={isEventsUpdate}>
                    <Row gutter={[20, 20]}>
                      {events.map((item) => (
                        <Col key={item.id} lg={4} md={6} span={12}>
                          <EventCard {...item} />
                        </Col>
                      ))}
                    </Row>
                  </Spin>
                </Col>

                <Col span={12}>
                  <Pagination
                    pageSize={EVENTS_PAGE_SIZE}
                    current={eventsPage}
                    total={eventsCount}
                    showTotal={false}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    onChange={onChangeEventsPage}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        )}
      </Row>
    </AppPage>
  );
};

export default Home;
