import React from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import { Grid, Avatar } from "antd";

const { useBreakpoint } = Grid;

const AvatarGroup = ({ list }) => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  return (
    <Avatar.Group
      size={{ xl: 32 }}
      maxCount={breakpoint.sm ? 4 : 3}
      maxPopoverTrigger=""
      maxStyle={{
        margin: 0,
        border: 0,
        fontWeight: 600,
        backgroundColor: "transparent",
      }}
    >
      {list.map((item, index) => (
        <Avatar
          key={index}
          src={item.avatar}
          alt={item.nickname}
          onClick={() => navigate(`/user/${item.address}`)}
        />
      ))}
    </Avatar.Group>
  );
};

AvatarGroup.defaultProps = {
  list: [],
};

AvatarGroup.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      nickname: PropTypes.string,
      avatar: PropTypes.string,
    })
  ),
};

export default AvatarGroup;
