import React from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import { Grid, Button } from "antd";
import { BackArrowIcon } from "../Icons";

import "./style.less";

const { useBreakpoint } = Grid;

const BackLink = ({ to, title, size }) => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  return (
    <Button
      size={size ? size : breakpoint.md ? "middle" : "small"}
      className="back-link default-blur"
      onClick={() =>
        to
          ? navigate(to)
          : window.history.state && window.history.state.idx > 0
          ? navigate(-1)
          : navigate("/")
      }
    >
      <BackArrowIcon style={{ fontSize: "12px" }} />
      {title}
    </Button>
  );
};

BackLink.defaultProps = {
  to: "",
  title: "Go back",
  size: null,
};

BackLink.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default BackLink;
