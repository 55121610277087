import Icon from "@ant-design/icons";

import oraculaSvg from "./svg/oraculaSvg";
import trophySvg from "./svg/trophySvg";
import searchSvg from "./svg/searchSvg";
import plusRoundSvg from "./svg/plusRoundSvg";
import userSvg from "./svg/userSvg";
import usersSvg from "./svg/usersSvg";
import userPlusSvg from "./svg/userPlusSvg";
import telegramSvg from "./svg/telegramSvg";
import twitterSvg from "./svg/twitterSvg";
import mediumSvg from "./svg/mediumSvg";
import githubSvg from "./svg/githubSvg";
import arrowLeftSvg from "./svg/arrowLeftSvg";
import arrowRightSvg from "./svg/arrowRightSvg";
import fireSvg from "./svg/fireSvg";
import closeSvg from "./svg/closeSvg";
import fightSvg from "./svg/fightSvg";
import filterSvg from "./svg/filterSvg";
import backArrowSvg from "./svg/backArrowSvg";
import stopWatchSvg from "./svg/stopWatchSvg";
import commentBubbleSvg from "./svg/commentBubbleSvg";
import coinsSvg from "./svg/coinsSvg";
import editSvg from "./svg/editSvg";
import coverUploadSvg from "./svg/coverUploadSvg";
import trashSvg from "./svg/trashSvg";
import avatarUploadSvg from "./svg/avatarUploadSvg";
import minusRoundSvg from "./svg/minusRoundSvg";
import trendingUpSvg from "./svg/trendingUpSvg";
import bugSvg from "./svg/bugSvg";
import metamaskSvg from "./svg/metamaskSvg";
import settingsSvg from "./svg/settingsSvg";
import logoutSvg from "./svg/logoutSvg";
import copySvg from "./svg/copySvg";
import sendMessageSvg from "./svg/sendMessageSvg";
import winFaceSvg from "./svg/winFaceSvg";
import loseFaceSvg from "./svg/loseFaceSvg";
import verifySvg from "./svg/verifySvg";
import shareSvg from "./svg/shareSvg";
import hourglassSvg from "./svg/hourglassSvg";
import leaderboardSvg from "./svg/leaderboardSvg";
import zapSvg from "./svg/zapSvg";
import stopSvg from "./svg/stopSvg";

export const OraculaIcon = (props) => (
  <Icon component={oraculaSvg} {...props} />
);
export const TrophyIcon = (props) => <Icon component={trophySvg} {...props} />;
export const SearchIcon = (props) => <Icon component={searchSvg} {...props} />;
export const PlusRoundIcon = (props) => (
  <Icon component={plusRoundSvg} {...props} />
);
export const UserIcon = (props) => <Icon component={userSvg} {...props} />;
export const UsersIcon = (props) => <Icon component={usersSvg} {...props} />;
export const UserPlusIcon = (props) => (
  <Icon component={userPlusSvg} {...props} />
);
export const TelegramIcon = (props) => (
  <Icon component={telegramSvg} {...props} />
);
export const TwitterIcon = (props) => (
  <Icon component={twitterSvg} {...props} />
);
export const MediumIcon = (props) => <Icon component={mediumSvg} {...props} />;
export const GithubIcon = (props) => <Icon component={githubSvg} {...props} />;
export const ArrowLeftIcon = (props) => (
  <Icon component={arrowLeftSvg} {...props} />
);
export const ArrowRightIcon = (props) => (
  <Icon component={arrowRightSvg} {...props} />
);
export const FireIcon = (props) => <Icon component={fireSvg} {...props} />;
export const CloseIcon = (props) => <Icon component={closeSvg} {...props} />;
export const FightIcon = (props) => <Icon component={fightSvg} {...props} />;
export const FilterIcon = (props) => <Icon component={filterSvg} {...props} />;
export const BackArrowIcon = (props) => (
  <Icon component={backArrowSvg} {...props} />
);
export const StopWatchIcon = (props) => (
  <Icon component={stopWatchSvg} {...props} />
);
export const CommentBubbleIcon = (props) => (
  <Icon component={commentBubbleSvg} {...props} />
);
export const CoinsIcon = (props) => <Icon component={coinsSvg} {...props} />;
export const EditIcon = (props) => <Icon component={editSvg} {...props} />;
export const CoverUploadIcon = (props) => (
  <Icon component={coverUploadSvg} {...props} />
);
export const AvatarUploadIcon = (props) => (
  <Icon component={avatarUploadSvg} {...props} />
);
export const TrashIcon = (props) => <Icon component={trashSvg} {...props} />;
export const MinusRoundIcon = (props) => (
  <Icon component={minusRoundSvg} {...props} />
);
export const TrendingUpIcon = (props) => (
  <Icon component={trendingUpSvg} {...props} />
);
export const BugIcon = (props) => <Icon component={bugSvg} {...props} />;
export const MetamaskIcon = (props) => (
  <Icon component={metamaskSvg} {...props} />
);
export const SettingsIcon = (props) => (
  <Icon component={settingsSvg} {...props} />
);
export const LogoutIcon = (props) => <Icon component={logoutSvg} {...props} />;
export const CopyIcon = (props) => <Icon component={copySvg} {...props} />;
export const SendMessageIcon = (props) => (
  <Icon component={sendMessageSvg} {...props} />
);
export const WinFaceIcon = (props) => (
  <Icon component={winFaceSvg} {...props} />
);
export const LoseFaceIcon = (props) => (
  <Icon component={loseFaceSvg} {...props} />
);
export const VerifyIcon = (props) => <Icon component={verifySvg} {...props} />;
export const ShareIcon = (props) => <Icon component={shareSvg} {...props} />;
export const HourglassIcon = (props) => (
  <Icon component={hourglassSvg} {...props} />
);
export const LeaderboardIcon = (props) => (
  <Icon component={leaderboardSvg} {...props} />
);
export const ZapIcon = (props) => <Icon component={zapSvg} {...props} />;
export const StopIcon = (props) => <Icon component={stopSvg} {...props} />;
