import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Mousewheel, Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SVG from "react-inlinesvg";
import { Row, Col, Button, Typography } from "antd";
import Container from "../../Container";

import "./style.less";

const { Title } = Typography;

const EventCategories = ({ categories, tags }) => {
  return (
    <div className="event-categories">
      <Container>
        <Row gutter={[0, { lg: 40, sm: 20, xs: 20 }]}>
          <Col span={12}>
            <div className="event-categories-item-wrapper">
              {categories.map((item) => (
                <Link
                  key={item.id}
                  className="event-categories-item"
                  to={`/event/category/${item.id}`}
                  type="button"
                >
                  <div className="event-categories-item-icon">
                    <SVG src={item.icon} />
                  </div>

                  <Title
                    className="event-categories-item-title"
                    level={4}
                    style={{
                      backgroundImage: `linear-gradient(142.76deg, rgb(${item.gradient.start}) 13.89%, rgb(${item.gradient.stop}) 85.84%)`,
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                    }}
                  >
                    {item.title}
                  </Title>
                </Link>
              ))}
            </div>
          </Col>

          {!!tags.length && (
            <Col span={12}>
              <div className="event-categories-tags">
                <Swiper
                  modules={[Mousewheel, Autoplay, FreeMode]}
                  spaceBetween={10}
                  slidesPerView="auto"
                  mousewheel
                  freeMode
                  loop
                  loopedSlides={tags.length}
                  speed={2000}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                >
                  {tags
                    .filter((tag) => !tag.isEmpty)
                    .map((tag) => (
                      <SwiperSlide
                        key={tag.id}
                        style={{ width: "auto", padding: "4px 0" }}
                      >
                        <Link to={`/event/tag/${tag.id}`}>
                          <Button className="default-gray-blur" size="small">
                            {`#${tag.title.toLowerCase()}`}
                          </Button>
                        </Link>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

EventCategories.defaultProps = {
  categories: [],
  tags: [],
};

EventCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
};

export default EventCategories;
