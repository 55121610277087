import React, { useState, useCallback, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected } from "./js/utils/web3React";
import Web3 from "web3";
import Web3EthContract from "web3-eth-contract";

import { INTERVAL } from "./js/const";
import axios from "./js/utils/axios";
import api from "./api";

import * as S from "./store/selectors";
import * as A from "./store/actions";
import * as AT from "./store/actionTypes";

import { Routes, Route, useLocation } from "react-router-dom";
import {
  Grid,
  Space,
  Spin,
  Typography,
  Button,
  notification,
  message,
} from "antd";
import AppHeader from "./components/App/AppHeader";
import AppFooter from "./components/App/AppFooter";
import PrivateRoute from "./components/PrivateRoute";
import ConnectWallet from "./components/ConnectWallet";
import IssueButton from "./components/IssueButton";

import Home from "./pages/Home";
import Leaderboard from "./pages/Leaderboard";

import Profile from "./pages/Profile";
import ProfileEditor from "./pages/ProfileEditor";

import PublicProfile from "./pages/PublicProfile";

import { EventContextProvider } from "./contexts/EventContext";
import Event from "./pages/Event";
import EventSubcategory from "./pages/EventSubcategory";
import EventEditor from "./pages/EventEditor";

import NotFound from "./pages/NotFound";

import "./App.less";

const { useBreakpoint } = Grid;
const { Text } = Typography;

const App = () => {
  const location = useLocation();
  const breakpoint = useBreakpoint();
  const { library, account, activate, deactivate, error } = useWeb3React();
  const dispatch = useDispatch();

  const isAppReady = useSelector(S.app.selectIsAppReady);
  const oraculaContract = useSelector(S.app.selectOraculaContract);
  const isAuthenticated = useSelector(S.profile.selectIsAuthenticated);

  const [isConnectWalletVisible, setIsConnectWalletVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    Promise.all([
      dispatch(A.app.getConfig()),
      dispatch(A.app.getContracts()),
      dispatch(A.event.getCategories()),
      dispatch(A.event.getTags()),
    ]).then(() => {
      dispatch({
        type: AT.app.SET_APP_IS_READY,
        payload: { isAppReady: true },
      });
    });
  }, [dispatch]);

  const onUserSignOut = useCallback(() => {
    deactivate();

    delete axios.defaults.headers.common["Authorization"];

    localStorage.removeItem("api_token");
    localStorage.removeItem("account");
    localStorage.setItem("user_disconnect", true);

    dispatch({
      type: AT.profile.SET_PROFILE_INFO,
      payload: {
        info: null,
      },
    });
    dispatch({
      type: AT.profile.SET_ORACULA_BALANCE,
      payload: { balance: "0" },
    });
    dispatch({
      type: AT.profile.SET_IS_ACTIVE,
      payload: { isActive: false },
    });
    dispatch({
      type: AT.profile.SET_IS_AUTHENTICATED,
      payload: { isAuthenticated: false },
    });
  }, [deactivate, dispatch]);

  const onUserSignIn = useCallback(
    async ({ apiToken }) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${apiToken}`;

      const { info, isActive, error } = await api.user.getProfile();

      if ((error && error.code === 401) || info.address !== account) {
        return onUserSignOut();
      }

      dispatch({
        type: AT.profile.SET_PROFILE_INFO,
        payload: { info },
      });
      dispatch({
        type: AT.profile.SET_IS_ACTIVE,
        payload: { isActive },
      });
      dispatch({
        type: AT.profile.SET_IS_AUTHENTICATED,
        payload: { isAuthenticated: true },
      });
      dispatch({
        type: AT.profile.SET_IS_PROFILE_LOADING,
        payload: { isProfileLoading: false },
      });
    },
    [account, onUserSignOut, dispatch]
  );

  const onUserSign = useCallback(
    (account) => {
      const message = JSON.stringify(account);

      library.eth.personal
        .sign(message, account)
        .then(async (signature) => {
          const { result, error } = await api.user.login({
            account,
            signature,
            message,
          });

          if (error) {
            notification.warning({
              message: "Warning",
              description: error.message,
              placement: "top",
            });

            return onUserSignOut();
          }

          const { token: apiToken } = result;

          localStorage.setItem("api_token", apiToken);
          localStorage.setItem("account", account);
          onUserSignIn({ apiToken });
        })
        .catch((error) => {
          notification.warning({
            message: "Warning",
            description: error.message,
            placement: "top",
          });

          onUserSignOut();
        });
    },
    [library, onUserSignIn, onUserSignOut]
  );

  const onChangeNetwork = () => {
    const eth = window.ethereum;

    if (eth) {
      eth.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${parseInt(process.env.REACT_APP_CHAIN_ID, 10).toString(
              16
            )}`,
            chainName: process.env.REACT_APP_EXPLORER_NAME,
            nativeCurrency: {
              name: "BNB",
              symbol: "bnb",
              decimals: 18,
            },
            rpcUrls: [process.env.REACT_APP_PUBLIC_NODE],
            blockExplorerUrls: [process.env.REACT_APP_EXPLORER],
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (error instanceof UnsupportedChainIdError) {
      onUserSignOut();

      message.warning(
        <Text>
          <Space direction={breakpoint.lg ? "horizontal" : "vertical"}>
            Selected network is not support with this site! Change network to
            BSC.{" "}
            <Button type="primary" size="small" onClick={onChangeNetwork}>
              Change network
            </Button>
          </Space>
        </Text>
      );
    }
  }, [error, breakpoint, onUserSignOut]);

  useEffect(() => {
    if (library) {
      Web3EthContract.setProvider(library.givenProvider);
    } else {
      Web3EthContract.setProvider(
        new Web3.providers.HttpProvider(process.env.REACT_APP_PUBLIC_NODE)
      );
    }
  }, [library]);

  useEffect(() => {
    if (account) {
      const apiToken = localStorage.getItem("api_token");
      const prevAccount = localStorage.getItem("account");

      if (apiToken && prevAccount !== account) {
        return onUserSignOut();
      }

      if (apiToken && prevAccount === account) {
        return onUserSignIn({ apiToken });
      }

      onUserSign(account);
    }
  }, [account, onUserSignIn, onUserSignOut, onUserSign]);

  const getBalance = useCallback(async () => {
    const { abi, address } = oraculaContract;
    const contract = new Web3EthContract(JSON.parse(abi), address);
    const balance = await contract.methods.balanceOf(account).call();

    dispatch({ type: AT.profile.SET_ORACULA_BALANCE, payload: { balance } });
  }, [account, oraculaContract, dispatch]);

  useEffect(() => {
    let interval = null;

    if (isAuthenticated && oraculaContract) {
      getBalance();
      interval = setInterval(() => getBalance(), INTERVAL.fast);
    }

    return () => clearInterval(interval);
  }, [isAuthenticated, oraculaContract, getBalance]);

  useEffect(() => {
    const isDisconnect = JSON.parse(localStorage.getItem("user_disconnect"));
    if (isDisconnect) {
      return dispatch({
        type: AT.profile.SET_IS_PROFILE_LOADING,
        payload: { isProfileLoading: false },
      });
    }

    const apiToken = localStorage.getItem("api_token");
    if (!apiToken) {
      return dispatch({
        type: AT.profile.SET_IS_PROFILE_LOADING,
        payload: { isProfileLoading: false },
      });
    }

    activate(injected);
  }, [activate, dispatch]);

  return (
    <div className="root-layout">
      <>
        <AppHeader
          onOpenConnectWallet={() => setIsConnectWalletVisible(true)}
          onSignOut={onUserSignOut}
        />

        {isAppReady ? (
          <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="leaderboard" element={<Leaderboard />} />

            <Route
              path="profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="profile/editor"
              element={
                <PrivateRoute>
                  <ProfileEditor />
                </PrivateRoute>
              }
            />

            <Route path="user/:address" element={<PublicProfile />} />

            <Route
              path="event/:id"
              element={
                <EventContextProvider>
                  <Event />
                </EventContextProvider>
              }
            />
            <Route
              path="event/:subcategory/:id"
              element={<EventSubcategory />}
            />
            <Route
              path="event/create"
              element={
                <PrivateRoute>
                  <EventEditor />
                </PrivateRoute>
              }
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <Spin className="page-loading" />
        )}
        <AppFooter />
      </>

      <ConnectWallet
        isVisible={isConnectWalletVisible}
        onCancel={() => setIsConnectWalletVisible(false)}
      />

      {isAuthenticated && <IssueButton />}
    </div>
  );
};

export default App;
