import React, { useContext } from "react";

import { EventContext } from "../../../contexts/EventContext";

import { Row, Col, Card, Typography } from "antd";
import Container from "../../Container";

import "./style.less";

const { Title, Text } = Typography;

const EventOutcomeSource = () => {
  const { event } = useContext(EventContext);

  return (
    <div className="event-outcome-source">
      <Container>
        <Row gutter={[20, 10]}>
          <Col md={3} span={12}>
            <Title level={2}>Source of outcome</Title>
          </Col>

          <Col md={9} span={12}>
            <Row gutter={[0, { sm: 20, xs: 10 }]}>
              {event.sources.map((item, index) => (
                <Col key={index} span={12}>
                  <a
                    className="event-outcome-source-item"
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Card size="small" bordered={false}>
                      <Text ellipsis>{item.link}</Text>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventOutcomeSource;
