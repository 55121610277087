import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { useWeb3React } from "@web3-react/core";
import Web3EthContract from "web3-eth-contract";

import * as S from "../../../store/selectors";

import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Space,
  Button,
  Dropdown,
  Menu,
  Typography,
  Modal,
  message,
  notification,
} from "antd";
import {
  OraculaIcon,
  TrophyIcon,
  SearchIcon,
  PlusRoundIcon,
  UserIcon,
  EditIcon,
  LogoutIcon,
  CopyIcon,
  CloseIcon,
} from "../../Icons";
import Container from "../../Container";
import AppLogo from "../AppLogo";

import ConnectMetamaskProfile from "../../../assets/images/connect-metamask-profile.png";

import testClaimAbi from "./contract/test-claim.json";

import "./styles.less";

const { useBreakpoint } = Grid;
const { Text, Link: AntLink } = Typography;

const AppHeader = ({ onOpenConnectWallet, onSignOut }) => {
  const { account } = useWeb3React();
  const location = useLocation();
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  // const oraculaContract = useSelector(S.app.selectOraculaContract);
  const isAuthenticated = useSelector(S.profile.selectIsAuthenticated);
  // const isFullAlphaAccess = useSelector(S.profile.selectIsFullAlphaAccess);
  // const profileInfo = useSelector(S.profile.selectProfileInfo);

  const [isHeaderLight, setIsHeaderLight] = useState(false);

  const [isClaimTestTokens, setIsClaimTestTokens] = useState(false);

  useEffect(() => {
    const isEventPage =
      location.pathname.match(
        "/event/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
      ) !== null;

    const isPublicProfile =
      location.pathname.match("/user/0x[a-fA-F0-9]{40}$") !== null;

    const isProfile = location.pathname === "/profile";

    if (isEventPage || isPublicProfile || isProfile) {
      setIsHeaderLight(true);
    } else {
      setIsHeaderLight(false);
    }
  }, [location]);

  const onCreateEvent = () => {
    if (!isAuthenticated) return message.info("Please log in!");

    navigate("event/create");
  };

  const onClaimTestOracula = () => {
    const contract = new Web3EthContract(
      testClaimAbi,
      "0xDEE22765a8Fa0f99a0dd581d4B13cfE41a14DECD"
    );

    contract.methods
      .claimTokens()
      .send({ from: account })
      .on("error", (error) => {
        notification.error({
          message: "Transaction error",
          description: error.message,
        });
      })
      .on("transactionHash", (tx) => {
        setIsClaimTestTokens(true);

        notification.info({
          message: "Transaction send",
          description: (
            <>
              Follow the transaction on{" "}
              <AntLink
                href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                target="_blank"
                rel="noreferrer"
              >
                {process.env.REACT_APP_EXPLORER_NAME}
              </AntLink>
            </>
          ),
        });
      })
      .once("confirmation", (_confirmationNumber, receipt) => {
        setIsClaimTestTokens(false);

        const { status, transactionHash: tx } = receipt;

        if (status) {
          notification.success({
            message: "Transaction completed",
            description: (
              <>
                More info at{" "}
                <AntLink
                  href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_EXPLORER_NAME}
                </AntLink>
              </>
            ),
          });
        }
      });
  };

  return (
    <header className={`app-header ${isHeaderLight ? "app-header-light" : ""}`}>
      <Container>
        <Row gutter={20} align="middle">
          <Col className="app-header-left" span={4}>
            <Space size={5}>
              <Button
                type="link"
                size="small"
                icon={<SearchIcon style={{ fontSize: "20px" }} />}
                onClick={() => message.info("Coming soon!")}
              />

              <Link to="leaderboard">
                <Button
                  type="link"
                  size="small"
                  icon={<TrophyIcon style={{ fontSize: "20px" }} />}
                />
              </Link>
            </Space>
          </Col>

          <Col className="app-header-center" span={4}>
            <Link className="app-header-logo" to="/">
              <AppLogo isDark={!isHeaderLight} />
            </Link>
          </Col>

          <Col className="app-header-right" span={4}>
            <Space size={5}>
              {/* {breakpoint.md && oraculaContract && (
                <a
                  href={`https://pancakeswap.finance/swap?outputCurrency=${oraculaContract.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    className={isHeaderLight ? "default-white-blur" : ""}
                    type={isHeaderLight ? "default" : "primary"}
                    size="small"
                    ghost={!isHeaderLight}
                    icon={<OraculaIcon />}
                  >
                    {breakpoint.xl && "Buy oracula"}
                  </Button>
                </a>
              )} */}

              {isAuthenticated ? (
                <>
                  <Button
                    className="ant-btn-info-gradient"
                    type="primary"
                    size="small"
                    icon={<PlusRoundIcon />}
                    onClick={onCreateEvent}
                  >
                    {breakpoint.xl && "Create event"}
                  </Button>

                  <Button
                    className={isHeaderLight ? "default-white-blur" : ""}
                    type={isHeaderLight ? "default" : "primary"}
                    size="small"
                    ghost={!isHeaderLight}
                    icon={<OraculaIcon />}
                    loading={isClaimTestTokens}
                    onClick={() =>
                      Modal.info({
                        width: 600,
                        centered: true,
                        closable: true,
                        title: "Claim test tokens",
                        closeIcon: <CloseIcon width="18" height="18" />,
                        content: (
                          <Space direction="vertical" size={0}>
                            <Text>Distribution limit:</Text>
                            <Text>- 1 time per 24 hours (at 12:00 UTC).</Text>
                            <Text>
                              - First 100 addresses can receive 10,000 tokens
                              per 24 hours.
                            </Text>
                            <Text>
                              - 1 address can receive only 100 test tokens per
                              24 hours.
                            </Text>
                          </Space>
                        ),
                        okText: "Claim my test tokens",
                        onOk: onClaimTestOracula,
                      })
                    }
                  >
                    {breakpoint.xl && "Claim test tokens"}
                  </Button>

                  <Dropdown
                    overlay={
                      <Menu className="connect-profile" activeKey="">
                        <div className="connect-profile-header">
                          <Space direction="vertical">
                            <img src={ConnectMetamaskProfile} alt="metamask" />

                            <Text
                              copyable={{ text: account, icon: <CopyIcon /> }}
                            >
                              <small>
                                <b>
                                  {`${account.substring(
                                    0,
                                    6
                                  )}...${account.substring(
                                    account.length - 4,
                                    account.length
                                  )}`}
                                </b>
                              </small>
                            </Text>
                          </Space>
                        </div>

                        <div className="connect-profile-body">
                          <Menu.Divider></Menu.Divider>
                          <Menu.Item key="profile">
                            <Link to="/profile">
                              <Space>
                                <UserIcon />
                                My profile
                              </Space>
                            </Link>
                          </Menu.Item>

                          <Menu.Item key="profile-editor">
                            <Link to="/profile/editor">
                              <Space>
                                <EditIcon />
                                Edit profile
                              </Space>
                            </Link>
                          </Menu.Item>

                          <Menu.Item key="sign-out" onClick={onSignOut}>
                            <Space>
                              <LogoutIcon />
                              Logout
                            </Space>
                          </Menu.Item>
                        </div>
                      </Menu>
                    }
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <Button
                      type="link"
                      size="small"
                      icon={<UserIcon style={{ fontSize: "20px" }} />}
                      onClick={(e) => e.preventDefault()}
                    />
                  </Dropdown>
                </>
              ) : (
                <Button
                  className="ant-btn-info-gradient"
                  type="primary"
                  size="small"
                  onClick={onOpenConnectWallet}
                >
                  {breakpoint.sm ? "Connect wallet" : "Connect"}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

AppHeader.propTypes = {
  onSignOut: PropTypes.func,
};

export default AppHeader;
