const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 14 24"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="m2 2 10 10L2 22"
    />
  </svg>
);

export default svg;
