const svg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5356 3.8076C15.7071 2.63603 17.6066 2.63603 18.7782 3.8076L20.1924 5.22182C21.364 6.39339 21.364 8.29289 20.1924 9.46446L9.7999 19.857C9.6603 19.9966 9.4825 20.0917 9.28891 20.1304L3.98561 21.1911C3.28589 21.331 2.66897 20.7141 2.80892 20.0144L3.86958 14.7111C3.90829 14.5175 4.00345 14.3397 4.14305 14.2001L14.5356 3.8076ZM17.364 5.22182L18.7782 6.63603C19.1687 7.02656 19.1687 7.65972 18.7782 8.05024L17.364 9.46446L14.5356 6.63603L15.9498 5.22182C16.3403 4.83129 16.9735 4.83129 17.364 5.22182ZM13.1213 8.05025L5.77136 15.4002L5.06425 18.9358L8.59978 18.2287L15.9498 10.8787L13.1213 8.05025Z"
      fill="currentColor"
    />
  </svg>
);

export default svg;
