import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";

import { isPast } from "date-fns";
import distanceInWordsToNow from "date-fns/formatDistanceToNow";
import { BigNumber } from "bignumber.js";

import Web3Utils from "web3-utils";
import Web3EthContract from "web3-eth-contract";

import { INTERVAL } from "../../../js/const";
import * as S from "../../../store/selectors";

import { Link } from "react-router-dom";
import { Row, Col, Space, Typography, Tooltip } from "antd";
import AnimatedNumbers from "../../AnimatedNumbers";
import { StopWatchIcon, FightIcon, OraculaIcon } from "../../Icons";

import "./style.less";

const { Title, Text } = Typography;

const EventCard = ({ ...event }) => {
  const eventContracts = useSelector(S.app.selectEventContracts);
  const gradient = useSelector(S.event.selectCategories).find(
    ({ id }) => event.categoryId === id
  ).gradient;

  const [isEventEnd, setIsEventEnd] = useState(false);
  const [isEventStart, setIsEventStart] = useState(false);
  const [startInText, setStartInText] = useState("");

  const [eventTotalBets, setEventTotalBets] = useState("0");

  useEffect(() => {
    let interval = null;

    const handler = () => {
      if (isPast(new Date(event.endDate))) {
        setIsEventEnd(true);
        return clearInterval(interval);
      }

      setIsEventStart(isPast(new Date(event.startDate)));
      setStartInText(
        distanceInWordsToNow(new Date(event.startDate), { addSuffix: true })
      );
    };

    handler();
    interval = setInterval(handler, 1000);

    return () => clearInterval(interval);
  }, [event]);

  useEffect(() => {
    const { abi: eventAbi, address: eventAddress } = eventContracts.find(
      (item) => item.id === event.contractId
    );
    const contract = new Web3EthContract(JSON.parse(eventAbi), eventAddress);

    const handler = async () => {
      const { totalBets } = await contract.methods
        .eventInfo(event.idOnContract)
        .call();
      setEventTotalBets(totalBets);
    };

    handler();
    let interval = setInterval(handler, INTERVAL.middle);

    return () => clearInterval(interval);
  }, [event, eventContracts]);

  return (
    <Link
      className="event-card"
      to={`/event/${event.id}`}
      style={{
        backgroundImage: `linear-gradient(177.8deg, rgba(255, 255, 255, 0) 1.85%, rgba(23, 182, 217, 0) 34.48%, rgb(${gradient.stop}) 98.15%)`,
      }}
    >
      <div className="event-card-inner">
        <div className="event-card-badges">
          {event.badges.map((item, index) => (
            <Tooltip key={index} title={item.title}>
              <img src={item.image} alt={item.title} />
            </Tooltip>
          ))}
        </div>

        <div className="event-card-info">
          <Row gutter={[0, 20]}>
            <Col span={12}>
              <Title level={3} ellipsis={{ rows: 3, symbol: "..." }}>
                {event.title}
              </Title>
            </Col>

            <Col span={12}>
              <Row gutter={20}>
                <Col span={7}>
                  <Text>
                    <small>
                      {isEventEnd ? (
                        <Space>
                          <FightIcon
                            style={{ flexShrink: "0", fontSize: "20px" }}
                          />
                          <b style={{ textTransform: "uppercase" }}>
                            Completed
                          </b>
                        </Space>
                      ) : isEventStart ? (
                        <Space>
                          <FightIcon
                            style={{ flexShrink: "0", fontSize: "20px" }}
                          />
                          <b style={{ textTransform: "uppercase" }}>Live</b>
                        </Space>
                      ) : (
                        <Space>
                          <StopWatchIcon
                            style={{ flexShrink: "0", fontSize: "20px" }}
                          />
                          <b style={{ textTransform: "uppercase" }}>
                            {`Start ${startInText}`}
                          </b>
                        </Space>
                      )}
                    </small>
                  </Text>
                </Col>

                <Col span={5} style={{ textAlign: "right" }}>
                  <Text>
                    <small>
                      <Space>
                        <OraculaIcon style={{ fontSize: "16px" }} />
                        <b>
                          <AnimatedNumbers
                            value={BigNumber(
                              Web3Utils.fromWei(eventTotalBets)
                            ).toFixed()}
                            style={{ fontSize: "inherit" }}
                          />{" "}
                          $ORC
                        </b>
                      </Space>
                    </small>
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <div className="event-card-image">
        <img src={event.cardImage} alt={event.title} />
      </div>
    </Link>
  );
};

EventCard.propTypes = {
  event: PropTypes.object,
};

export default EventCard;
