import React, { useState, useEffect, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { endOfDay, format, subMinutes } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useSelector } from "react-redux";
import { getBase64 } from "../js/utils";
import api from "../api";
import * as S from "../store/selectors";

import {
  Grid,
  Row,
  Col,
  Space,
  Upload,
  Typography,
  Button,
  Form,
  Input,
  Select,
  Radio,
  Alert,
  notification,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  CoverUploadIcon,
  TrashIcon,
  PlusRoundIcon,
  MinusRoundIcon,
} from "../components/Icons";
import AppPage from "../components/App/AppPage";
import Container from "../components/Container";
import DatePicker from "../components/DatePicker";
import BackLink from "../components/BackLink";

const { useBreakpoint } = Grid;
const { Dragger } = Upload;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ACCESS_TYPES = [
  { title: "Public", value: true, disabled: false },
  { title: "Private", value: false, disabled: true },
];

const disabledDate = (current) => {
  return current && current < endOfDay(new Date());
};

const EventEditor = () => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const [form] = Form.useForm();

  const profileInfo = useSelector(S.profile.selectProfileInfo);
  const сategories = useSelector(S.event.selectCategories);
  const tags = useSelector(S.event.selectTags);

  const [isLastCoverImage, setIsLastCoverImage] = useState(false);
  const [isEnoughCoverImages, setIsEnoughCoverImages] = useState(true);
  const [coverImages, setCoverImages] = useState([
    { preview: null, file: null },
    { preview: null, file: null },
    { preview: null, file: null },
  ]);

  const [estimateDateTime, setEstimateDateTime] = useState(null);

  const [isUpload, setIsUpload] = useState(false);

  const onAddCoverImage = () => {
    const newCoverImages = [...coverImages];
    newCoverImages.push({ preview: null, file: null });

    setCoverImages(newCoverImages);
  };

  const onRemoveCoverImageDragger = (index) => {
    const newCoverImages = [...coverImages];
    newCoverImages.splice(index, 1);

    setCoverImages(newCoverImages);
  };

  const onRemoveCoverImage = (index) => {
    const newCoverImages = [...coverImages];
    newCoverImages[index] = { preview: null, file: null };

    setCoverImages(newCoverImages);
  };

  const beforeUploadCoverImage = useCallback(
    (file, index) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";

      if (!isJpgOrPng) {
        message.error("You can only upload JPEG/PNG file!");
      }

      const isLt3M = file.size / 1024 / 1024 <= 3;

      if (!isLt3M) {
        message.error("Image must be no larger than 3MB!");
      }

      if (isJpgOrPng && isLt3M) {
        getBase64(file, (imageUrl) => {
          const img = new Image();

          img.onload = function () {
            const newCoverImages = [...coverImages];
            newCoverImages[index] = { preview: imageUrl, file };

            setCoverImages(newCoverImages);
          };
          img.src = imageUrl;
        });
      }

      return false;
    },
    [coverImages]
  );

  useEffect(() => {
    const isLast =
      coverImages.filter((image) => image.preview === null).length <= 1;
    setIsLastCoverImage(isLast);

    const isEnough = coverImages.length > 3;
    setIsEnoughCoverImages(isEnough);
  }, [coverImages]);

  const onDateChange = ([startDate, endDate]) => {
    const startDateUtc = utcToZonedTime(startDate, "UTC");
    const endDateUtc = utcToZonedTime(endDate, "UTC");

    setEstimateDateTime({
      endBets: format(subMinutes(startDateUtc, 5), "yyyy-MM-dd HH:mm:ss"),
      startEvent: format(startDateUtc, "yyyy-MM-dd HH:mm:ss"),
      endEvent: format(endDateUtc, "yyyy-MM-dd HH:mm:ss"),
    });
  };

  const onSubmit = async (values) => {
    const isCoverImagesExist = !!coverImages.filter((item) => item.preview)
      .length;

    if (!isCoverImagesExist) {
      return message.warning("Add an event image");
    }

    const {
      categoryId,
      tags,
      title,
      description,
      date,
      isPublic,
      options,
      links,
    } = values;

    const startDateUtc = utcToZonedTime(date[0], "UTC");
    const endDateUtc = utcToZonedTime(date[1], "UTC");
    const startDate = format(startDateUtc, "yyyy-MM-dd HH:mm:ss");
    const endDate = format(endDateUtc, "yyyy-MM-dd HH:mm:ss");

    setIsUpload(true);
    const { error } = await api.event.createEvent({
      categoryId,
      tags,
      title,
      description,
      startDate,
      endDate,
      isPublic,
      coverImages: coverImages.filter(
        (item) => item.preview || (item.preview && item.file)
      ),
      options: options.map((item) => item.option),
      links: links.map((item) => item.link),
    });
    setIsUpload(false);

    if (error) {
      return message.warning(error.message);
    }

    message.success("Your event has been created!");

    navigate("/profile");
  };

  const onSubmitFailed = () => {
    message.warning("Check the correctness of the filled fields");
  };

  return (
    <AppPage className="page-event-editor">
      <Container>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Row gutter={[0, { xl: 40, sm: 20, xs: 20 }]}>
            <Col span={12}>
              <Space>
                <BackLink title={null} size="small" />
                <Title level={2}>Create event</Title>
              </Space>
            </Col>

            {!profileInfo.isEmailVerified && (
              <Col span={12}>
                <Alert
                  message={
                    <Space>
                      <Text>Please verify your email before create event!</Text>
                      <Button
                        className="default-gray-blur"
                        size="small"
                        onClick={() =>
                          navigate("/profile", {
                            state: {
                              isNeedVerifyEmail: true,
                            },
                          })
                        }
                      >
                        Verify email
                      </Button>
                    </Space>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            )}

            <Col span={12}>
              <Row gutter={[20, { sm: 20, xs: 10 }]}>
                <Col xl={6} lg={{ span: 7, order: 0 }} span={12} order={1}>
                  <Row gutter={[20, 20]}>
                    {coverImages.map((image, index) => (
                      <Col
                        key={index}
                        span={index ? (breakpoint.md ? 6 : 12) : 12}
                      >
                        <ImgCrop aspect={2 / 1}>
                          <Dragger
                            className={`page-event-editor-cover ${
                              index
                                ? "page-event-editor-cover-small"
                                : "page-event-editor-cover-large"
                            }`}
                            accept="image/jpeg,image/png"
                            showUploadList={false}
                            beforeUpload={(file) =>
                              beforeUploadCoverImage(file, index)
                            }
                          >
                            {image.preview !== null ? (
                              <>
                                <Button
                                  className="default-white-blur ant-upload-remove-button"
                                  icon={<TrashIcon />}
                                  size="small"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onRemoveCoverImage(index);
                                  }}
                                />

                                <img src={image.preview} alt="Cover" />
                              </>
                            ) : (
                              <div style={{ width: "100%", padding: 20 }}>
                                {isEnoughCoverImages && (
                                  <Button
                                    className="default-blur ant-upload-remove-item-button"
                                    icon={<TrashIcon />}
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onRemoveCoverImageDragger(index);
                                    }}
                                  />
                                )}

                                <Row gutter={[0, 10]}>
                                  <Col span={12}>
                                    <Text type="secondary">
                                      <small>
                                        JPEG, PNG (max file size 3MB).
                                      </small>
                                    </Text>
                                  </Col>

                                  <Col span={12}>
                                    <Text
                                      type="secondary"
                                      style={{
                                        fontSize: 45,
                                        lineHeight: 1,
                                      }}
                                    >
                                      <CoverUploadIcon />
                                    </Text>
                                  </Col>

                                  <Col span={12}>
                                    <Title
                                      level={index ? 5 : 4}
                                      style={{
                                        background:
                                          "linear-gradient(142.76deg, #9816AD 13.89%, #4A00E0 85.84%)",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                      }}
                                    >
                                      <b>Upload file</b>
                                    </Title>
                                  </Col>

                                  {!index && (
                                    <Col span={12}>
                                      <Text type="secondary">
                                        <small>
                                          Drag and Drop File <br /> or browse
                                          media on your device
                                        </small>
                                      </Text>
                                    </Col>
                                  )}
                                </Row>
                              </div>
                            )}
                          </Dragger>
                        </ImgCrop>
                      </Col>
                    ))}

                    {isLastCoverImage && (
                      <Col span={12} style={{ textAlign: "center" }}>
                        <Button type="primary" ghost onClick={onAddCoverImage}>
                          <PlusRoundIcon />
                          Add more
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col xl={6} lg={{ span: 5, order: 1 }} span={12} order={0}>
                  <Form.Item
                    name="categoryId"
                    label="Event category"
                    validateTrigger={["onBlur"]}
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select category"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                    >
                      {сategories.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="tags"
                    label="Tags"
                    tooltip="The tag must not contain special characters or spaces"
                    validateTrigger={["onBlur"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter tags",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      placeholder="Tags"
                      tokenSeparators={[","]}
                      style={{ width: "100%" }}
                      removeIcon={
                        <MinusRoundIcon
                          style={{ fontSize: "12px", color: "#DA0101" }}
                        />
                      }
                      suffixIcon={<div>1</div>}
                    >
                      {tags.map((item) => (
                        <Option
                          key={item.id}
                          value={item.title}
                          label={item.title}
                        >
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="title"
                    label="Title"
                    tooltip="The title should contain a question that can be unambiguously answered by the suggested answers"
                    validateTrigger={["onBlur"]}
                    rules={[{ required: true }, { type: "string" }]}
                  >
                    <Input placeholder="Event title" />
                  </Form.Item>

                  <Form.Item
                    name="date"
                    label="Start and end date of the event"
                    tooltip="Specify the local time of the event, which will be converted to UTC"
                    extra={
                      estimateDateTime ? (
                        <Space direction="vertical">
                          <span>{`End of bets: ${estimateDateTime.endBets} UTC`}</span>
                          <span>{`Start event: ${estimateDateTime.startEvent} UTC`}</span>
                          <span>{`End event: ${estimateDateTime.endEvent} UTC`}</span>
                        </Space>
                      ) : (
                        false
                      )
                    }
                    rules={[{ required: true }]}
                  >
                    <RangePicker
                      showTime
                      suffixIcon={false}
                      placeholder={["Start", "End"]}
                      format="yyyy-MM-dd HH:mm:ss"
                      placement="bottomRight"
                      disabledDate={disabledDate}
                      onChange={onDateChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Row gutter={[0, { md: 16, sm: 0, xs: 0 }]}>
                <Col span={12}>
                  <Row gutter={[20, 10]}>
                    <Col md={3} span={12}>
                      <Title
                        className="required-label-title"
                        level={breakpoint.md ? 3 : 4}
                      >
                        Description
                      </Title>
                    </Col>

                    <Col md={9} span={12}>
                      <Form.Item
                        name="description"
                        validateTrigger={["onBlur"]}
                        rules={[{ type: "string" }]}
                      >
                        <TextArea
                          placeholder="Event description"
                          autoSize={{ minRows: 3 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row gutter={[20, 10]}>
                    <Col md={3} span={12}>
                      <Title
                        className="required-label-title"
                        level={breakpoint.md ? 3 : 4}
                      >
                        Options
                      </Title>
                    </Col>

                    <Col md={9} span={12}>
                      <Form.List
                        name="options"
                        initialValue={[{ option: "" }, { option: "" }]}
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <div key={key} style={{ display: "flex" }}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "option"]}
                                    validateTrigger={["onBlur"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter option title",
                                      },
                                      {
                                        type: "string",
                                      },
                                    ]}
                                    style={{ width: "100%", marginBottom: 14 }}
                                  >
                                    <Input
                                      placeholder={`Option ${index + 1}`}
                                    />
                                  </Form.Item>

                                  {fields.length > 2 && (
                                    <Button
                                      className="dynamic-delete-button"
                                      type="link"
                                      size="small"
                                      danger
                                      icon={<MinusRoundIcon />}
                                      style={{ marginTop: 10, marginLeft: 10 }}
                                      onClick={() => remove(name)}
                                    />
                                  )}
                                </div>
                              )
                            )}

                            <Form.Item>
                              <Button
                                type="primary"
                                size="small"
                                ghost
                                onClick={() => add()}
                              >
                                <PlusRoundIcon />
                                Add option
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row gutter={[20, 10]}>
                    <Col md={3} span={12}>
                      <Title level={breakpoint.md ? 3 : 4}>
                        Source for outcome
                      </Title>
                    </Col>

                    <Col md={9} span={12}>
                      <Form.List name="links" initialValue={[{ url: "" }]}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              ({ key, name, ...restField }, index) => (
                                <div key={key} style={{ display: "flex" }}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "link"]}
                                    validateTrigger={["onBlur"]}
                                    rules={[
                                      {
                                        type: "url",
                                        message:
                                          "Source link is not a valid url",
                                      },
                                    ]}
                                    style={{ width: "100%", marginBottom: 14 }}
                                  >
                                    <Input
                                      placeholder={`Source link ${index + 1}`}
                                    />
                                  </Form.Item>

                                  {fields.length > 1 && (
                                    <Button
                                      className="dynamic-delete-button"
                                      type="link"
                                      size="small"
                                      danger
                                      icon={<MinusRoundIcon />}
                                      style={{ marginTop: 10, marginLeft: 10 }}
                                      onClick={() => remove(name)}
                                    />
                                  )}
                                </div>
                              )
                            )}

                            <Form.Item>
                              <Button
                                type="primary"
                                size="small"
                                ghost
                                onClick={() => add()}
                              >
                                <PlusRoundIcon />
                                Add source
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row gutter={[20, 10]}>
                    <Col md={3} span={12}>
                      <Title level={breakpoint.md ? 3 : 4}>Event type</Title>
                    </Col>

                    <Col md={9} span={12}>
                      <Form.Item
                        name="isPublic"
                        initialValue={ACCESS_TYPES[0].value}
                      >
                        <Radio.Group size="small">
                          {ACCESS_TYPES.map((item, index) => (
                            <Radio.Button
                              key={index}
                              value={item.value}
                              disabled={item.disabled}
                            >
                              {item.title}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col
                  span={12}
                  style={{ textAlign: breakpoint.md ? "right" : "left" }}
                >
                  <Space>
                    <Button type="primary" ghost onClick={() => navigate(-1)}>
                      Cancel
                    </Button>

                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={!profileInfo.isEmailVerified}
                      loading={isUpload}
                    >
                      Add event
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </AppPage>
  );
};

export default EventEditor;
