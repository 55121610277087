import * as AT from "./actionsTypes";
import api from "../../api";

export const getCategories = () => async (dispatch) => {
  const { result: categories } = await api.event.getCategories();

  dispatch({
    type: AT.SET_CATEGORIES,
    payload: { categories },
  });
};

export const getTags = () => async (dispatch) => {
  const { result: tags } = await api.event.getTags();

  dispatch({
    type: AT.SET_TAGS,
    payload: { tags },
  });
};
