import React from "react";

import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "./js/utils/web3React";

import { Provider as StoreProvider } from "react-redux";
import store from "./store";

import { ConfigProvider } from "antd";
import en from "antd/lib/locale/en_GB";

import { BrowserRouter as Router } from "react-router-dom";

const Providers = ({ children }) => {
  const antdConfig = {
    locale: en,
  };

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <StoreProvider store={store}>
        <ConfigProvider {...antdConfig}>
          <Router>{children}</Router>
        </ConfigProvider>
      </StoreProvider>
    </Web3ReactProvider>
  );
};

export default Providers;
