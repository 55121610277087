const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 6a.75.75 0 0 1-.75.75H2.56l3.221 3.219a.751.751 0 0 1-1.062 1.062l-4.5-4.5a.75.75 0 0 1 0-1.062l4.5-4.5a.751.751 0 0 1 1.062 1.062L2.561 5.25h8.69A.75.75 0 0 1 12 6Z"
      clipRule="evenodd"
    />
  </svg>
);

export default svg;
