const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 31 21"
  >
    <path
      fill="currentColor"
      d="M15.043 13.8c-.038-.813-.738-1.437-1.55-1.437h-.399c.458-.284.754-.803.714-1.39-.056-.798-.747-1.403-1.546-1.403h-1.1c.045.536-.129 1.272-.514 1.704a1.716 1.716 0 0 1-.524.4c-.064.031-.043.127.029.127h.393a.47.47 0 0 1 .406.218.445.445 0 0 1-.381.674H8.167a.447.447 0 0 0-.243.071 1.183 1.183 0 0 0-.537.993c0 .407.206.776.537.992a.472.472 0 0 0 .252.074h2.944c.3 0 .515.294.426.576a.47.47 0 0 1-.453.315H8.176a.472.472 0 0 0-.252.073 1.183 1.183 0 0 0-.537.993c0 .406.205.775.537.992a.409.409 0 0 0 .23.072h2.05a.45.45 0 0 1 .438.535.458.458 0 0 1-.455.357H8.174a.448.448 0 0 0-.241.068 1.183 1.183 0 0 0-.546.998c0 .563.403 1.054.952 1.165h2.741c.8 0 1.49-.605 1.546-1.403a1.507 1.507 0 0 0-.713-1.39h.45c.8 0 1.49-.605 1.546-1.402a1.507 1.507 0 0 0-.713-1.39h.34a1.51 1.51 0 0 0 1.507-1.583ZM7.4 10.9c.047.343.242.646.533.833a.446.446 0 0 0 .24.068h.252c.073 0 .094-.1.027-.128a2.433 2.433 0 0 1-.657-.42l-.396-.353Z"
    />
    <path
      fill="currentColor"
      d="M6.495 10.735c0-.198.03-.388.081-.57l-.322-.287a.396.396 0 1 1 .527-.59l1.541 1.375c.52.464 1.299.4 1.744-.099.454-.508.41-1.29-.099-1.744L7.264 6.407a1.233 1.233 0 0 0-1.706.057l-.012.011c-3.521 3.323-4.29 4.146-4.573 4.502C.235 11.908 0 12.598 0 13.598v3.098a4.276 4.276 0 0 0 4.271 4.27h2.582a2.09 2.09 0 0 1-.358-1.163c0-.585.242-1.127.65-1.512a2.073 2.073 0 0 1-.65-1.511c0-.585.242-1.127.65-1.512a2.072 2.072 0 0 1-.65-1.51c0-.586.242-1.127.65-1.512a2.072 2.072 0 0 1-.65-1.511ZM23.076 11.727c.286-.187.478-.487.525-.827l-.617.525a1.922 1.922 0 0 1-.262.197c-.082.052-.043.18.054.18h.064a.41.41 0 0 0 .236-.075ZM20.796 17.844h2.04a.44.44 0 0 0 .24-.071c.332-.218.537-.587.537-.993 0-.407-.205-.776-.537-.992a.447.447 0 0 0-.245-.074h-2.925a.473.473 0 0 1-.406-.216.445.445 0 0 1 .38-.675h2.933a.511.511 0 0 0 .263-.073c.332-.217.537-.586.537-.993 0-.406-.205-.775-.537-.992a.455.455 0 0 0-.245-.072H20.43a.446.446 0 0 1-.383-.672.468.468 0 0 1 .404-.22h.488c.1 0 .135-.133.048-.182a2.566 2.566 0 0 1-.643-.528 2.002 2.002 0 0 1-.506-1.52h-1.1c-.799 0-1.49.604-1.545 1.402-.041.586.255 1.106.713 1.39h-.398c-.813 0-1.512.624-1.55 1.436a1.51 1.51 0 0 0 1.507 1.583h.34a1.507 1.507 0 0 0-.714 1.39c.056.797.747 1.402 1.546 1.402h.45a1.507 1.507 0 0 0-.712 1.39c.055.798.746 1.403 1.545 1.403h2.741c.549-.111.952-.601.952-1.165 0-.406-.205-.775-.536-.992a.443.443 0 0 0-.246-.074h-2.018a.458.458 0 0 1-.454-.357.449.449 0 0 1 .437-.535Z"
    />
    <path
      fill="currentColor"
      d="M30.027 10.977a1.506 1.506 0 0 0-.282-.404l-.738-.754-3.565-3.355a1.233 1.233 0 0 0-1.706-.057L21.033 8.82a1.235 1.235 0 1 0 1.645 1.843l1.541-1.376a.396.396 0 0 1 .573.54c-.039.05-.317.293-.369.339a2.07 2.07 0 0 1-.568 2.08c.408.385.65.926.65 1.511 0 .585-.242 1.127-.65 1.512.408.384.65.926.65 1.511 0 .585-.242 1.126-.65 1.511.408.385.65.927.65 1.512 0 .424-.134.833-.358 1.164h2.582A4.276 4.276 0 0 0 31 16.696v-3.098c0-1-.367-1.916-.973-2.62ZM16.262 7.494V.795a.762.762 0 1 0-1.524 0v6.7a.762.762 0 1 0 1.524 0ZM11.765 7.82a.762.762 0 1 0 1.379-.65l-2.343-4.972a.762.762 0 1 0-1.379.65l2.343 4.971ZM18.22 8.184c.38.18.835.016 1.015-.365l2.343-4.972a.762.762 0 1 0-1.379-.65L17.856 7.17a.762.762 0 0 0 .364 1.014Z"
    />
  </svg>
);

export default svg;
