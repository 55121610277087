import React from "react";
import PropTypes from "prop-types";

import { Space, Typography } from "antd";
import Lottie from "react-lottie";

import HourglassLottie from "../../../assets/lottie/hourglass.json";

import "./style.less";

const { Title } = Typography;

const EventBetsAwait = ({ title }) => {
  return (
    <div className="event-bets-await">
      <Space direction="vertical" align="center">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: HourglassLottie,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={80}
          width={80}
          isClickToPauseDisabled
        />

        <Title level={4}>{title}</Title>
      </Space>
    </div>
  );
};

EventBetsAwait.defaultProps = {
  title: "",
};

EventBetsAwait.propTypes = {
  title: PropTypes.string,
};

export default EventBetsAwait;
