import React, { useState, useContext, useEffect } from "react";

import { format } from "date-fns";
import { BigNumber } from "bignumber.js";

import Web3Utils from "web3-utils";

import { EventContext } from "../../../contexts/EventContext";

import { Row, Col, Table, Typography } from "antd";
import Container from "../../Container";
import AnimatedNumbers from "../../AnimatedNumbers";

const { Title, Text } = Typography;

const columns = [
  {
    title: "Your position",
    dataIndex: "userBetPosition",
    key: "userBetPosition",
  },
  {
    title: "Date & Time",
    dataIndex: "userBetTime",
    key: "userBetTime",
    render: (timestamp) => (
      <Text style={{ whiteSpace: "nowrap" }}>
        {format(new Date(timestamp * 1000), "yyyy-MM-dd HH:mm:ss")}
      </Text>
    ),
  },
  {
    title: "Bet amount ($ORC)",
    dataIndex: "userBetAmount",
    key: "userBetAmount",
    render: (amount) => (
      <AnimatedNumbers
        value={Web3Utils.fromWei(amount)}
        style={{ fontSize: "inherit", fontWeight: "inherit" }}
      />
    ),
  },
  // {
  //   title: "Potential winning",
  //   dataIndex: "winning",
  //   key: "winning",
  //   render: (amount) => (
  //     <AnimatedNumbers
  //       value={amount}
  //       style={{ fontSize: "inherit", fontWeight: "inherit" }}
  //     />
  //   ),
  // },
];

const EventPositions = () => {
  const { event, userBets } = useContext(EventContext);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const { options } = event;

    setDataSource(
      userBets.map((bet, index) => ({
        key: index,
        userBetPosition: options.find(({ id }) => id === bet.outcomeId).title,
        userBetAmount: bet.amount,
        userBetTime: bet.timestamp,
        // winning: new BigNumber(Web3Utils.fromWei(bet.amount))
        //   .multipliedBy(
        //     Web3Utils.fromWei(
        //       coefficients.find(
        //         (coefficient) => coefficient.id === bet.outcomeId
        //       ).value
        //     )
        //   )
        //   .toFixed(),
      }))
    );
  }, [event, userBets]);

  return (
    <div className="event-discussion">
      <Container>
        <Row gutter={[20, 10]}>
          <Col md={3} span={12}>
            <Title level={2}>Your positions</Title>
          </Col>

          <Col md={9} span={12}>
            <Table
              dataSource={dataSource}
              columns={columns}
              footer={false}
              pagination={false}
              scroll={{ x: true }}
              // summary={(pageData) => {
              //   const totalAmount = pageData.reduce(
              //     (acc, item) =>
              //       new BigNumber(acc).plus(item.userBetAmount).toFixed(),
              //     "0"
              //   );

              //   return (
              //     <Table.Summary fixed>
              //       <Table.Summary.Row>
              //         <Table.Summary.Cell index={0} colSpan={2}>
              //           Total bets amount:
              //         </Table.Summary.Cell>

              //         <Table.Summary.Cell index={2}>
              //           <AnimatedNumbers
              //             value={Web3Utils.fromWei(totalAmount)}
              //             style={{ fontSize: "inherit", fontWeight: "inherit" }}
              //           />
              //         </Table.Summary.Cell>
              //       </Table.Summary.Row>
              //     </Table.Summary>
              //   );
              // }}
              locale={{
                emptyText: "No bets",
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventPositions;
