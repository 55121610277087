const isProduction = process.env.NODE_ENV !== "development";

const BSC_BLOCK_TIME = 3000;

const INTERVAL = {
  ultrafast: BSC_BLOCK_TIME * 2,
  fast: BSC_BLOCK_TIME * 3,
  middle: BSC_BLOCK_TIME * 10,
  slow: BSC_BLOCK_TIME * 20,
};

const ORACULA_LINKS = {
  web: "https://oracula.io/",
  telegram: "https://t.me/oracula_io",
  twitter: "https://twitter.com/oracula_io",
  medium: "https://oracula.medium.com/",
  github: "https://github.com/oracula-io",
};

export { isProduction, INTERVAL, ORACULA_LINKS };
