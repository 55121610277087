const svg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 12L8.10557 13.7889C8.83273 14.1524 9.70762 14.0397 10.3188 13.5036C10.9254 12.9715 11.1525 12.1275 10.8961 11.3636L10.895 11.3603C10.8941 11.3572 10.8927 11.3528 10.8911 11.3472C10.869 11.2729 10.7913 10.988 10.7471 10.5851C10.6872 10.0388 10.6917 9.32129 10.9157 8.5747C11.3102 7.25958 11.8572 6.30588 12.4049 5.61855C12.7587 6.85074 13.4053 8.23376 14.5858 9.41422C14.8113 9.63971 15.0573 9.86371 15.3107 10.0944C16.5658 11.2368 18 12.5425 18 15C18 17.6055 15.4622 20 12 20C8.72613 20 6 17.7736 6 14C6 13.4258 6.09373 12.7329 6.24353 11.9962C6.49464 12.3948 6.76542 12.7188 7.02329 12.9767C7.27363 13.2271 7.50868 13.4121 7.69752 13.5428C7.82774 13.633 7.96216 13.7169 8.10381 13.788C8.10381 13.788 8.10557 13.7889 9 12ZM8.96162 8.13304C8.81983 8.64478 8.74994 9.13825 8.72522 9.5916C8.64919 10.9854 9 12 9 12C9 12 8 11.5 7.5 10C7.3353 9.5059 7.11635 8.90329 6.89675 8.31728C6.89054 8.30069 6.884 8.28443 6.87716 8.2685C6.53619 7.47449 5.4352 7.4982 5.1629 8.33812C4.61497 10.0282 4 12.3031 4 14C4 19 7.74745 22 12 22C16.2526 22 20 19 20 15C20 11.5714 17.7959 9.61225 16.5364 8.49272C16.3265 8.30613 16.1429 8.14286 16 8C14.5939 6.5939 14.1521 4.74612 14.0254 3.47461C13.9552 2.77068 13.2759 2.27145 12.6864 2.66244C10.8011 3.91278 9.55753 5.98241 8.96162 8.13304Z"
      fill="currentColor"
    />
  </svg>
);

export default svg;
