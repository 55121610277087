import * as AT from "./actionsTypes";

const initialState = {
  categories: [],
  tags: [],
};

const appReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case AT.SET_CATEGORIES:
      const { categories } = payload;

      return {
        ...state,
        categories,
      };

    case AT.SET_TAGS:
      const { tags } = payload;

      return {
        ...state,
        tags,
      };

    default:
      return state;
  }
};

export default appReducer;
