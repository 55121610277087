import React from "react";
import ReactDOM from "react-dom";

import Provider from "./Provider";
import App from "./App";

import "swiper/swiper.less";
import "swiper/modules/pagination/pagination.less";
import "./css/index.less";

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
