import axios from "../js/utils/axios";

const app = {
  getConfig: async () => {
    const res = await axios.get("/config/");
    const { result } = res.data;

    return {
      result: {
        eventSortOptions: result.ordering_options,
        popularBets: result.popular_bets,
      },
    };
  },

  getContracts: async () => {
    const res = await axios.get("/contracts/");
    const {
      result: { events, token },
    } = res.data;

    return {
      result: {
        events: events.map((item) => ({
          id: item.id,
          name: item.title,
          address: item.address,
          abi: item.abi,
        })),
        oracula: {
          id: token.id,
          address: token.address,
          abi: token.abi,
          symbol: token.symbol,
          decimals: token.decimals,
          image: token.image,
        },
      },
    };
  },
};

export default app;
