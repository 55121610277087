const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 19 19"
  >
    <circle cx="9.5" cy="9.5" r="8.9" stroke="currentColor" strokeWidth="1.2" />
    <path
      fill="currentColor"
      d="M11.142 8.676a1.504 1.504 0 0 0-.487-.72 1.488 1.488 0 0 0-2.258.469 1.513 1.513 0 0 0 .17 1.66 1.492 1.492 0 0 0 1.586.48 1.49 1.49 0 0 0 .885-.737 1.512 1.512 0 0 0 .104-1.152Zm-1.366.638a.785.785 0 0 1-.84-.256.798.798 0 0 1 .236-1.206.785.785 0 0 1 1.129.468.8.8 0 0 1-.525.994Z"
    />
    <path
      fill="currentColor"
      d="M13.406 8.195a3.9 3.9 0 0 0-.403-.458 5.084 5.084 0 0 0-.469-.404c-.17-.113-.335-.236-.518-.34a4.147 4.147 0 0 0-.565-.271 3.578 3.578 0 0 0-.597-.206 3.136 3.136 0 0 0-.62-.122 3.41 3.41 0 0 0-.637-.045 5.863 5.863 0 0 0-.644.026l-.633.108c-.209.045-.408.123-.614.184l-.305.097c-.1.038-.195.088-.29.13a18.27 18.27 0 0 0-.577.271c-.19.095-.359.222-.54.331-.18.109-.358.224-.531.342-.329.26-.66.517-.983.784l-.384.319.3.417c.199.28.42.541.661.784.23.237.472.461.724.673.258.208.527.4.807.576.278.178.568.336.87.472l.45.203c.154.061.313.104.468.158l.469.149c.16.042.323.068.485.097.32.067.646.106.973.115.325.018.652.018.977 0 .324-.03.645-.066.966-.106.321-.04.626-.151.938-.236l.468-.118c.153-.045.298-.113.446-.172l.87-.345a8.49 8.49 0 0 0 .827-.449c.272-.158.544-.311.809-.472.504-.372.363-.417.843-.802-.565.257-.482.193-1.026.472-.282.116-.568.22-.851.328-.284.109-.556.236-.844.316l-.865.236c-.143.038-.284.085-.429.118l-.436.069c-.293.04-.579.109-.87.127-.29.02-.58.015-.87.031a8.032 8.032 0 0 1-.86-.057 4.655 4.655 0 0 1-.843-.148c-.136-.036-.28-.052-.415-.095l-.403-.137c-.134-.047-.272-.087-.403-.141l-.385-.184a5.735 5.735 0 0 1-.74-.414c-.249-.141-.47-.325-.704-.488a4.137 4.137 0 0 1-.635-.58 5.764 5.764 0 0 1-.544-.617l-.084.741c.29-.236.574-.507.862-.762l.47-.33c.154-.107.302-.237.468-.324.166-.087.328-.184.49-.279.082-.044.162-.099.246-.139l.258-.106c.173-.069.342-.156.518-.208l.539-.146c.181-.04.364-.071.548-.092.185-.03.372-.04.558-.03h.282l.279.03c.19.015.378.048.562.097.188.04.372.096.551.165.188.056.359.146.541.222a5.5 5.5 0 0 1 .994.635Z"
    />
  </svg>
);

export default svg;
