const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 21 21"
  >
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M10.5 19.875a9.375 9.375 0 1 0 0-18.75 9.375 9.375 0 1 0 0 18.75Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6.594 10.5h7.812M10.5 6.594v7.812"
    />
  </svg>
);

export default svg;
