import axios from "../js/utils/axios";

const event = {
  getPlayersLeaderboard: async () => {
    const res = await axios.get("/leaderboard/");
    const { result } = res.data;

    return {
      result: result.map(
        ({
          address,
          nickname,
          avatar_img,
          wins,
          loses,
          rating,
          total_earning,
        }) => ({
          address,
          nickname,
          avatar: avatar_img,
          win: wins,
          lose: loses,
          score: rating,
          totalEarning: total_earning,
        })
      ),
    };
  },

  getAuthorsLeaderboard: async () => {
    const res = await axios.get("/creator_leaderboard/");
    const { result } = res.data;

    return {
      result: result.map(
        ({
          address,
          nickname,
          avatar_img,
          events_amount,
          total_bet,
          rating,
        }) => ({
          address,
          nickname,
          avatar: avatar_img,
          eventCount: events_amount,
          betAmount: total_bet,
          score: rating,
        })
      ),
    };
  },
};

export default event;
