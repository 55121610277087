import React from "react";

import {
  Row,
  Col,
  // Space,
  Card,
  Typography,
} from "antd";
import Container from "../../Container";

import CupImg from "../../../assets/images/cup.svg";
// import CoinImg from "../../../assets/images/coins/gold-coin.png";

import "./style.less";

const { Title } = Typography;

const EventJackpot = () => {
  return (
    <div className="event-jackpot">
      <Container>
        <Row gutter={[20, 10]}>
          <Col md={3} span={12}>
            <Title level={2}>Reward / Jackpot</Title>
          </Col>

          <Col md={9} span={12}>
            <Card className="event-jackpot-card" bordered={false}>
              <Row gutter={[0, { sm: 30, xs: 20 }]}>
                <Col span={12}>
                  <img src={CupImg} width={120} alt="Cup" />
                </Col>

                <Col span={12}>
                  <Title level={2}>MONTHLY BONUS JACKPOT</Title>
                </Col>

                {/* <Col span={12}>
                  <Space align="center">
                    <img src={CoinImg} width={44} alt="Coin" />
                    <Title>TBA</Title>
                  </Space>
                </Col> */}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventJackpot;
