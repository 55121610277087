const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
  >
    <path
      fill="currentColor"
      d="M30.206 17.902a3.92 3.92 0 0 1 0-3.825l.892-1.413a1.818 1.818 0 0 0-.892-2.752l-1.552-.616a3.92 3.92 0 0 1-2.252-3.092l-.106-1.668a1.818 1.818 0 0 0-2.338-1.7l-1.583.414a3.92 3.92 0 0 1-3.634-1.18L17.68.786a1.816 1.816 0 0 0-2.89 0l-1.063 1.286a3.921 3.921 0 0 1-3.708 1.179l-1.615-.425a1.817 1.817 0 0 0-2.337 1.7l-.107 1.668a3.92 3.92 0 0 1-2.252 3.092l-1.52.627a1.817 1.817 0 0 0-.892 2.752l.893 1.413a3.92 3.92 0 0 1 0 3.825l-.893 1.413a1.816 1.816 0 0 0 .893 2.752l1.55.616a3.92 3.92 0 0 1 2.21 3.092l.107 1.668a1.817 1.817 0 0 0 2.338 1.7l1.625-.393a3.92 3.92 0 0 1 3.645 1.169l1.062 1.285a1.816 1.816 0 0 0 2.89 0l1.063-1.285a3.92 3.92 0 0 1 3.697-1.169l1.626.414a1.816 1.816 0 0 0 2.337-1.7l.106-1.668a3.922 3.922 0 0 1 2.253-3.092l1.551-.616a1.817 1.817 0 0 0 .893-2.752l-.935-1.434Zm-5.706-5.96-7.023 11.262-.096.148a2.869 2.869 0 0 1-3.942.914c-.3-.188-.562-.43-.775-.712l-4.58-6.056a2.126 2.126 0 1 1 3.007-3.007l2.848 2.189 7.119-7.278a2.139 2.139 0 1 1 3.442 2.54Z"
    />
  </svg>
);

export default svg;
