import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import Web3EthContract from "web3-eth-contract";
import api from "../api";
import * as S from "../store/selectors";

import { Row, Col, Spin } from "antd";
import AppPage from "../components/App/AppPage";
import ProfileHeader from "../components/Profile/ProfileHeader";

const PublicProfile = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { account } = useWeb3React();

  const oraculaContract = useSelector(S.app.selectOraculaContract);

  const [tokenContract, setTokenContract] = useState(null);
  const [isContractInfoLoading, setIsContractInfoLoading] = useState(true);

  const [user, setUser] = useState(null);
  const [userOraculaBalance, setUserOraculaBalance] = useState("0");
  const [isUserLoading, setIsUserLoading] = useState(true);

  // Get user
  useEffect(() => {
    const { address } = params;

    if (
      account &&
      account.toLocaleLowerCase() === address.toLocaleLowerCase()
    ) {
      return navigate("/profile", { replace: true });
    }

    (async () => {
      const { info, error } = await api.user.getUser({ account: address });

      if (error) {
        return navigate("/oops", { replace: true });
      }

      setUser(info);
      setIsUserLoading(false);
    })();
  }, [params, account, navigate]);

  // Event contract
  useEffect(() => {
    if (user) {
      const { abi: tokenAbi, address: tokenAddress } = oraculaContract;

      const tokenContract = new Web3EthContract(
        JSON.parse(tokenAbi),
        tokenAddress
      );

      setTokenContract(tokenContract);
    }
  }, [user, oraculaContract]);

  useEffect(() => {
    const getBalance = async () => {
      const balance = await tokenContract.methods
        .balanceOf(user.address)
        .call();
      setUserOraculaBalance(balance);
    };

    let interval = null;

    if (tokenContract) {
      (async () => {
        await getBalance();

        setIsContractInfoLoading(false);
        setInterval(getBalance, 15000);
      })();
    }

    return () => clearInterval(interval);
  }, [user, tokenContract]);

  if (isUserLoading || isContractInfoLoading) {
    return <Spin className="page-loading" />;
  }

  return (
    <AppPage className="page-profile" coverHeader>
      <Row gutter={[0, { md: 60, sm: 50, xs: 50 }]}>
        <Col span={12}>
          <ProfileHeader
            account={user.address}
            nickname={user.nickname}
            avatar={user.avatar}
            cover={user.cover}
            telegram={user.telegram}
            twitter={user.twitter}
            oraculaBalance={userOraculaBalance}
            isVerifiedEmail={user.isEmailVerified}
            score={user.rating}
          />
        </Col>
      </Row>
    </AppPage>
  );
};

export default PublicProfile;
