import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import * as S from "../../../store/selectors";

import { Link } from "react-router-dom";
import { Typography } from "antd";

import HighlightImg from "../../../assets/images/highlights/event-card-topic-highlight.svg";

import "./style.less";

const { Title } = Typography;

const EventCardTopic = ({ ...topic }) => {
  const gradient = useSelector(S.event.selectCategories).find(
    ({ id }) => topic.categoryId === id
  ).gradient;

  return (
    <Link
      className="event-card-topic"
      to={`/event/topic/${topic.id}`}
      style={{
        backgroundImage: `linear-gradient(93.85deg, rgb(${gradient.start}) 0%, rgb(${gradient.stop}) 100%)`,
      }}
    >
      <div className="event-card-topic-inner">
        <Title className="event-card-topic-title" level={2}>
          {topic.title}
        </Title>
      </div>

      <div className="event-card-topic-image">
        <img src={topic.image} alt={topic.title} />
      </div>

      <img
        className="event-card-topic-highlight"
        src={HighlightImg}
        alt="Highlight"
      />
    </Link>
  );
};

EventCardTopic.defaultProps = {
  to: "/",
};

EventCardTopic.propTypes = {
  to: PropTypes.string,
};

export default EventCardTopic;
