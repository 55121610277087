import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { BigNumber } from "bignumber.js";
import { format } from "date-fns";
import Web3Utils from "web3-utils";
import Web3EthContract from "web3-eth-contract";
import * as S from "../../../store/selectors";

import { Link } from "react-router-dom";
import { Grid, Row, Col, Space, Typography, Tag, Button } from "antd";
import { OraculaIcon, WinFaceIcon, LoseFaceIcon } from "../../Icons";
import Lottie from "react-lottie";

import HourglassLottie from "../../../assets/lottie/hourglass-black.json";

import "./style.less";

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const EventCardClaim = ({ account, ...event }) => {
  const breakpoint = useBreakpoint();

  const eventContracts = useSelector(S.app.selectEventContracts);
  const gradient = useSelector(S.event.selectCategories).find(
    ({ id }) => event.categoryId === id
  ).gradient;

  const [isUserCanClaim, setIsUserCanClaim] = useState(false);
  const [isUserClaimWin, setIsUserClaimWin] = useState(false);
  const [userTotalBet, setUserTotalBet] = useState("0");

  const [isOutcomeIdSet, setIsOutcomeIdSet] = useState(false);

  const [coefficient, setCoefficient] = useState(Web3Utils.toWei("1"));

  const [totalBetsAmount, setTotalBetsAmount] = useState("0");

  const [isRefundAllowed, setIsRefundAllowed] = useState(false);

  useEffect(() => {
    const { abi: eventAbi, address: eventAddress } = eventContracts.find(
      (item) => item.id === event.contractId
    );
    const contract = new Web3EthContract(JSON.parse(eventAbi), eventAddress);

    const getUserInfo = async () => {
      const { winClaimed, outcome, totalBet } = await contract.methods
        .playerInfo(event.idOnContract, account)
        .call();

      let outcomeId = await contract.methods
        .getReverseOutcome(event.idOnContract, outcome)
        .call();

      return {
        winClaimed,
        outcomeId,
        totalBet,
      };
    };

    const getEventInfo = async () => {
      const { totalBets, outcomeSet, realOutcome, refundAllowed } =
        await contract.methods.eventInfo(event.idOnContract).call();

      let outcomeId = null;

      if (outcomeSet) {
        outcomeId = await contract.methods
          .getReverseOutcome(event.idOnContract, realOutcome)
          .call();
      }

      return {
        totalBets,
        outcomeId,
        isOutcomeIdSet: outcomeSet,
        isRefundAllowed: refundAllowed,
      };
    };

    const getEventCoefficients = async () => {
      const { 0: optionsIds, 1: coefficients } = await contract.methods
        .getCoefficients(event.idOnContract)
        .call();

      const result = await Promise.all(
        optionsIds.map(async (item, index) => {
          const id = item;
          // process.env.NODE_ENV === "development" // TODO prod
          //   ? item
          //   : await contract.methods
          //       .getReverseOutcome(event.idOnContract, item)
          //       .call();
          return {
            id,
            value: coefficients[index],
          };
        })
      );

      return result;
    };

    const updateInfo = async () => {
      const [user, event, coefficients] = await Promise.all([
        getUserInfo(),
        getEventInfo(),
        getEventCoefficients(),
      ]);

      setIsUserCanClaim(user.outcomeId === event.outcomeId);
      setIsUserClaimWin(user.winClaimed);
      setUserTotalBet(user.totalBet);

      setIsOutcomeIdSet(event.isOutcomeIdSet);

      if (event.isOutcomeIdSet) {
        setCoefficient(
          coefficients.find(({ id }) => event.outcomeId === id).value
        );
      }

      setTotalBetsAmount(event.totalBets);
    };

    let interval = null;

    if (account && event && eventContracts) {
      (async () => {
        updateInfo();
        interval = setInterval(updateInfo, 10000);
      })();
    }

    return () => clearInterval(interval);
  }, [account, event, eventContracts]);

  return (
    <div className="event-card-claim">
      <Link to={`/event/${event.id}`} className="event-card-claim-link">
        <div className="event-card-claim-link-inner">
          <div className="event-card-claim-link-badges">
            <Tag color="rgba(0,0,0,0.5)">
              {`Completed ${format(new Date(event.endDate), "yyyy-MM-dd")}`}
            </Tag>
          </div>

          <div className="event-card-claim-link-info">
            <Row gutter={[0, 10]}>
              <Col span={12}>
                <Title level={4} ellipsis={{ rows: 3, symbol: "..." }}>
                  {event.title}
                </Title>
              </Col>

              <Col span={12}>
                <Space direction="vertical" size={0}>
                  <Text>
                    <small>Total bets:</small>
                  </Text>
                  <Text>
                    <Space>
                      <OraculaIcon style={{ fontSize: "16px" }} />
                      <b>
                        {`${new BigNumber(
                          Web3Utils.fromWei(totalBetsAmount)
                        ).toFixed(2)} $ORC`}
                      </b>
                    </Space>
                  </Text>
                </Space>
              </Col>
            </Row>
          </div>
        </div>

        <div
          className="event-card-claim-link-overlay"
          style={{
            backgroundImage: `linear-gradient(177.8deg, rgba(255, 255, 255, 0) 1.85%, rgba(23, 182, 217, 0) 34.48%, rgb(${gradient.stop}) 98.15%)`,
          }}
        />

        <div className="event-card-claim-link-image">
          <img src={event.cardImage} alt={event.title} />
        </div>
      </Link>

      <div className="event-card-claim-status">
        {!isOutcomeIdSet ? (
          <Space direction="vertical">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: HourglassLottie,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={58}
              width={58}
              isClickToPauseDisabled
            />
            <Title level={4}>Wait for the result</Title>
          </Space>
        ) : (
          <Space
            className={isUserCanClaim ? "event-bets-win" : "event-bets-lose"}
            direction={breakpoint.sm ? "vertical" : "horizontal"}
            size={20}
          >
            <Space direction="vertical" style={{ textAlign: "center" }}>
              {isUserCanClaim ? (
                <WinFaceIcon
                  style={{ fontSize: breakpoint.sm ? "58px" : "40px" }}
                />
              ) : (
                <LoseFaceIcon
                  style={{ fontSize: breakpoint.sm ? "58px" : "40px" }}
                />
              )}
              <Title level={4}>
                {isUserCanClaim ? "Your win" : "Your lose"}
              </Title>
            </Space>

            <Space direction="vertical" size={5}>
              <Title level={5}>
                {isUserCanClaim && !isUserClaimWin
                  ? "Available to claim:"
                  : isUserCanClaim && isUserClaimWin
                  ? "Your claim:"
                  : "Your bet:"}
              </Title>

              <Text>
                <Space>
                  <OraculaIcon style={{ fontSize: "16px" }} />
                  <b>
                    {isUserCanClaim
                      ? `${new BigNumber(Web3Utils.fromWei(userTotalBet))
                          .multipliedBy(Web3Utils.fromWei(coefficient))
                          .toFixed(2)} $ORC`
                      : `${new BigNumber(
                          Web3Utils.fromWei(userTotalBet)
                        ).toFixed(2)} $ORC`}
                  </b>
                </Space>
              </Text>

              {/* {isUserCanClaim && !isUserClaimWin && (
                <Button type="primary">Claim</Button>
              )} */}
            </Space>
          </Space>
        )}
      </div>
    </div>
  );
};

EventCardClaim.propTypes = {
  account: PropTypes.string,
  event: PropTypes.object,
};

export default EventCardClaim;
