const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 65 65"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.5 0C50.45 0 65 14.55 65 32.5S50.45 65 32.5 65 0 50.45 0 32.5 14.55 0 32.5 0Zm-.158 16.052c6.642 0 12.02 5.377 12.02 12.02s-5.377 12.02-12.02 12.02-12.02-5.377-12.02-12.02c0-6.642 5.377-12.02 12.02-12.02ZM21.587 41.91h21.904c3.559 0 6.485 2.926 6.485 6.485v3.48c-4.666 4.19-10.755 6.72-17.477 6.72-6.643 0-12.81-2.53-17.396-6.72v-3.48c0-3.559 2.925-6.485 6.484-6.485ZM32.5 4.428C48.078 4.428 60.651 17 60.651 32.5c0 15.578-12.573 28.151-28.152 28.151C17 60.651 4.427 48.078 4.427 32.5 4.427 17 17 4.428 32.5 4.428Z"
    />
  </svg>
);

export default svg;
