import * as AT from "./actionsTypes";
import api from "../../api";

export const getConfig = () => async (dispatch) => {
  const { result: config } = await api.app.getConfig();

  dispatch({
    type: AT.SET_CONFIG,
    payload: { config },
  });
};

export const getContracts = () => async (dispatch) => {
  const { result: contracts } = await api.app.getContracts();

  dispatch({
    type: AT.SET_CONTRACTS,
    payload: { contracts },
  });
};
