const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 58 58"
  >
    <path
      fill="currentColor"
      d="M29 58a29 29 0 1 1 29-29 29.03 29.03 0 0 1-29 29Zm0-54.948A25.945 25.945 0 0 0 3.052 28.999 25.95 25.95 0 0 0 29 54.947a25.947 25.947 0 0 0 25.947-25.948A25.976 25.976 0 0 0 29 3.052Z"
    />
    <path
      fill="currentColor"
      d="M39.197 36.447a6.318 6.318 0 0 1-5.403-3.206 1.53 1.53 0 1 1 2.656-1.526 3.235 3.235 0 0 0 5.524 0 1.532 1.532 0 0 1 2.656 1.526 6.286 6.286 0 0 1-5.433 3.206ZM17.828 36.447a6.317 6.317 0 0 1-5.403-3.206 1.53 1.53 0 1 1 2.655-1.526 3.236 3.236 0 0 0 5.525 0 1.532 1.532 0 0 1 2.656 1.526 6.286 6.286 0 0 1-5.434 3.206ZM30.527 48.505h-3.052a1.527 1.527 0 0 1 0-3.052h3.052a1.526 1.526 0 1 1 0 3.052ZM14.073 22.527a8.803 8.803 0 0 1-2.015-.244 1.566 1.566 0 1 1 .703-3.053 5.739 5.739 0 0 0 6.868-4.274 1.566 1.566 0 1 1 3.052.703 8.79 8.79 0 0 1-8.517 6.776l-.091.092ZM43.775 22.527a8.794 8.794 0 0 1-8.425-6.838 1.567 1.567 0 0 1 3.053-.702 5.74 5.74 0 0 0 6.868 4.273 1.566 1.566 0 0 1 .702 3.053 8.804 8.804 0 0 1-2.198.214Z"
    />
  </svg>
);

export default svg;
