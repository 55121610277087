import { combineReducers } from "redux";

import app from "./app/reducers";
import profile from "./profile/reducers";
import event from "./event/reducers";

const rootReducer = combineReducers({
  app,
  profile,
  event,
});

export default rootReducer;
