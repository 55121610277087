const svg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7052 3.38065L14.7929 2.29289C15.1834 1.90237 15.8166 1.90237 16.2071 2.29289C16.5977 2.68342 16.5977 3.31658 16.2071 3.70711L15.2486 4.66568C15.7215 5.32261 16 6.12879 16 7.00003V7.43094C16.6686 7.96294 17.2474 8.6212 17.7134 9.37246L18.7929 8.29292C19.1834 7.9024 19.8166 7.9024 20.2071 8.29292C20.5976 8.68345 20.5976 9.31661 20.2071 9.70714L18.7071 11.2071C18.6711 11.2431 18.6331 11.2758 18.5934 11.3051C18.7626 11.8472 18.8819 12.4148 18.9458 13H21C21.5523 13 22 13.4477 22 14C22 14.5523 21.5523 15 21 15H18.9449C18.8804 15.5826 18.7604 16.1497 18.5902 16.6926C18.6311 16.7225 18.6702 16.756 18.7071 16.7929L20.2071 18.2929C20.5976 18.6834 20.5976 19.3166 20.2071 19.7071C19.8166 20.0977 19.1834 20.0977 18.7929 19.7071L17.711 18.6252C16.466 20.641 14.4169 22 12 22C9.5831 22 7.53404 20.641 6.28903 18.6252L5.20711 19.7071C4.81658 20.0977 4.18342 20.0977 3.79289 19.7071C3.40237 19.3166 3.40237 18.6834 3.79289 18.2929L5.29289 16.7929C5.32983 16.756 5.36894 16.7225 5.4098 16.6926C5.23963 16.1497 5.11959 15.5826 5.0551 15H3C2.44772 15 2 14.5523 2 14C2 13.4477 2.44772 13 3 13H5.05421C5.11807 12.4148 5.23735 11.8472 5.40663 11.3051C5.36691 11.2758 5.32887 11.2431 5.29289 11.2071L3.79289 9.70714C3.40237 9.31661 3.40237 8.68345 3.79289 8.29292C4.18342 7.9024 4.81658 7.9024 5.20711 8.29292L6.28665 9.37246C6.7526 8.6212 7.33137 7.96294 8 7.43094V7.00003C8 6.12879 8.27854 5.32261 8.75143 4.66568L7.79289 3.70714C7.40237 3.31661 7.40237 2.68345 7.79289 2.29292C8.18342 1.9024 8.81658 1.9024 9.20711 2.29292L10.2948 3.38065C10.8121 3.13653 11.3901 3.00003 12 3.00003C12.6099 3.00003 13.1879 3.13653 13.7052 3.38065ZM9.2403 9.00003C7.91687 10.0567 7 11.877 7 14C7 17.0162 8.80271 19.3289 11 19.8764V9.00003H9.2403ZM10 7.00003C10 5.89546 10.8954 5.00003 12 5.00003C13.1046 5.00003 14 5.89546 14 7.00003H10ZM13 9.00003V19.8764C15.1973 19.3289 17 17.0162 17 14C17 11.877 16.0831 10.0567 14.7597 9.00003H13Z"
      fill="currentColor"
    />
  </svg>
);

export default svg;
