const svg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6259 2.52843C12.8388 0.126393 16.7533 2.48927 15.6589 5.56641L14.4885 8.85714H18.1524C20.7663 8.85714 22.1299 11.9674 20.3588 13.8898L13.3741 21.4715C11.1612 23.8736 7.24669 21.5106 8.34115 18.4335L9.51155 15.1429H5.8476C3.23374 15.1429 1.87014 12.0326 3.64119 10.1102L10.6259 2.52843ZM13.7745 4.8962C14.1393 3.87048 12.8345 3.08287 12.0969 3.88354L5.11213 11.4653C4.52178 12.1061 4.97631 13.1429 5.8476 13.1429H10.9286C11.2536 13.1429 11.5583 13.3008 11.7456 13.5663C11.933 13.8318 11.9797 14.1718 11.8708 14.478L10.2255 19.1037C9.86069 20.1294 11.1655 20.9171 11.9032 20.1164L18.8879 12.5347C19.4782 11.8939 19.0237 10.8571 18.1524 10.8571H13.0715C12.7465 10.8571 12.4418 10.6992 12.2544 10.4337C12.067 10.1682 12.0204 9.82821 12.1293 9.52204L13.7745 4.8962Z"
      fill="currentColor"
    />
  </svg>
);

export default svg;
