import React, { useContext, useState, useEffect, useMemo } from "react";
// import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { BigNumber } from "bignumber.js";

import { useWeb3React } from "@web3-react/core";
import Web3Utils from "web3-utils";

import { EventContext } from "../../../contexts/EventContext";
import axios from "../../../js/utils/axios";
import * as S from "../../../store/selectors";

import {
  Grid,
  Row,
  Col,
  Space,
  Typography,
  Button,
  notification,
  message,
} from "antd";
import { OraculaIcon, CoinsIcon } from "../../Icons";
import AnimatedNumbers from "../../AnimatedNumbers";

// import "./style.less";

const { useBreakpoint } = Grid;
const { Text, Link } = Typography;

const EventBetsClaim = ({ optionId }) => {
  const {
    eventContract,
    event,
    // bets,
    outcomeId,
    isUserInEvent,
    isUserCanClaim,
    isUserClaimWin,
    userOutcomeId,
    userTotalBet,
    coefficients,
  } = useContext(EventContext);
  const { account } = useWeb3React();
  const breakpoint = useBreakpoint();

  const isAuthenticated = useSelector(S.profile.selectIsAuthenticated);

  const [isClaimLoading, setIsClaimLoading] = useState(false);

  const coefficient = useMemo(
    () =>
      coefficients.find(({ outcomeId }) => outcomeId === optionId).coefficient,
    [coefficients, optionId]
  );

  const userTotalBetAmount = useMemo(() => {
    if (!isUserInEvent) return "0";

    if (outcomeId !== userOutcomeId) return "0";

    return userTotalBet;
  }, [isUserInEvent, outcomeId, userOutcomeId, userTotalBet]);

  const onClaim = () => {
    if (!isAuthenticated) {
      return message.warning("You must be logged in before claim");
    }

    eventContract.methods
      .claimReward(event.idOnContract)
      .send({ from: account })
      .on("error", (error) => {
        notification.error({
          message: "Transaction error",
          description: error.message,
        });
      })
      .on("transactionHash", (tx) => {
        setIsClaimLoading(true);

        notification.info({
          message: "Transaction send",
          description: (
            <>
              Follow the transaction on{" "}
              <Link
                href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                target="_blank"
                rel="noreferrer"
              >
                {process.env.REACT_APP_EXPLORER_NAME}
              </Link>
            </>
          ),
        });
      })
      .once("confirmation", (_confirmationNumber, receipt) => {
        setIsClaimLoading(false);

        const { status, transactionHash: tx } = receipt;

        if (status) {
          notification.success({
            message: "Transaction completed",
            description: (
              <>
                More info at{" "}
                <Link
                  href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_EXPLORER_NAME}
                </Link>
              </>
            ),
          });
        }
      });
  };

  const onTestClaim = async () => {
    const formData = new FormData();

    formData.append("event_id", event.id);

    const res = await axios.post("/user/claim_signature/", formData);
    const { result } = res.data;

    console.log("result :>> ", result);
  };

  return (
    <div className="event-bets-claim">
      <Row gutter={[20, 20]}>
        <Col lg={4} sm={6} span={12}>
          <Space align="start">
            <CoinsIcon style={{ marginTop: "3px", fontSize: "24px" }} />
            <Space direction="vertical" size={0}>
              <Text>Your bet:</Text>
              <AnimatedNumbers
                value={Web3Utils.fromWei(userTotalBetAmount)}
                style={{ fontSize: breakpoint.sm ? 26 : 20 }}
              />
            </Space>
          </Space>
        </Col>

        <Col lg={4} sm={6} span={12}>
          <Space align="start">
            <OraculaIcon style={{ marginTop: "3px", fontSize: "19px" }} />
            <Space direction="vertical" size={0}>
              <Text>Available to claim:</Text>
              <AnimatedNumbers
                value={new BigNumber(Web3Utils.fromWei(userTotalBetAmount))
                  .multipliedBy(Web3Utils.fromWei(coefficient))
                  .toFixed()}
                style={{
                  fontSize: breakpoint.sm ? 26 : 20,
                  background:
                    "linear-gradient(142.76deg, #FDC830 13.89%, #F37335 85.84%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              />
            </Space>
          </Space>
        </Col>

        <Col lg={4} span={12} style={{ textAlign: "right" }}>
          <Button
            className="default-white-blur"
            block
            disabled={!isUserCanClaim || isUserClaimWin}
            loading={isClaimLoading}
            onClick={onClaim}
          >
            Claim
          </Button>
        </Col>

        <Col span={12} style={{ textAlign: "right" }}>
          <Button className="default-white-blur" block onClick={onTestClaim}>
            Test Claim
          </Button>
        </Col>
      </Row>
    </div>
  );
};

EventBetsClaim.propTypes = {};

export default EventBetsClaim;
