const svg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4H17V10C17 12.7614 14.7614 15 12 15C9.23858 15 7 12.7614 7 10V4ZM5 4C5 2.89543 5.89543 2 7 2H17C18.1046 2 19 2.89543 19 4H21C22.1046 4 23 4.89543 23 6V8C23 10.2091 21.2091 12 19 12H18.7101C17.9417 14.5819 15.7238 16.5395 13 16.9291V20H16C16.5523 20 17 20.4477 17 21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21C7 20.4477 7.44772 20 8 20H11V16.9291C8.27624 16.5395 6.05829 14.5819 5.28988 12H5C2.79086 12 1 10.2091 1 8V6C1 4.89543 1.89543 4 3 4L5 4ZM5 6H3V8C3 9.10457 3.89543 10 5 10V6ZM19 6V10C20.1046 10 21 9.10457 21 8V6H19Z"
      fill="currentColor"
    />
  </svg>
);

export default svg;
