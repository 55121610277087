import * as AT from "./actionsTypes";

const initialState = {
  isAppReady: false,
  config: {
    eventSortOptions: [],
    popularBets: [],
  },
  contracts: {
    events: [],
    oracula: null,
  },
};

const appReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case AT.SET_APP_IS_READY:
      const { isAppReady } = payload;

      return {
        ...state,
        isAppReady,
      };

    case AT.SET_CONFIG:
      const { config } = payload;

      return {
        ...state,
        config,
      };

    case AT.SET_CONTRACTS:
      const { contracts } = payload;

      return {
        ...state,
        contracts,
      };

    default:
      return state;
  }
};

export default appReducer;
