import React, { useState, useContext } from "react";

import { EventContext } from "../../../contexts/EventContext";

import { Row, Col, Typography } from "antd";
import Container from "../../Container";

const { Title, Paragraph } = Typography;

const EventDescription = () => {
  const { event } = useContext(EventContext);

  const [ellipsis] = useState(true);

  return (
    <div>
      <Container>
        <Row gutter={[20, 10]}>
          <Col md={3} span={12}>
            <Title level={2}>Description</Title>
          </Col>

          <Col md={9} span={12}>
            <Paragraph
              ellipsis={
                ellipsis ? { rows: 3, expandable: true, symbol: "more" } : false
              }
            >
              {event.description}
            </Paragraph>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventDescription;
