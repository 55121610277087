import React from "react";

import "./style.less";

const SocialLink = ({ href, children, disabled }) => {
  return (
    <a
      href={href}
      className={`social-link ${disabled ? "social-link-disabled" : ""}`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default SocialLink;
