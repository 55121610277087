import React from "react";

import { Link } from "react-router-dom";
import { Row, Col, Space, Typography, Button } from "antd";
import AppPage from "../components/App/AppPage";
import Container from "../components/Container";

import ErrorImage from "../assets/images/404.svg";

const { Title, Text } = Typography;

const NotFound = () => {
  return (
    <AppPage className="page-not-found">
      <Container>
        <Row gutter={[0, { md: 30, sm: 20, xs: 20 }]}>
          <Col span={12}>
            <img src={ErrorImage} width={600} alt="404" />
          </Col>

          <Col span={12}>
            <Space direction="vertical" size={10}>
              <Title
                style={{
                  background:
                    "linear-gradient(142.76deg, #8E2DE2 13.89%, #4A00E0 85.84%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              >
                Oops
              </Title>
              <Text>Page not found</Text>
            </Space>
          </Col>

          <Col span={12}>
            <Link to="/" replace>
              <Button type="primary">Back to main page</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </AppPage>
  );
};

export default NotFound;
