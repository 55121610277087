import React from "react";

import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as S from "../../store/selectors";

import { Spin } from "antd";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector(S.profile.selectIsAuthenticated);
  const isProfileLoading = useSelector(S.profile.selectIsProfileLoading);

  if (isProfileLoading) {
    return <Spin className="page-loading" />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;
