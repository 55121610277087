import React from "react";

// import { useSelector } from "react-redux";
import { ORACULA_LINKS } from "../../../js/const";
// import { registerToken } from "../../../js/utils/wallet";
// import * as S from "../../../store/selectors";

import { Link } from "react-router-dom";
import { Row, Col, Space, Typography } from "antd";
import {
  TelegramIcon,
  TwitterIcon,
  MediumIcon,
  // OraculaIcon,
  // MetamaskIcon,
  // GithubIcon
} from "../../Icons";
import Container from "../../Container";
import AppLogo from "../AppLogo";
import SocialLink from "../../SocialLink";

import "./style.less";

const { Title, Text } = Typography;

const AppFooter = () => {
  // const oraculaContract = useSelector(S.app.selectOraculaContract);

  return (
    <footer className="app-footer">
      <Container>
        <Row gutter={[20, 30]}>
          <Col xl={7} md={6} span={12}>
            <Row gutter={[0, { lg: 30, sm: 20, xs: 20 }]}>
              <Col span={12}>
                <Link to="/">
                  <AppLogo />
                </Link>
              </Col>

              <Col xl={8} lg={10}>
                <Text>
                  Win, earn, farm and get NFT bonuses all the way. Take part in
                  prediction events with the purpose of making profit,
                  leveraging your knowledge in almost any imaginable field.
                </Text>
              </Col>

              <Col span={12}>
                <Space size="middle">
                  <SocialLink href={ORACULA_LINKS.telegram}>
                    <TelegramIcon />
                  </SocialLink>

                  <SocialLink href={ORACULA_LINKS.twitter}>
                    <TwitterIcon />
                  </SocialLink>

                  <SocialLink href={ORACULA_LINKS.medium}>
                    <MediumIcon />
                  </SocialLink>

                  {/* <SocialLink href={ORACULA_LINKS.github}>
                    <GithubIcon />
                  </SocialLink> */}
                </Space>
              </Col>
            </Row>
          </Col>

          <Col xl={5} md={6} span={12}>
            <Row>
              <Col span={12}>
                <Row gutter={[0, { lg: 30, sm: 20, xs: 20 }]}>
                  <Col span={12}>
                    <Title
                      level={5}
                      type="primary"
                      style={{ textTransform: "uppercase" }}
                    >
                      Resources
                    </Title>
                  </Col>

                  <Col span={12}>
                    <a
                      href="https://oracula.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      About Oracula
                    </a>
                  </Col>

                  <Col span={12}>
                    <a
                      href="https://oracula.io/pitchdeck.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pitchdeck
                    </a>
                  </Col>

                  {/* <Col span={12}>
                    <a
                      href="https://bscscan.com/token/0x85f3ec4EC49aB6a5901278176235957ef521970d"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Token Contract
                    </a>
                  </Col> */}
                </Row>
              </Col>

              {/* <Col span={6}>
                <Row gutter={[0, { lg: 30, sm: 20, xs: 20 }]}>
                  <Col span={12}>
                    <Title
                      level={5}
                      type="primary"
                      style={{ textTransform: "uppercase" }}
                    >
                      Token
                    </Title>
                  </Col>

                  <Col span={12}>
                    <Col span={12}>
                      <a
                        href={`https://pancakeswap.finance/swap?outputCurrency=${oraculaContract.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Space>
                          <OraculaIcon />
                          Buy oracula
                        </Space>
                      </a>
                    </Col>
                  </Col>

                  <Col span={12}>
                    <Col span={12}>
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();

                          registerToken({
                            tokenAddress: oraculaContract.address,
                            tokenSymbol: oraculaContract.symbol,
                            tokenDecimals: oraculaContract.decimals,
                            tokenLogo: oraculaContract.image,
                          });
                        }}
                      >
                        <Space>
                          <MetamaskIcon style={{ fontSize: 20 }} />
                          Add $ORC To Metamask
                        </Space>
                      </a>
                    </Col>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Container>

      <svg className="app-footer-ellipse" fill="none" viewBox="0 0 1064 170">
        <circle cx="464" cy="600" r="600" fill="url(#footer-a)" />
        <defs>
          <linearGradient
            id="footer-a"
            x1="464"
            x2="464"
            y1="0"
            y2="168.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C4C4C4" />
            <stop offset="1" stopColor="#ffffff" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </footer>
  );
};

export default AppFooter;
