import React from "react";

import { useWeb3React } from "@web3-react/core";
import { injected } from "../../js/utils/web3React";

import {
  Modal,
  Row,
  Col,
  Space,
  Typography,
  Button,
  Divider,
  message,
} from "antd";
import { CloseIcon } from "../Icons";

import MetamaskLogo from "../../assets/images/metamask-logo.svg";
import WalletConnectLogo from "../../assets/images/wallet-connect-logo.svg";
import TrustWalletLogo from "../../assets/images/trust-wallet-logo.svg";

import "./style.less";

const { Title, Text } = Typography;

const ConnectWallet = ({ isVisible, onCancel }) => {
  const { activate } = useWeb3React();

  const onConnectToMetamask = async () => {
    await activate(injected);
    localStorage.setItem("user_disconnect", false);
    onCancel();
  };

  return (
    <Modal
      width={390}
      wrapClassName="connect-wallet"
      title="Connect Wallet"
      footer={null}
      centered={true}
      destroyOnClose={true}
      visible={isVisible}
      onCancel={onCancel}
      closeIcon={<CloseIcon width="18" height="18" />}
    >
      <Row>
        <Col span={12}>
          <Row gutter={[20, 50]} align="middle">
            <Col span={6}>
              <button
                className="connect-wallet-button"
                onClick={onConnectToMetamask}
              >
                <Space direction="vertical" size={10}>
                  <img src={MetamaskLogo} width={60} alt="metamask" />
                  <Title level={5}>Metamask</Title>
                </Space>
              </button>
            </Col>

            <Col span={6}>
              <button
                className="connect-wallet-button"
                onClick={() => message.info("Coming soon!")}
              >
                <Space direction="vertical" size={10}>
                  <img src={WalletConnectLogo} width={60} alt="WalletConnect" />
                  <Title level={5}>WalletConnect</Title>
                </Space>
              </button>
            </Col>

            <Col span={6}>
              <button
                className="connect-wallet-button"
                onClick={() => message.info("Coming soon!")}
              >
                <Space direction="vertical" size={10}>
                  <img src={TrustWalletLogo} width={60} alt="trust wallet" />
                  <Title level={5}>Trust Wallet</Title>
                </Space>
              </button>
            </Col>
          </Row>
        </Col>

        <Divider />

        <Col span={12}>
          <Row gutter={[0, 10]}>
            <Col span={12} style={{ textAlign: "center" }}>
              <Text type="secondary">
                <small>
                  <b>Haven’t got a crypto wallet yet?</b>
                </small>
              </Text>
            </Col>

            <Col span={12}>
              <a
                href="https://metamask.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="ant-btn-dark" type="primary" block>
                  Learn How to Connect
                </Button>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ConnectWallet;
