const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 35 24"
  >
    <path
      fill="currentColor"
      d="M8.391 12.141H.471A.475.475 0 0 1 0 11.663c0-.265.211-.479.471-.479h7.92c.26 0 .472.214.472.479 0 .264-.211.478-.472.478ZM10.376 8.444h-7.92a.475.475 0 0 1-.472-.479c0-.264.211-.478.472-.478h7.92c.26 0 .471.214.471.478 0 .265-.21.479-.471.479ZM10.376 15.837h-7.92a.475.475 0 0 1-.472-.478c0-.264.211-.479.472-.479h7.92c.26 0 .471.215.471.479s-.21.478-.471.478ZM23.18 24c-.842 0-1.687-.092-2.51-.273a.54.54 0 0 1 .224-1.054c.75.165 1.52.249 2.287.249 2.863 0 5.557-1.13 7.587-3.18A10.933 10.933 0 0 0 33.938 12c0-2.907-1.112-5.643-3.132-7.704-2.034-2.075-4.742-3.218-7.626-3.218-2.863 0-5.558 1.13-7.588 3.18A10.933 10.933 0 0 0 12.422 12c0 2.907 1.113 5.642 3.133 7.703.209.209.222.56.016.772a.513.513 0 0 1-.74.016l-.03-.03A12.016 12.016 0 0 1 11.36 12c0-3.217 1.237-6.238 3.483-8.506C17.074 1.24 20.035 0 23.181 0c3.168 0 6.144 1.256 8.378 3.536a12.018 12.018 0 0 1 3.442 8.463c0 3.217-1.237 6.238-3.483 8.507C29.287 22.759 26.326 24 23.18 24Z"
    />
    <path
      fill="currentColor"
      d="M15.69 21.074a.383.383 0 0 1-.069-.006l-2.046-.37a.404.404 0 0 1 .139-.795l1.547.279-.103-1.663a.402.402 0 0 1 .372-.43.402.402 0 0 1 .423.379l.135 2.177a.406.406 0 0 1-.132.327.394.394 0 0 1-.265.102ZM23.18 3.411c-4.665 0-8.46 3.852-8.46 8.588 0 4.735 3.795 8.587 8.46 8.587 4.663 0 8.458-3.852 8.458-8.587 0-4.736-3.795-8.588-8.459-8.588Zm.838 9.25c0 .161-.08.31-.212.399a.465.465 0 0 1-.444.038l-4.963-2.2a.479.479 0 0 1-.242-.627c.104-.24.38-.35.617-.245l4.307 1.91V7.682c0-.263.21-.476.468-.476.259 0 .468.213.468.476v4.979Z"
    />
  </svg>
);

export default svg;
