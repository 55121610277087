import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useCountUp } from "react-countup";

import "./style.less";

const CustomAnimatedNumbers = ({
  value,
  decimals,
  separator,
  prefix,
  style,
  onClick,
}) => {
  const countUpRef = useRef(null);
  const { update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: value,
    duration: 1.5,
    decimals,
    separator,
    prefix,
  });

  useEffect(() => {
    update(value);
  }, [value, update]);

  return (
    <div
      ref={countUpRef}
      style={{
        display: "inline-block",
        lineHeight: 1,
        fontWeight: 600,
        ...style,
      }}
      onClick={onClick}
    />
  );
};

CustomAnimatedNumbers.defaultProps = {
  value: 0,
  decimals: 2,
  separator: " ",
  prefix: "",
  style: { fontSize: 32 },
  onClick: () => null,
};

CustomAnimatedNumbers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decimals: PropTypes.number,
  separator: PropTypes.string,
  prefix: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default CustomAnimatedNumbers;
