import axios from "../js/utils/axios";

const user = {
  login: async ({ account, signature, message }) => {
    try {
      const formData = new FormData();

      formData.append("address", account);
      formData.append("signature", signature);
      formData.append("message", message);

      const res = await axios.post("/user/login/", formData);
      const { result } = res.data;

      return { result };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },

  getProfile: async () => {
    try {
      const res = await axios.get("/user/profile/");
      const {
        result: {
          id,
          address,
          avatar_img: avatar,
          banner_img: cover,
          nickname,
          email,
          telegram,
          twitter,
          rating,
          is_email_confirmed: isEmailVerified,
          is_active: isActive,
        },
      } = res.data;

      return {
        info: {
          id,
          address,
          avatar,
          cover,
          nickname,
          email,
          telegram,
          twitter,
          score: rating,
          isEmailVerified,
        },
        isActive,
      };
    } catch ({ response }) {
      const { status } = response;

      return {
        error: {
          code: status,
          message: "",
        },
      };
    }
  },

  udateProfile: async ({ ...data }) => {
    try {
      const formData = new FormData();

      if (data.cover.file) {
        formData.append("banner_img", data.cover.file);
      }

      if (data.avatar.file) {
        formData.append("avatar_img", data.avatar.file);
      }

      formData.append("nickname", data.nickname);
      formData.append("email", data.email);
      formData.append("telegram", data.telegram);
      formData.append("twitter", data.twitter);

      const res = await axios.patch("/user/profile/", formData);
      const {
        result: {
          id,
          address,
          avatar_img: avatar,
          banner_img: cover,
          nickname,
          email,
          telegram,
          twitter,
          rating,
          is_email_confirmed,
          is_active: isActive,
        },
      } = res.data;

      return {
        info: {
          id,
          address,
          avatar,
          cover,
          nickname,
          email,
          telegram,
          twitter,
          rating,
          isEmailConfirmed: is_email_confirmed,
        },
        isActive,
      };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },

  onGetVerifyCode: async () => {
    try {
      const res = await axios.post("/user/send_confirmation_letter/");
      const { result } = res.data;

      return {
        result: {
          message: result,
        },
      };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },

  onSendVerifyCode: async ({ code }) => {
    try {
      const formData = new FormData();
      formData.append("confirm_code", code);

      const res = await axios.post("/user/confirm_email/", formData);
      const { result } = res.data;

      return {
        result: {
          message: result,
        },
      };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },

  createIssue: async ({ ...data }) => {
    try {
      const formData = new FormData();

      if (data.files) {
        data.files.forEach((file, index) => {
          formData.append(`ticket_images[${index}]image`, file);
        });
      }

      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("telegram", data.telegram);
      // formData.append("meta", data.meta);

      await axios.post("/ticket/", formData);

      return {
        error: false,
      };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },

  getEvents: async ({ page = 1, pageSize = "999" } = {}) => {
    const params = `?page=${page}&page_size=${pageSize}`;
    const res = await axios.get(`/user/event/${params}`);
    const {
      result: { events_amount, results },
    } = res.data;

    return {
      result: {
        count: events_amount,
        list: results.map(
          ({
            link,
            id_on_contract,
            contract_id,
            token_id,
            category_id,
            topic_id,
            title,
            description,
            card_image,
            banner_images,
            badges,
            tags,
            options,
            proof_links,
            start_date,
            end_date,
            status,
            is_public,
            is_active,
          }) => ({
            id: link,
            idOnContract:
              id_on_contract !== null ? String(id_on_contract) : null,
            contractId: contract_id,
            tokenId: token_id,
            categoryId: category_id,
            topicId: topic_id,
            title,
            description,
            cardImage: card_image,
            coverImages: banner_images.map(({ link, file }) => ({
              id: link,
              image: file,
            })),
            badges,
            tags,
            options: options.map(({ id, title }) => ({
              id: String(id),
              title,
            })),
            sources: proof_links,
            startDate: start_date,
            endDate: end_date,
            status: status.toUpperCase(),
            isPublic: is_public,
            isActive: is_active,
          })
        ),
      },
    };
  },

  getUser: async ({ account }) => {
    try {
      const res = await axios.get(`/user/${account}/`);
      const {
        result: {
          id,
          address,
          avatar_img: avatar,
          banner_img: cover,
          nickname,
          telegram,
          twitter,
          rating,
          is_email_confirmed: isEmailVerified,
        },
      } = res.data;

      return {
        info: {
          id,
          address,
          avatar,
          cover,
          nickname,
          telegram,
          twitter,
          rating,
          isEmailVerified,
        },
      };
    } catch ({ response }) {
      const {
        status,
        data: { result },
      } = response;

      return {
        error: {
          code: status,
          message: result,
        },
      };
    }
  },
};

export default user;
