import React, { createContext, useState, useCallback, useEffect } from "react";

import { INTERVAL } from "../js/const";

import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { isPast, subMinutes } from "date-fns";

import { useWeb3React } from "@web3-react/core";
import Web3EthContract from "web3-eth-contract";

import confetti from "canvas-confetti";

import api from "../api";
import * as S from "../store/selectors";

import { notification } from "antd";

const EventContext = createContext();

const showConfetti = () => {
  confetti({
    particleCount: 400,
    startVelocity: 30,
    gravity: 0.5,
    spread: 1000,
    origin: {
      x: 0.5,
      y: 0.3,
    },
    colors: ["#8125E2", "#1EB982"],
  });
};

const EventContextProvider = ({ children }) => {
  const params = useParams();
  const navigate = useNavigate();

  const { account } = useWeb3React();

  const eventContracts = useSelector(S.app.selectEventContracts);
  const oraculaContract = useSelector(S.app.selectOraculaContract);

  const isAuthenticated = useSelector(S.profile.selectIsAuthenticated);

  const [eventContract, setEventContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);

  const [event, setEvent] = useState(null);
  const [creator, setCreator] = useState(null);
  const [bets, setBets] = useState([]);
  const [totalBets, setTotalBets] = useState("0");
  const [coefficients, setCoefficients] = useState([]);
  const [outcomeId, setOutcomeId] = useState(null);
  const [isOutcomeSet, setIsOutcomeSet] = useState(false);

  const [isRefundAllowed, setIsRefundAllowed] = useState(false);

  const [isEventLoading, setIsEventLoading] = useState(true);

  const [isEventBetsEnd, setIsEventBetsEnd] = useState(false);
  const [isEventStart, setIsEventStart] = useState(false);
  const [isEventEnd, setIsEventEnd] = useState(false);

  const [users, setUsers] = useState([]);

  const [isUserInEvent, setIsUserInEvent] = useState(false);
  const [isUserCanClaim, setIsUserCanClaim] = useState(false);
  const [isUserClaimWin, setIsUserClaimWin] = useState(false);
  const [userOutcomeId, setUserOutcomeId] = useState(null);
  const [userTotalBet, setUserTotalBet] = useState("0");
  const [userBets, setUserBets] = useState([]);

  useEffect(() => {
    const { id } = params;

    (async () => {
      const { result, error } = await api.event.getEvent({ id });

      if (error) {
        return navigate("/oops", { replace: true });
      }

      setEvent(result);
    })();
  }, []);

  useEffect(() => {
    const checkIsEventBetsEnd = () => {
      const date = subMinutes(new Date(event.startDate), 5);
      const result = isPast(date);
      setIsEventBetsEnd(result);
    };

    const checkIsEventStart = () => {
      const date = new Date(event.startDate);
      const result = isPast(date);
      setIsEventStart(result);
    };

    const checkIsEventEnd = () => {
      const date = new Date(event.endDate);
      const result = isPast(date);
      setIsEventEnd(result);
    };

    let interval = null;

    if (event) {
      checkIsEventBetsEnd();
      checkIsEventStart();
      checkIsEventEnd();

      interval = setInterval(() => {
        checkIsEventBetsEnd();
        checkIsEventStart();
        checkIsEventEnd();
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [event]);

  useEffect(() => {
    if (event) {
      if (!event.idOnContract) {
        notification.error({
          message: "Error",
          description: "Looks like this event doesn't exist!",
          placement: "top",
        });

        return navigate("/oops", { replace: true });
      }

      const { abi: eventAbi, address: eventAddress } = eventContracts.find(
        (item) => item.id === event.contractId
      );
      const { abi: tokenAbi, address: tokenAddress } = oraculaContract;

      const eventContract = new Web3EthContract(
        JSON.parse(eventAbi),
        eventAddress
      );
      const tokenContract = new Web3EthContract(
        JSON.parse(tokenAbi),
        tokenAddress
      );

      setEventContract(eventContract);
      setTokenContract(tokenContract);
    }
  }, [navigate, event, eventContracts, oraculaContract]);

  const getEventInfo = useCallback(async () => {
    const {
      creator,
      totalBets,
      outcomeSet: isOutcomeSet,
      realOutcome,
      refundAllowed: isRefundAllowed,
    } = await eventContract.methods.eventInfo(event.idOnContract).call();

    let outcomeId = null;

    if (isOutcomeSet) {
      outcomeId = await eventContract.methods
        .getReverseOutcome(event.idOnContract, realOutcome)
        .call();
    }

    return {
      creator,
      totalBets,
      outcomeId,
      isOutcomeSet,
      isRefundAllowed,
    };
  }, [eventContract, event]);

  const getEventBets = useCallback(async () => {
    const { players, outcomeIds, betAmounts, timestamps } =
      await eventContract.methods.getAllBetsInEvent(event.idOnContract).call();

    const participants = players.reduce((accumulator, item, _index, array) => {
      const result = {
        address: item,
        bets: [],
      };

      array.forEach((item2, index2) => {
        if (item2 === item) {
          result.bets.push({
            outcomeId: outcomeIds[index2],
            amount: betAmounts[index2],
            timestamp: timestamps[index2],
          });
        }
      });

      accumulator.push(result);

      return accumulator;
    }, []);

    const uniqParticipants = [
      ...new Map(participants.map((item) => [item["address"], item])).values(),
    ];

    return uniqParticipants;
  }, [eventContract, event]);

  const getEventCoefficients = useCallback(async () => {
    const { 0: optionsIds, 1: coefficients } = await eventContract.methods
      .getCoefficients(event.idOnContract)
      .call();

    const result = await Promise.all(
      optionsIds.map(async (item, index) => {
        const outcomeId = item;
        // process.env.NODE_ENV === "development" // TODO prod
        //   ? item
        //   : await eventContract.methods
        //       .getReverseOutcome(event.idOnContract, item)
        //       .call();
        return {
          outcomeId,
          coefficient: coefficients[index],
        };
      })
    );

    return result;
  }, [eventContract, event]);

  const getUsersInfo = useCallback(async (addresses) => {
    const { result } = await api.event.getEventParticipantsByAddresses({
      addresses,
    });

    return result;
  }, []);

  const getUserInfo = useCallback(async () => {
    const {
      inGame: isInEvent,
      winClaimed: isWinClaimed,
      outcome,
      totalBet,
    } = await eventContract.methods
      .playerInfo(event.idOnContract, account)
      .call();

    let outcomeId = null;

    if (isInEvent) {
      outcomeId = await eventContract.methods
        .getReverseOutcome(event.idOnContract, outcome)
        .call();
    }

    return {
      isInEvent,
      isWinClaimed,
      outcomeId,
      totalBet,
    };
  }, [eventContract, event, account]);

  const onFetchContractInfo = useCallback(async () => {
    const handlers = [getEventInfo(), getEventBets(), getEventCoefficients()];

    // console.log("isAuthenticated", isAuthenticated);
    // console.log("account :>> ", account);

    if (isAuthenticated) {
      handlers.push(getUserInfo());
    }

    const [event, bets, coefficients, user] = await Promise.all(handlers);

    if (isAuthenticated) {
      const userBets = bets.find((item) => item.address === account);

      setUserBets(userBets?.bets || []);
      setIsUserInEvent(user.isInEvent);
      setIsUserClaimWin(user.isWinClaimed);
      setUserOutcomeId(user.outcomeId);
      setUserTotalBet(user.totalBet);

      if (user.isInEvent && event.isOutcomeSet) {
        setIsUserCanClaim(user.outcomeId === event.outcomeId);
      }
    }

    setCreator(event.creator);
    setBets(bets);
    setTotalBets(event.totalBets);
    setCoefficients(coefficients);
    setOutcomeId(event.outcomeId);
    setIsOutcomeSet(event.isOutcomeSet);
    setIsRefundAllowed(event.isRefundAllowed);

    setIsEventLoading(false);
  }, [
    account,
    isAuthenticated,
    getEventInfo,
    getEventBets,
    getEventCoefficients,
    getUserInfo,
  ]);

  useEffect(() => {
    let interval = null;

    if (event && event.idOnContract !== null && eventContract) {
      (async () => {
        await onFetchContractInfo();
        interval = setInterval(onFetchContractInfo, INTERVAL.middle);
      })();
    }

    return () => clearInterval(interval);
  }, [isAuthenticated, eventContract, event, onFetchContractInfo]);

  useEffect(() => {
    if (bets.length) {
      (async () => {
        const users = await getUsersInfo(bets.map(({ address }) => address));
        setUsers(users);
      })();
    }
  }, [bets, getUsersInfo]);

  useEffect(() => {
    if (event && isUserCanClaim && !isUserClaimWin) {
      setTimeout(showConfetti, 1500);
    }
  }, [event, isUserCanClaim, isUserClaimWin]);

  const defaultContext = {
    eventContract,
    tokenContract,
    event,
    creator,
    bets,
    totalBets,
    coefficients,
    outcomeId,
    isOutcomeSet,

    isRefundAllowed,

    isEventLoading,

    isEventBetsEnd,
    isEventStart,
    isEventEnd,

    users,

    isUserInEvent,
    isUserCanClaim,
    isUserClaimWin,
    userOutcomeId,
    userTotalBet,
    userBets,
    onFetchContractInfo,
  };

  return (
    <EventContext.Provider value={defaultContext}>
      {children}
    </EventContext.Provider>
  );
};

export { EventContext, EventContextProvider };
