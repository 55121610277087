import app from "./app";
import user from "./user";
import event from "./event";
import leaderboard from "./leaderboard";

const api = {
  app,
  user,
  event,
  leaderboard,
};

export default api;
