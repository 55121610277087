import React, { useContext, useState } from "react";

import { useSelector } from "react-redux";

import { useWeb3React } from "@web3-react/core";
import Web3Utils from "web3-utils";

import { EventContext } from "../../../contexts/EventContext";

import * as S from "../../../store/selectors";

import {
  Grid,
  Row,
  Col,
  Space,
  Card,
  Button,
  Typography,
  notification,
  message,
} from "antd";
import Container from "../../Container";
import AnimatedNumbers from "../../AnimatedNumbers";

import "./style.less";

const { useBreakpoint } = Grid;
const { Title, Text, Link } = Typography;

const EventRefund = () => {
  const { account } = useWeb3React();
  const { event, eventContract, userTotalBet } = useContext(EventContext);
  const breakpoint = useBreakpoint();

  const isAuthenticated = useSelector(S.profile.selectIsAuthenticated);

  const [isRefundLoading, setIsRefundLoading] = useState(false);

  const onRefund = async () => {
    if (!isAuthenticated) {
      return message.warning("You must be logged in before claim");
    }

    eventContract.methods
      .claimRefund(event.idOnContract)
      .send({ from: account })
      .on("error", (error) => {
        notification.error({
          message: "Transaction error",
          description: error.message,
        });
      })
      .on("transactionHash", (tx) => {
        setIsRefundLoading(true);

        notification.info({
          message: "Transaction send",
          description: (
            <>
              Follow the transaction on{" "}
              <Link
                href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                target="_blank"
                rel="noreferrer"
              >
                {process.env.REACT_APP_EXPLORER_NAME}
              </Link>
            </>
          ),
        });
      })
      .once("confirmation", (_confirmationNumber, receipt) => {
        setIsRefundLoading(false);

        const { status, transactionHash: tx } = receipt;

        if (status) {
          notification.success({
            message: "Transaction completed",
            description: (
              <>
                More info at{" "}
                <Link
                  href={`${process.env.REACT_APP_EXPLORER}tx/${tx}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_EXPLORER_NAME}
                </Link>
              </>
            ),
          });
        }
      });
  };

  return (
    <div className="event-outcome-source">
      <Container>
        <Row gutter={[20, 10]}>
          <Col md={3} span={12}>
            <Title level={2}>Refund</Title>
          </Col>

          <Col md={9} span={12}>
            <Card bordered={false}>
              <Row gutter={[0, { lg: 20, sm: 10, xs: 10 }]}>
                <Col span={12}>
                  <Title level={3}>
                    The event did not take place. Return your bet!
                  </Title>
                </Col>

                <Col span={12}>
                  <Row gutter={[20, 20]}>
                    <Col lg={8} span={12}>
                      <Space direction="vertical" size={0}>
                        <Text>Available to refund:</Text>
                        <AnimatedNumbers
                          value={Web3Utils.fromWei(userTotalBet)}
                          style={{
                            fontSize: breakpoint.sm ? 26 : 20,
                            background:
                              "linear-gradient(142.76deg, #FDC830 13.89%, #F37335 85.84%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            backgroundClip: "text",
                            textFillColor: "transparent",
                          }}
                        />
                      </Space>
                    </Col>

                    <Col lg={4} span={12}>
                      <Button
                        type="primary"
                        block
                        disabled={Web3Utils.fromWei(userTotalBet) <= 0}
                        loading={isRefundLoading}
                        onClick={onRefund}
                      >
                        Refund
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventRefund;
