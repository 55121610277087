const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 65 65"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.415 65h28.167C57.6 65 65 57.271 65 45.77V19.23C65 7.728 57.6 0 46.585 0h-28.17C7.4 0 0 7.729 0 19.23v26.54C0 57.272 7.4 65 18.415 65Zm2.706-35.75c-4.48 0-8.121-3.646-8.121-8.125C13 16.645 16.64 13 21.121 13c4.478 0 8.122 3.645 8.122 8.125s-3.644 8.125-8.122 8.125Zm36.797 12.785c1.088 2.79.523 6.145-.64 8.908-1.38 3.287-4.02 5.776-7.348 6.863-1.477.483-3.026.694-4.572.694h-27.39c-2.726 0-5.137-.654-7.115-1.872-1.238-.764-1.457-2.529-.539-3.672a916.491 916.491 0 0 0 4.582-5.766c2.915-3.703 4.88-4.776 7.063-3.833.885.389 1.774.972 2.69 1.59 2.437 1.656 5.826 3.934 10.29 1.462 3.056-1.711 4.827-4.646 6.37-7.202l.026-.043c.11-.179.218-.358.326-.537.518-.858 1.03-1.705 1.609-2.485.725-.976 3.415-4.028 6.899-1.855 2.219 1.369 4.085 3.22 6.082 5.203a7.33 7.33 0 0 1 1.667 2.545Z"
    />
  </svg>
);

export default svg;
