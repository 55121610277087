import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Typography } from "antd";

import "./style.less";

const { Title } = Typography;

const StatisticCard = ({ type, title, children }) => {
  return (
    <div className={`statistic-card statistic-card-${type}`}>
      <div className="statistic-card-inner">
        <Row gutter={[0, { sm: 15, xs: 10 }]}>
          <Col span={12}>
            <Title level={5}>
              <b>{title}</b>
            </Title>
          </Col>

          <Col span={12}>{children}</Col>
        </Row>
      </div>
    </div>
  );
};

StatisticCard.defaultProps = {
  type: "pink",
  title: "",
};

StatisticCard.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default StatisticCard;
