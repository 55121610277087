import React, { useContext } from "react";

import { useSelector } from "react-redux";

import { subMinutes } from "date-fns";

import Web3Utils from "web3-utils";

import { EventContext } from "../../../contexts/EventContext";

import * as S from "../../../store/selectors";

import { Pagination, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Row, Col, Typography } from "antd";
import {
  StopWatchIcon,
  OraculaIcon,
  CommentBubbleIcon,
  UsersIcon,
} from "../../Icons";
import { StatisticCard } from "../../Card";
import Container from "../../Container";
import BackLink from "../../BackLink";
import Statistic from "../../Statistic";
import Countdown from "../../Countdown";
import AnimatedNumbers from "../../AnimatedNumbers";
import AvatarGroup from "../../AvatarGroup";

import "./style.less";
import "swiper/modules/effect-fade/effect-fade.less";

const { Title } = Typography;

const EventHeader = () => {
  const {
    event,
    totalBets,
    isEventBetsEnd,
    isEventStart,
    isEventEnd,
    isRefundAllowed,
    users,
  } = useContext(EventContext);

  const gradient = useSelector(S.event.selectCategories).find(
    ({ id }) => event.categoryId === id
  ).gradient;

  return (
    <>
      <div className="event-header">
        <div className="event-header-inner">
          <Container>
            <Row>
              <Col span={12}>
                <BackLink />
              </Col>

              <Col className="event-header-title" span={12}>
                <Title>{event.title}</Title>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="event-header-slider">
          <Swiper
            modules={[Pagination, Autoplay, EffectFade]}
            className="event-categories-slider"
            slidesPerView={1}
            loop
            effect="fade"
            speed={500}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={{
              el: ".event-header-slider-pagination",
              clickable: true,
            }}
          >
            {event.coverImages.map((item) => (
              <SwiperSlide key={item.id}>
                <img src={item.image} alt={event.title} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div
            className="event-header-slider-overlay"
            style={{
              backgroundImage: `linear-gradient(177.8deg, rgba(255, 255, 255, 0) 1.85%, rgba(23, 182, 217, 0) 34.48%, rgb(${gradient.stop}) 98.15%)`,
            }}
          />
        </div>

        <div className="event-header-slider-pagination swiper-pagination" />
      </div>

      <div className="event-header-statistic">
        <Container>
          <Row
            gutter={[
              { sm: 20, xs: 10 },
              { sm: 20, xs: 10 },
            ]}
          >
            <Col xl={3} sm={6} span={12}>
              <StatisticCard
                type="pink"
                title={
                  isRefundAllowed
                    ? "Event did not take place"
                    : !isEventBetsEnd
                    ? "Bet acceptance ends in"
                    : !isEventStart
                    ? "The event starts in"
                    : !isEventEnd
                    ? "The event ends in"
                    : "The event ended"
                }
              >
                <Statistic
                  icon={<StopWatchIcon style={{ fontSize: "30px" }} />}
                >
                  {isRefundAllowed ? (
                    <Countdown key="countdown-bets-end" date={new Date()} />
                  ) : !isEventBetsEnd ? (
                    <Countdown
                      key="countdown-bets-end"
                      date={subMinutes(new Date(event.startDate), 5)}
                    />
                  ) : !isEventStart ? (
                    <Countdown
                      key="countdown-event-start"
                      date={new Date(event.startDate)}
                    />
                  ) : (
                    <Countdown
                      key="countdown-event-end"
                      date={new Date(event.endDate)}
                    />
                  )}
                </Statistic>
              </StatisticCard>
            </Col>

            <Col xl={3} sm={6} span={12}>
              <StatisticCard type="green" title="Total pool">
                <Statistic icon={<OraculaIcon />}>
                  <AnimatedNumbers value={Web3Utils.fromWei(totalBets)} />
                </Statistic>
              </StatisticCard>
            </Col>

            <Col xl={3} span={6}>
              <StatisticCard type="orange" title="Comments">
                <Statistic icon={<CommentBubbleIcon />}>
                  <AnimatedNumbers value={0} decimals={0} />
                </Statistic>
              </StatisticCard>
            </Col>

            <Col xl={3} span={6}>
              <StatisticCard type="blue" title="Participants">
                {users.length ? (
                  <AvatarGroup list={users} />
                ) : (
                  <Statistic icon={<UsersIcon />}>
                    <AnimatedNumbers value={0} decimals={0} />
                  </Statistic>
                )}
              </StatisticCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EventHeader;
