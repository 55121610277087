const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M9 12a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm3-4.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 12c0 1.5-1.5 1.5-1.5 1.5h-15S0 21 0 19.5s1.5-6 9-6 9 4.5 9 6Zm-1.5-.006c-.002-.369-.231-1.479-1.248-2.496C14.274 16.02 12.433 15 9 15c-3.435 0-5.274 1.02-6.252 1.998-1.017 1.017-1.245 2.127-1.248 2.496h15Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.25 7.5a.75.75 0 0 1 .75.75v2.25h2.25a.75.75 0 1 1 0 1.5H21v2.25a.75.75 0 1 1-1.5 0V12h-2.25a.75.75 0 1 1 0-1.5h2.25V8.25a.75.75 0 0 1 .75-.75Z"
      clipRule="evenodd"
    />
  </svg>
);

export default svg;
