import React, { useState, useCallback, useEffect } from "react";

import { useSelector } from "react-redux";
import api from "../../api";
import * as S from "../../store/selectors";

import {
  Grid,
  Space,
  Typography,
  Modal,
  Button,
  Form,
  Input,
  Upload,
  notification,
  message,
} from "antd";
import { BugIcon, CloseIcon } from "../Icons";

import "./style.less";

const { useBreakpoint } = Grid;
const { Text } = Typography;
const { TextArea } = Input;

const IssueButton = () => {
  const breakpoint = useBreakpoint();
  const [form] = Form.useForm();

  const userTelegram = useSelector(S.profile.selectProfileInfo).telegram;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isUpload, setIsUpload] = useState(false);

  useEffect(() => {
    if (userTelegram) {
      form.setFieldsValue({ telegram: userTelegram });
    }
  }, [userTelegram, form]);

  const beforeUploadFiles = useCallback((file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG file!");
    }

    const isLt3M = file.size / 1024 / 1024 <= 1;

    if (!isLt3M) {
      message.error("Image must be no larger than 1MB!");
    }

    return false;
  }, []);

  const onSubmit = async ({ ...values }) => {
    console.log("values", values);

    setIsUpload(true);
    const { error } = await api.user.createIssue({
      ...values,
      files: values.files
        ? values.files.fileList.map(({ originFileObj }) => originFileObj)
        : [],
    });
    setIsUpload(false);

    if (error) {
      return notification.error({
        message: "Error",
        description: error.message,
        placement: "top",
      });
    }

    message.success("Issue created!");

    form.resetFields();

    setIsModalVisible(false);
  };

  const onSubmitFailed = () => {
    message.warning("Check the correctness of the filled fields");
  };

  return (
    <>
      <Button
        className="issue-button default-dark-blur"
        icon={<BugIcon />}
        onClick={() => setIsModalVisible(true)}
      />

      <Modal
        width={600}
        wrapClassName="issue-modal"
        title="New issue"
        footer={null}
        centered={true}
        destroyOnClose={true}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        closeIcon={<CloseIcon width="18" height="18" />}
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Form.Item
            name="title"
            label="Title"
            validateTrigger={["onBlur"]}
            rules={[{ required: true }, { type: "string" }]}
          >
            <Input placeholder="Title" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            tooltip="Describe the problem or bug you are experiencing"
            validateTrigger={["onBlur"]}
            rules={[{ required: true }, { type: "string" }]}
          >
            <TextArea placeholder="Description" autoSize={{ minRows: 3 }} />
          </Form.Item>

          <Form.Item
            name="telegram"
            label="Your telegram"
            tooltip="Specify the link to your telegram so that we can contact you"
            validateTrigger={["onBlur"]}
            rules={[{ type: "url" }]}
          >
            <Input placeholder="https://t.me/nickname" />
          </Form.Item>

          <Form.Item name="files">
            <Upload
              listType="picture"
              accept="image/jpeg,image/png"
              maxCount={5}
              multiple
              beforeUpload={beforeUploadFiles}
            >
              <Space direction={breakpoint.md ? "horizontal" : "vertical"}>
                <Button>Attach screenshots</Button>
                <Text type="secondary">
                  <small>Max 5 files. JPEG, PNG (max file size 3MB).</small>
                </Text>
              </Space>
            </Upload>
          </Form.Item>

          <Form.Item style={{ marginBottom: 0, textAlign: "right" }}>
            <Space>
              <Button onClick={() => setIsModalVisible(false)}>Cancel</Button>

              <Button htmlType="submit" type="primary" loading={isUpload}>
                Send issue
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default IssueButton;
