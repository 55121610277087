import React, { useState, useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBase64 } from "../js/utils";
import api from "../api";
import * as S from "../store/selectors";
import * as AT from "../store/actionTypes";

import { Link } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Space,
  Upload,
  Typography,
  Button,
  Form,
  Input,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  CoverUploadIcon,
  AvatarUploadIcon,
  TrashIcon,
} from "../components/Icons";
import AppPage from "../components/App/AppPage";
import Container from "../components/Container";

const { useBreakpoint } = Grid;
const { useForm } = Form;
const { Dragger } = Upload;
const { Title, Text } = Typography;

const ProfileEditor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const [form] = useForm();

  const profileInfo = useSelector(S.profile.selectProfileInfo);

  const [coverImage, setCoverImage] = useState({ preview: null, file: null });
  const [avatarImage, setAvatarImage] = useState({ preview: null, file: null });
  const [isUpdateUserProfile, setIsUpdateUserProfile] = useState(false);

  useEffect(() => {
    const { avatar, cover, nickname, email, telegram, twitter } = profileInfo;

    setCoverImage({ preview: cover, file: null });
    setAvatarImage({ preview: avatar, file: null });

    form.setFieldsValue({ nickname, email, telegram, twitter });
  }, [profileInfo, form]);

  const onRemoveCoverImagePreview = (e) => {
    e.stopPropagation();

    setCoverImage({ preview: null, file: null });
  };

  const beforeUploadCoverImagePreview = useCallback((file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG file!");
    }

    const isLt3M = file.size / 1024 / 1024 <= 3;

    if (!isLt3M) {
      message.error("Image must be no larger than 3MB!");
    }

    if (isJpgOrPng && isLt3M) {
      getBase64(file, (imageUrl) => {
        const img = new Image();

        img.onload = () => {
          setCoverImage({ preview: imageUrl, file });
        };

        img.src = imageUrl;
      });
    }

    return false;
  }, []);

  const onRemoveAvatarImagePreview = (e) => {
    e.stopPropagation();

    setAvatarImage({ preview: null, file: null });
  };

  const beforeUploadAvatarImagePreview = useCallback((file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPEG/PNG file!");
    }

    const isLt1M = file.size / 1024 / 1024 <= 1;

    if (!isLt1M) {
      message.error("Image must be no larger than 1MB!");
    }

    if (isJpgOrPng && isLt1M) {
      getBase64(file, (imageUrl) => {
        const img = new Image();

        img.onload = () => {
          setAvatarImage({ preview: imageUrl, file });
        };

        img.src = imageUrl;
      });
    }

    return false;
  }, []);

  const onSubmit = async ({ ...values }) => {
    setIsUpdateUserProfile(true);
    const { info, isActive, error } = await api.user.udateProfile({
      ...values,
      cover: coverImage,
      avatar: avatarImage,
    });
    setIsUpdateUserProfile(false);

    if (error) {
      return message.error(error.message);
    }

    message.success("Profile updated!");

    dispatch({
      type: AT.profile.SET_PROFILE_INFO,
      payload: { info },
    });
    dispatch({
      type: AT.profile.SET_IS_ACTIVE,
      payload: { isActive },
    });

    navigate("/profile", {
      state: { isNeedVerifyEmail: info.email.length && !info.isEmailConfirmed },
    });
  };

  const onSubmitFailed = () => {
    message.warning("Check the correctness of the filled fields");
  };

  return (
    <AppPage className="page-profile-editor">
      <Container>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
        >
          <Row gutter={[20, { xl: 40, sm: 20, xs: 10 }]}>
            <Col span={12}>
              <ImgCrop aspect={2 / 1}>
                <Dragger
                  className="profile-editor-media-cover"
                  accept="image/jpeg,image/png"
                  showUploadList={false}
                  beforeUpload={beforeUploadCoverImagePreview}
                >
                  {coverImage.preview !== null ? (
                    <>
                      <img src={coverImage.preview} alt="Cover" />
                      <Button
                        className="default-white-blur ant-upload-remove-button"
                        icon={<TrashIcon />}
                        size="small"
                        onClick={onRemoveCoverImagePreview}
                      />
                    </>
                  ) : (
                    <div style={{ width: "100%", padding: 20 }}>
                      <Row gutter={[0, { lg: 20, sm: 10, xs: 10 }]}>
                        <Col span={12}>
                          <Text type="secondary">
                            JPEG, PNG (max file size 3MB).
                          </Text>
                        </Col>

                        <Col span={12}>
                          <Text
                            type="secondary"
                            style={{
                              fontSize: breakpoint.xl ? 65 : 45,
                              lineHeight: 1,
                            }}
                          >
                            <CoverUploadIcon />
                          </Text>
                        </Col>

                        <Col span={12}>
                          <Title
                            level={4}
                            style={{
                              background:
                                "linear-gradient(142.76deg, #9816AD 13.89%, #4A00E0 85.84%)",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              backgroundClip: "text",
                              textFillColor: "transparent",
                            }}
                          >
                            Upload banner image
                          </Title>
                        </Col>

                        <Col span={12}>
                          <Text type="secondary">
                            Drag and Drop File <br /> or browse media on your
                            device
                          </Text>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Dragger>
              </ImgCrop>
            </Col>

            <Col span={12}>
              <Row gutter={[20, 20]}>
                <Col md={4} sm={5} span={12}>
                  <ImgCrop>
                    <Dragger
                      className="profile-editor-media-avatar"
                      accept="image/jpeg,image/png"
                      showUploadList={false}
                      beforeUpload={beforeUploadAvatarImagePreview}
                    >
                      {avatarImage.preview !== null ? (
                        <>
                          <img src={avatarImage.preview} alt="Cover" />
                          <Button
                            className="default-white-blur ant-upload-remove-button"
                            icon={<TrashIcon />}
                            size="small"
                            onClick={onRemoveAvatarImagePreview}
                          />
                        </>
                      ) : (
                        <div style={{ width: "100%", padding: 20 }}>
                          <Row gutter={[0, { xl: 20, sm: 10, xs: 10 }]}>
                            <Col span={12}>
                              <Text type="secondary">
                                <small>JPEG, PNG (max file size 1MB).</small>
                              </Text>
                            </Col>

                            <Col span={12}>
                              <Text
                                type="secondary"
                                style={{
                                  fontSize: breakpoint.xl ? 65 : 45,
                                  lineHeight: 1,
                                }}
                              >
                                <AvatarUploadIcon />
                              </Text>
                            </Col>

                            <Col span={12}>
                              <Title
                                level={5}
                                style={{
                                  background:
                                    "linear-gradient(142.76deg, #9816AD 13.89%, #4A00E0 85.84%)",
                                  WebkitBackgroundClip: "text",
                                  WebkitTextFillColor: "transparent",
                                  backgroundClip: "text",
                                  textFillColor: "transparent",
                                }}
                              >
                                <b>Upload avatar image</b>
                              </Title>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Dragger>
                  </ImgCrop>
                </Col>

                <Col md={8} sm={7} span={12}>
                  <Form.Item
                    name="nickname"
                    label="Nickname"
                    validateTrigger={["onBlur"]}
                    rules={[{ required: true }, { type: "string" }]}
                  >
                    <Input placeholder="Your nickname" />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="Email"
                    tooltip={
                      <Space direction="vertical">
                        <Text>
                          Your email is required in order to notify you about
                          the events you created and it’s statuses as well as
                          the events you’re betting on
                        </Text>

                        <Text type="warning">
                          All information received is private and will be not
                          disclosed by us
                        </Text>
                      </Space>
                    }
                    validateTrigger={["onBlur"]}
                    rules={[{ type: "email" }]}
                  >
                    <Input placeholder="your@email.com" />
                  </Form.Item>

                  <Form.Item
                    name="telegram"
                    label="Telegram link"
                    validateTrigger={["onBlur"]}
                    rules={[{ type: "url" }]}
                  >
                    <Input placeholder="https://t.me/nickname" />
                  </Form.Item>

                  <Form.Item
                    name="twitter"
                    label="Twitter link"
                    validateTrigger={["onBlur"]}
                    rules={[{ type: "url" }]}
                  >
                    <Input placeholder="https://twitter.com/nickname" />
                  </Form.Item>

                  <Form.Item
                    style={{ textAlign: breakpoint.sm ? "right" : "center" }}
                  >
                    <Space>
                      <Link to="/profile" replace>
                        <Button type="primary" ghost>
                          Cancel
                        </Button>
                      </Link>

                      <Button
                        htmlType="submit"
                        type="primary"
                        loading={isUpdateUserProfile}
                      >
                        Save profile
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </AppPage>
  );
};

export default ProfileEditor;
