/* eslint-disable no-unused-vars */
import React, {
  useContext,
  // useState, useCallback, useEffect
} from "react";

// import { useSelector } from "react-redux";
// import { isPast, subMinutes } from "date-fns";
import { EventContext } from "../contexts/EventContext";
// import { useWeb3React } from "@web3-react/core";
// import Web3EthContract from "web3-eth-contract";
// import Web3Utils from "web3-utils";
// import api from "../api";

// import * as S from "../store/selectors";

import { Row, Col, Spin } from "antd";
import AppPage from "../components/App/AppPage";
import EventHeader from "../components/Event/EventHeader";
import EventDescription from "../components/Event/EventDescription";
import EventBets from "../components/Event/EventBets";
import EventRefund from "../components/Event/EventRefund";
import EventPositions from "../components/Event/EventPositions";
// import EventStatistics from "../components/Event/EventStatistics";
import EventOutcomeSource from "../components/Event/EventOutcomeSource";
import EventReview from "../components/Event/EventReview";
// import EventDiscussion from "../components/Event/EventDiscussion";
import EventJackpot from "../components/Event/EventJackpot";

const Event = () => {
  const { event, isRefundAllowed, isEventLoading } = useContext(EventContext);

  // const { account } = useWeb3React();

  // const [commentsCount, setCommentsCount] = useState(0);
  // const [comments, setComments] = useState([]);

  // Comments
  // const getEventComments = useCallback(async () => {
  //   const { result } = await api.event.getEventComments({
  //     id: event.id,
  //   });

  //   setCommentsCount(result.count);
  //   setComments(result.list);
  // }, [event]);

  // useEffect(() => {
  //   let interval = null;

  //   if (event) {
  //     (async () => {
  //       getEventComments();

  //       interval = setInterval(getEventComments, 30000);
  //     })();
  //   }

  //   return clearInterval(interval);
  // }, [event, getEventComments]);

  if (isEventLoading) {
    return <Spin className="page-loading" />;
  }

  return (
    <AppPage className="page-event" coverHeader>
      <Row gutter={[0, { xl: 80, sm: 60, xs: 50 }]}>
        <Col span={12}>
          <EventHeader />
        </Col>

        {!!event.description && (
          <Col span={12}>
            <EventDescription />
          </Col>
        )}

        <Col span={12}>{isRefundAllowed ? <EventRefund /> : <EventBets />}</Col>

        <Col span={12}>
          <EventPositions />
        </Col>

        {!!event.sources.length && (
          <Col span={12}>
            <EventOutcomeSource />
          </Col>
        )}

        <Col span={12}>
          <EventReview />
        </Col>

        {/* <Col span={12}>
          <EventDiscussion />
        </Col> */}

        <Col span={12}>
          <EventJackpot />
        </Col>
      </Row>
    </AppPage>
  );
};

export default Event;
