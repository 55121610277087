import React from "react";
import PropTypes from "prop-types";

import Countdown, { zeroPad } from "react-countdown";

import "./index.less";

const CustomCountdown = ({ date }) => {
  const Completionist = () => (
    <div className="countdown">
      <span className="countdown-item">{zeroPad(0)}</span>:
      <span className="countdown-item">{zeroPad(0)}</span>:
      <span className="countdown-item">{zeroPad(0)}</span>:
      <span className="countdown-item">{zeroPad(0)}</span>
    </div>
  );

  const renderer = ({ formatted: { hours, minutes, seconds }, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div className="countdown">
          <span className="countdown-item">{zeroPad(hours)}</span>:
          <span className="countdown-item">{zeroPad(minutes)}</span>:
          <span className="countdown-item">{zeroPad(seconds)}</span>
        </div>
      );
    }
  };

  return <Countdown date={date} renderer={renderer} daysInHours={true} />;
};

CustomCountdown.propTypes = {
  date: PropTypes.instanceOf(Date),
};

export default CustomCountdown;
