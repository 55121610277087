import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { useWeb3React } from "@web3-react/core";

import * as S from "../store/selectors";

import { Row, Col, Space, Radio } from "antd";
import AppPage from "../components/App/AppPage";
import ProfileHeader from "../components/Profile/ProfileHeader";
import Container from "../components/Container";
import VerifyEmail from "../components/VerifyEmail";
import ProfileBets from "../components/Profile/ProfileBets";
import ProfileEvents from "../components/Profile/ProfileEvents";

const tabs = [
  { value: "BETS", title: "My Bets", disabled: false },
  { value: "EVENTS", title: "My events", disabled: false },
  { value: "COMMENTS", title: "Comments", disabled: true },
  { value: "ISSUES", title: "Issues", disabled: true },
];

const Profile = () => {
  const location = useLocation();
  const { account } = useWeb3React();

  const profileInfo = useSelector(S.profile.selectProfileInfo);
  const oraculaBalance = useSelector(S.profile.selectOraculaBalance);

  const [currentTab, setCurrentTab] = useState(tabs[0].value);

  const [isVerifyModalVisible, setIsVerifyModalVisible] = useState(false);

  useEffect(() => {
    const { state } = location;

    if (state && state.isNeedVerifyEmail) {
      window.history.replaceState({}, document.title);
      setIsVerifyModalVisible(true);
    }
  }, [location]);

  return (
    <>
      <AppPage className="page-profile" coverHeader>
        <Row gutter={[0, { md: 60, sm: 50, xs: 50 }]}>
          <Col span={12}>
            <ProfileHeader
              account={account}
              nickname={profileInfo.nickname}
              email={profileInfo.email}
              avatar={profileInfo.avatar}
              cover={profileInfo.cover}
              telegram={profileInfo.telegram}
              twitter={profileInfo.twitter}
              oraculaBalance={oraculaBalance}
              isPublicProfile={false}
              isVerifiedEmail={profileInfo.isEmailVerified}
              score={profileInfo.score}
              onVerifyEmail={() => setIsVerifyModalVisible(true)}
            />
          </Col>

          <Col span={12}>
            <Container>
              <Row gutter={[0, { md: 60, sm: 50, xs: 50 }]}>
                <Col span={12}>
                  <Radio.Group
                    className="page-profile-tab-nav"
                    defaultValue={currentTab}
                    value={currentTab}
                    onChange={(e) => setCurrentTab(e.target.value)}
                  >
                    {tabs.map((item, index) => (
                      <Radio.Button
                        key={index}
                        value={item.value}
                        disabled={item.disabled}
                      >
                        <Space>{item.title}</Space>
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Col>

                <Col span={12}>
                  {currentTab === "BETS" && <ProfileBets account={account} />}
                  {currentTab === "EVENTS" && (
                    <ProfileEvents account={account} />
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </AppPage>

      <VerifyEmail
        isVisible={isVerifyModalVisible}
        onCancel={() => setIsVerifyModalVisible(false)}
      />
    </>
  );
};

export default Profile;
