const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 14 24"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      d="M12 22 2 12 12 2"
    />
  </svg>
);

export default svg;
