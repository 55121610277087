import React from "react";

import {
  Grid,
  Row,
  Col,
  Space,
  Card,
  Progress,
  Avatar,
  Typography,
} from "antd";
import Container from "../../Container";

import "./style.less";

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const EventReview = () => {
  const breakpoint = useBreakpoint();

  return (
    <div className="event-review">
      <Container>
        <Row gutter={[20, 10]}>
          <Col md={3} span={12}>
            <Title level={2}>Review</Title>
          </Col>

          <Col md={9} span={12}>
            <Row gutter={[0, { sm: 20, xs: 10 }]}>
              <Col className="event-review-item" span={12}>
                <Card bordered={false}>
                  <Row align="middle" gutter={[20, 20]}>
                    <Col sm={6} span={12}>
                      <Space size={20}>
                        <Avatar
                          size={breakpoint.sm ? 80 : 60}
                          src="https://joeschmoe.io/api/v1/random"
                        />

                        <Space direction="vertical" size={0}>
                          <Title level={4}>Nikita Pimanov</Title>
                          <Text type="secondary">
                            <b>2 450 followers</b>
                          </Text>
                        </Space>
                      </Space>
                    </Col>

                    <Col sm={6} span={12}>
                      <Space size={20}>
                        <Progress
                          type="circle"
                          showInfo={false}
                          percent={84}
                          width={breakpoint.sm ? 80 : 60}
                          strokeWidth={10}
                          strokeColor={{
                            "15%": "#ED7CFF",
                            "85%": "#A375FF",
                          }}
                        />

                        <Space direction="vertical" size={0}>
                          <Title
                            level={4}
                            style={{
                              background:
                                "linear-gradient(142.76deg, #ED7CFF 13.89%, #A375FF 85.84%)",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              backgroundClip: "text",
                              textFillColor: "transparent",
                            }}
                          >
                            84%
                          </Title>
                          <Text type="secondary">
                            <b>correct prediction</b>
                          </Text>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventReview;
