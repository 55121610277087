import React, { useState, useEffect } from "react";

import api from "../../../api";

import { Link } from "react-router-dom";
import { Row, Col, Space, Spin, Typography, Card, Button } from "antd";
import EventCardAuthor from "../../Card/EventCardAuthor";

const { Title } = Typography;

const ProfileEvents = () => {
  const [events, setEvents] = useState([]);
  const [eventsLoading, setIsEventsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const { result } = await api.user.getEvents();

      setEvents(result.list);
      setIsEventsLoading(false);
    })();
  }, []);

  if (eventsLoading) {
    return <Spin className="page-loading" />;
  }

  return (
    <Row gutter={[0, { sm: 30, xs: 20 }]}>
      {!!events.length ? (
        events.map((event, index) => (
          <Col key={index} span={12}>
            <EventCardAuthor {...event} />
          </Col>
        ))
      ) : (
        <Col span={12}>
          <Card bordered={false} style={{ textAlign: "center" }}>
            <Space direction="vertical" size={20}>
              <Title level={3}>You don't have any events yet!</Title>

              <Link to="/event/create">
                <Button>Create event</Button>
              </Link>
            </Space>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default ProfileEvents;
