import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { scroller } from "react-scroll";
import { useSelector } from "react-redux";
// import { format } from "date-fns";
// import { utcToZonedTime } from "date-fns-tz";
import api from "../api";
import * as S from "../store/selectors";

import {
  Grid,
  Row,
  Col,
  Space,
  Pagination,
  Radio,
  Typography,
  Spin,
} from "antd";
import {
  FireIcon,
  // TrendingUpIcon
} from "../components/Icons";
import AppPage from "../components/App/AppPage";
import Container from "../components/Container";
import { EventCardTopic, EventCard } from "../components/Card";
import BackLink from "../components/BackLink";

const { useBreakpoint } = Grid;
const { Title } = Typography;

const EVENTS_PAGE_SIZE = 9;

const SUBCATEGORIES = ["category", "tag", "topic"];

const EventSubcategory = () => {
  const navigate = useNavigate();
  const params = useParams();
  const breakpoint = useBreakpoint();

  const sortOptions = useSelector(S.app.selectConfig).eventSortOptions;
  const categories = useSelector(S.event.selectCategories);
  const tags = useSelector(S.event.selectTags);

  const [pageTitle, setPageTitle] = useState("");
  const [isPageParamsGet, setIsPageParamsGet] = useState(false);

  const [currentCategoryId, setCurrentCategoryId] = useState("");
  const [currentTagId, setCurrentTagId] = useState("");
  const [currentTopicId, setCurrentTopicId] = useState("");

  const [topics, setTopics] = useState([]);
  const [isTopicsLoading, setIsTopicsLoading] = useState(true);

  const [eventsPage, setEventsPage] = useState(1);
  const [eventsPageSize] = useState(EVENTS_PAGE_SIZE);
  const [eventsSort, setEventsSort] = useState(
    localStorage.getItem("event_sort_option") || sortOptions[0].value
  );
  const [eventsCount, setEventsCount] = useState(0);
  const [events, setEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(true);
  const [isEventsUpdate, setIsEventsUpdate] = useState(false);

  useEffect(() => {
    const getCategory = (id) => {
      return categories.find((item) => item.id.toString() === id);
    };

    const getTag = (id) => {
      return tags.find((item) => item.id.toString() === id);
    };

    const getTopic = async (id) => {
      const { result, error } = await api.event.getTopic({ id });

      if (error) {
        return undefined;
      }

      return result;
    };

    const { subcategory } = params;

    if (!SUBCATEGORIES.includes(subcategory)) {
      return navigate("/oops", { replace: true });
    }

    const { id } = params;

    if (subcategory === "category") {
      const category = getCategory(id);

      if (!category) {
        return navigate("/oops", { replace: true });
      }

      setCurrentCategoryId(category.id);
      setPageTitle(`Category: ${category.title}`);
      setIsPageParamsGet(true);
    } else if (subcategory === "tag") {
      const tag = getTag(id);

      if (!tag) {
        return navigate("/oops", { replace: true });
      }

      setCurrentTagId(tag.id);
      setPageTitle(`Tag: #${tag.title.toLowerCase()}`);
      setIsPageParamsGet(true);
    } else {
      (async () => {
        const topic = await getTopic(id);

        if (!topic) {
          return navigate("/oops", { replace: true });
        }

        setCurrentTopicId(topic.id);
        setPageTitle(`Topic: ${topic.title}`);
        setIsPageParamsGet(true);
      })();
    }
  }, [params, categories, tags, navigate]);

  useEffect(() => {
    if (isPageParamsGet) {
      if (currentCategoryId) {
        (async () => {
          const { result } = await api.event.getTopics({
            category: currentCategoryId,
          });
          setTopics(result);
          setIsTopicsLoading(false);
        })();
      } else {
        setIsTopicsLoading(false);
      }
    }
  }, [isPageParamsGet, currentCategoryId]);

  const onChangeEventsPage = (page) => {
    setEventsPage(page);
    scroller.scrollTo("allEventsTitle", {
      duration: 500,
      delay: 0,
      offset: -30,
      smooth: true,
    });
  };

  useEffect(() => {
    if (isPageParamsGet) {
      (async () => {
        setIsEventsUpdate(true);

        const {
          result: { count, list },
        } = await api.event.getEvents({
          page: eventsPage,
          pageSize: eventsPageSize,
          sort: eventsSort,
          category: currentCategoryId,
          tag: currentTagId,
          topic: currentTopicId,
          isResultSet: false,
          isRefund: false,
          // gte: format(
          //   utcToZonedTime(new Date(), "UTC"),
          //   "yyyy-MM-dd'T'HH:mm:ss.SSS"
          // ),
        });

        setEventsCount(count);
        setEvents(list);
        setIsEventsUpdate(false);
        setIsEventsLoading(false);
      })();
    }
  }, [
    isPageParamsGet,
    eventsPage,
    eventsPageSize,
    eventsSort,
    currentCategoryId,
    currentTagId,
    currentTopicId,
  ]);

  if (isEventsLoading || isTopicsLoading) {
    return <Spin className="page-loading" />;
  }

  return (
    <AppPage className="page-home">
      <Row gutter={[0, { md: 60, sm: 50, xs: 50 }]}>
        <Col span={12}>
          <Container>
            <Row>
              <Col span={12}>
                <Space>
                  <BackLink title={null} size="small" />
                  <Title level={2}>{pageTitle}</Title>
                </Space>
              </Col>
            </Row>
          </Container>
        </Col>

        {!!topics.length && (
          <Col span={12}>
            <Container>
              <Row gutter={[0, { sm: 30, xs: 20 }]}>
                <Col span={12}>
                  <Title level={3}>
                    <Space>
                      Topics on fire now
                      <FireIcon />
                    </Space>
                  </Title>
                </Col>

                <Col span={12}>
                  <Row gutter={20}>
                    {topics.map((item) => (
                      <Col key={item.id} span={6}>
                        <EventCardTopic {...item} />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        )}

        {/* <Col span={12}>
          <Container>
            <Row gutter={[0, { sm: 30, xs: 20 }]}>
              <Col span={12}>
                <Title level={3}>
                  <Space>
                    <TrendingUpIcon />
                    Trending events
                  </Space>
                </Title>
              </Col>

              <Col span={12}>
                <Row gutter={[20, { md: 40, sm: 20, xs: 20 }]}>
                  {[...Array(3).keys()].map((_item, index) => (
                    <Col key={index} lg={4} md={6} span={12}>
                      <EventCard />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </Col> */}

        {!!events.length && (
          <Col span={12}>
            <Container>
              <Row gutter={[0, { sm: 30, xs: 20 }]}>
                <Col span={12}>
                  <Title id="allEventsTitle" level={3}>
                    <Space
                      direction={breakpoint.md ? "horizontal" : "vertical"}
                    >
                      <Radio.Group
                        size="small"
                        defaultValue={eventsSort}
                        onChange={({ target }) => {
                          localStorage.setItem(
                            "event_sort_option",
                            target.value
                          );
                          setEventsSort(target.value);
                        }}
                      >
                        {sortOptions.map((item, index) => (
                          <Radio.Button key={index} value={item.value}>
                            {item.title}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </Space>
                  </Title>
                </Col>

                <Col span={12}>
                  <Spin spinning={isEventsUpdate}>
                    <Row gutter={[20, 20]}>
                      {events.map((item) => (
                        <Col key={item.id} lg={4} md={6} span={12}>
                          <EventCard {...item} />
                        </Col>
                      ))}
                    </Row>
                  </Spin>
                </Col>

                <Col span={12}>
                  <Pagination
                    pageSize={EVENTS_PAGE_SIZE}
                    current={eventsPage}
                    total={eventsCount}
                    showTotal={false}
                    hideOnSinglePage={true}
                    showSizeChanger={false}
                    onChange={onChangeEventsPage}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        )}
      </Row>
    </AppPage>
  );
};

export default EventSubcategory;
