const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      d="M1.292 1.292a1 1 0 0 1 1.416 0L8 6.586l5.292-5.294a1 1 0 1 1 1.416 1.416L9.414 8l5.294 5.292a1 1 0 0 1-1.416 1.416L8 9.414l-5.292 5.294a1.002 1.002 0 0 1-1.416-1.416L6.586 8 1.292 2.708a1 1 0 0 1 0-1.416Z"
    />
  </svg>
);

export default svg;
