const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M12 22.5a10.5 10.5 0 1 1 0-21 10.5 10.5 0 0 1 0 21Zm0 1.5a12 12 0 1 0 0-24 12 12 0 0 0 0 24Z"
    />
    <path
      fill="currentColor"
      d="M6 12a.75.75 0 0 1 .75-.75h10.5a.75.75 0 1 1 0 1.5H6.75A.75.75 0 0 1 6 12Z"
    />
  </svg>
);

export default svg;
