import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { isPast } from "date-fns";

import Web3EthContract from "web3-eth-contract";

import api from "../../../api";
import * as S from "../../../store/selectors";

import { Link } from "react-router-dom";
import { Row, Col, Space, Card, Button, Typography, Spin } from "antd";
import { Navigation, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { EventCard, EventCardClaim } from "../../Card";
import { ArrowLeftIcon, ArrowRightIcon } from "../../Icons";

import "./style.less";

const { Title } = Typography;

const ProfileBets = ({ account }) => {
  const eventContracts = useSelector(S.app.selectEventContracts);

  const [events, setEvents] = useState([]);
  const [eventsLoading, setIsEventsLoading] = useState(true);

  // CORE events
  useEffect(() => {
    const { abi, address } = eventContracts.find(({ name }) => name === "CORE");
    const contract = new Web3EthContract(JSON.parse(abi), address);

    (async () => {
      const { eventIds } = await contract.methods
        .getAllUserBets(account)
        .call();

      if (!eventIds.length) {
        return setIsEventsLoading(false);
      }

      const ids = [...new Set(eventIds)];

      const { result } = await api.event.getEvents({
        events: ids.reverse().toString(),
      });

      setEvents(result.list);
      setIsEventsLoading(false);
    })();
  }, [account, eventContracts]);

  if (eventsLoading) {
    return <Spin className="page-loading" />;
  }

  return (
    <Row gutter={[0, { md: 60, sm: 50, xs: 50 }]}>
      {!!events.filter(({ endDate }) => isPast(new Date(endDate))).length && (
        <Col span={12}>
          <Row gutter={[0, { sm: 30, xs: 20 }]}>
            <Col span={12}>
              <Row gutter={[20, 20]}>
                <Col span={8}>
                  <Title level={2}>Completed bets</Title>
                </Col>

                <Col span={4} style={{ textAlign: "right" }}>
                  <Space>
                    <Button
                      id="completedBetsPrevButton"
                      icon={<ArrowLeftIcon />}
                      size="small"
                    />
                    <Button
                      id="completedBetsNextButton"
                      icon={<ArrowRightIcon />}
                      size="small"
                    />
                  </Space>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Swiper
                modules={[Navigation, Mousewheel]}
                spaceBetween={20}
                slidesPerView={1}
                mousewheel={{ forceToAxis: true }}
                navigation={{
                  nextEl: "#completedBetsNextButton",
                  prevEl: "#completedBetsPrevButton",
                }}
                breakpoints={{ 992: { slidesPerView: 2 } }}
              >
                {events
                  .filter(({ endDate }) => isPast(new Date(endDate)))
                  .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
                  .map((event) => (
                    <SwiperSlide key={event.id}>
                      <EventCardClaim {...event} account={account} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Col>
          </Row>
        </Col>
      )}

      <Col span={12}>
        <Row gutter={[0, { sm: 30, xs: 20 }]}>
          <Col span={12}>
            <Title level={2}>Current bets</Title>
          </Col>

          <Col span={12}>
            {!!events.filter(({ endDate }) => !isPast(new Date(endDate)))
              .length ? (
              <Row gutter={[20, 20]}>
                {events
                  .filter(({ endDate }) => !isPast(new Date(endDate)))
                  .map((item) => (
                    <Col key={item.id} lg={4} md={6} span={12}>
                      <EventCard {...item} />
                    </Col>
                  ))}
              </Row>
            ) : (
              <Card bordered={false} style={{ textAlign: "center" }}>
                <Space direction="vertical" size={20}>
                  <Title level={3}>
                    You are not participating in any event!
                  </Title>

                  <Link to="/">
                    <Button>View events</Button>
                  </Link>
                </Space>
              </Card>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ProfileBets.propTypes = {
  account: PropTypes.string,
};

export default ProfileBets;
