import { isProduction } from "../js/const";
import { applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

const createEnhancers = () => {
  let devTools = false;

  const middlewares = [];
  middlewares.push(thunk);

  if (!isProduction) {
    devTools =
      typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : null;
  }

  const enhancers = [];
  enhancers.push(applyMiddleware(...middlewares));
  if (devTools) enhancers.push(devTools);

  return compose(...enhancers);
};

export default createEnhancers;
