import React, { useState, useEffect } from "react";

// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

import { BigNumber } from "bignumber.js";
// import { format } from "date-fns";
// import { utcToZonedTime } from "date-fns-tz";
import api from "../api";
// import * as S from "../store/selectors";

import {
  // Grid,
  Row,
  Col,
  Space,
  // Pagination,
  Radio,
  Avatar,
  Input,
  Typography,
  Table,
  Spin,
} from "antd";
import { SearchIcon } from "../components/Icons";
import AppPage from "../components/App/AppPage";
import Container from "../components/Container";

import leaderboardCup from "../assets/images/leaderboard-cup.svg";
import leaderboardFirstPositionCoin from "../assets/images/coins/leaderboard-first-position.svg";
import leaderboardSecondPositionCoin from "../assets/images/coins/leaderboard-second-position.svg";
import leaderboardThirdPositionCoin from "../assets/images/coins/leaderboard-third-position.svg";
import leaderboardBasePositionCoin from "../assets/images/coins/leaderboard-base-position.svg";

// const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const LEADERBOARD_TYPES = [
  { title: "Players", value: "PLAYERS", disabled: false },
  { title: "Authors", value: "AUTHORS", disabled: false },
];

const PLAYER_COLUMNS = [
  {
    title: "№",
    dataIndex: "playerLeaderboardPosition",
    key: "playerLeaderboardPosition",
    width: "104px",
    minWidth: "104px",
    render: (position) => (
      <div className="leaderboard-table-position">
        <img
          src={
            position === 1
              ? leaderboardFirstPositionCoin
              : position === 2
              ? leaderboardSecondPositionCoin
              : position === 3
              ? leaderboardThirdPositionCoin
              : leaderboardBasePositionCoin
          }
          width={40}
          height={40}
          alt="Position"
        />
        <span>{position}</span>
      </div>
    ),
  },
  {
    title: "User",
    dataIndex: "playerLeaderboardUser",
    key: "playerLeaderboardUser",
    width: "30%",
    render: ({ address, nickname, avatar }) => (
      <Link
        className="leaderboard-table-user"
        to={`/user/${address}`}
        style={{ whiteSpace: "nowrap" }}
      >
        <Space>
          <Avatar src={avatar} />
          <Text>{nickname}</Text>
        </Space>
      </Link>
    ),
  },
  {
    title: "Win",
    dataIndex: "playerLeaderboardWin",
    key: "playerLeaderboardWin",
    width: "15%",
  },
  {
    title: "Lose",
    dataIndex: "playerLeaderboardLose",
    key: "playerLeaderboardLose",
    width: "15%",
  },
  {
    title: "Score",
    dataIndex: "playerLeaderboardScore",
    key: "playerLeaderboardScore",
    width: "15%",
  },
  {
    title: "Total earning ($ORC)",
    dataIndex: "playerLeaderboardTotalEarning",
    key: "playerLeaderboardTotalEarning",
    width: "25%",
    render: (amount) => <Text>{new BigNumber(amount).toFixed(2)}</Text>,
  },
];

const AUTHOR_COLUMNS = [
  {
    title: "№",
    dataIndex: "authorLeaderboardPosition",
    key: "authorLeaderboardPosition",
    width: "104px",
    minWidth: "104px",
    render: (position) => (
      <div className="leaderboard-table-position">
        <img
          src={
            position === 1
              ? leaderboardFirstPositionCoin
              : position === 2
              ? leaderboardSecondPositionCoin
              : position === 3
              ? leaderboardThirdPositionCoin
              : leaderboardBasePositionCoin
          }
          width={40}
          height={40}
          alt="Position"
        />
        <span>{position}</span>
      </div>
    ),
  },
  {
    title: "User",
    dataIndex: "authorLeaderboardUser",
    key: "authorLeaderboardUser",
    width: "30%",
    render: ({ address, nickname, avatar }) => (
      <Link
        className="leaderboard-table-user"
        to={`/user/${address}`}
        style={{ whiteSpace: "nowrap" }}
      >
        <Space>
          <Avatar src={avatar} />
          <Text>{nickname}</Text>
        </Space>
      </Link>
    ),
  },
  {
    title: "Events",
    dataIndex: "authorLeaderboardEventCount",
    key: "authorLeaderboardEventCount",
    width: "15%",
  },
  {
    title: "Score",
    dataIndex: "authorLeaderboardScore",
    key: "authorLeaderboardScore",
    width: "15%",
  },
  {
    title: "Bets amount ($ORC)",
    dataIndex: "authorLeaderboardBetAmount",
    key: "authorLeaderboardBetAmount",
    width: "30%",
    render: (amount) => <Text>{new BigNumber(amount).toFixed(2)}</Text>,
  },
];

const Leaderboard = () => {
  // const breakpoint = useBreakpoint();

  const [leaderboardType, setLeaderboardType] = useState(
    LEADERBOARD_TYPES[0].value
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [playerDataSource, setPlayerDataSource] = useState([]);
  const [authorDataSource, setAuthorDataSource] = useState([]);

  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    setIsDataLoading(true);
    if (leaderboardType === "PLAYERS") {
      (async () => {
        const { result } = await api.leaderboard.getPlayersLeaderboard();

        setPlayerDataSource(
          result.map(
            (
              { address, nickname, avatar, win, lose, score, totalEarning },
              index
            ) => ({
              key: index,
              playerLeaderboardPosition: index + 1,
              playerLeaderboardUser: { address, nickname, avatar },
              playerLeaderboardWin: win,
              playerLeaderboardLose: lose,
              playerLeaderboardScore: score,
              playerLeaderboardTotalEarning: totalEarning,
            })
          )
        );
        setIsDataLoading(false);
      })();
    } else {
      (async () => {
        const { result } = await api.leaderboard.getAuthorsLeaderboard();

        setAuthorDataSource(
          result.map(
            (
              { address, nickname, avatar, eventCount, betAmount, score },
              index
            ) => ({
              key: index,
              authorLeaderboardPosition: index + 1,
              authorLeaderboardUser: { address, nickname, avatar },
              authorLeaderboardEventCount: eventCount,
              authorLeaderboardScore: score,
              authorLeaderboardBetAmount: betAmount,
            })
          )
        );
        setIsDataLoading(false);
      })();
    }
  }, [leaderboardType]);

  if (isDataLoading) {
    return <Spin className="page-loading" />;
  }

  return (
    <AppPage className="page-leaderboard">
      <Row gutter={[0, { md: 60, sm: 50, xs: 50 }]}>
        <Col span={12} style={{ textAlign: "center" }}>
          <Space direction="vertical" size="large">
            <img src={leaderboardCup} width={140} height={140} alt="Cup" />
            <Title>Leaderboard</Title>
          </Space>
        </Col>

        <Col span={12}>
          <Container>
            <Row gutter={[0, 20]}>
              <Col id="restoreTableScroll" span={12}>
                <Row>
                  <Col span={6}>
                    <Radio.Group
                      defaultValue={leaderboardType}
                      size="small"
                      onChange={(e) => setLeaderboardType(e.target.value)}
                    >
                      {LEADERBOARD_TYPES.map((item, index) => (
                        <Radio.Button
                          key={index}
                          value={item.value}
                          disabled={item.disabled}
                        >
                          {item.title}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </Col>

                  <Col span={6}>
                    <Input
                      size="small"
                      placeholder="Search by wallet or nickname"
                      suffix={
                        <SearchIcon style={{ color: "rgba(59,55,53, 0.5)" }} />
                      }
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>

              <Col span={12}>
                {leaderboardType === "PLAYERS" ? (
                  <Table
                    className="leaderboard-table"
                    dataSource={playerDataSource.filter(
                      ({ playerLeaderboardUser: { address, nickname } }) =>
                        address
                          .toLowerCase()
                          .indexOf(searchQuery.toLowerCase()) >= 0 ||
                        nickname
                          .toLowerCase()
                          .indexOf(searchQuery.toLowerCase()) >= 0
                    )}
                    columns={PLAYER_COLUMNS}
                    footer={false}
                    pagination={{
                      pageSize: 20,
                      position: ["bottomCenter"],
                      onChange: () =>
                        scroller.scrollTo("restoreTableScroll", {
                          duration: 500,
                          delay: 0,
                          offset: -30,
                          smooth: true,
                        }),
                    }}
                    scroll={{ x: true }}
                    locale={{
                      emptyText: <Text>No data</Text>,
                    }}
                  />
                ) : (
                  <Table
                    className="leaderboard-table"
                    dataSource={authorDataSource.filter(
                      ({ authorLeaderboardUser: { address, nickname } }) =>
                        address
                          .toLowerCase()
                          .indexOf(searchQuery.toLowerCase()) >= 0 ||
                        nickname
                          .toLowerCase()
                          .indexOf(searchQuery.toLowerCase()) >= 0
                    )}
                    columns={AUTHOR_COLUMNS}
                    footer={false}
                    pagination={{
                      pageSize: 20,
                      position: ["bottomCenter"],
                      onChange: () =>
                        scroller.scrollTo("restoreTableScroll", {
                          duration: 500,
                          delay: 0,
                          offset: -30,
                          smooth: true,
                        }),
                    }}
                    scroll={{ x: true }}
                    locale={{
                      emptyText: <Text>No data</Text>,
                    }}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </AppPage>
  );
};

export default Leaderboard;
