const svg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3C20 3.55228 19.5523 4 19 4V4.5778C19 6.15719 18.5325 7.70126 17.6564 9.0154L15.6667 12L17.6564 14.9846C18.5325 16.2987 19 17.8428 19 19.4222V20C19.5523 20 20 20.4477 20 21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21C4 20.4477 4.44772 20 5 20V19.4222C5 17.8428 5.4675 16.2987 6.3436 14.9846L8.33333 12L6.3436 9.0154C5.4675 7.70126 5 6.15719 5 4.5778V4C4.44772 4 4 3.55228 4 3ZM7 20H8.25001L9.456 18.0704C10.631 16.1904 13.369 16.1904 14.544 18.0704L15.75 20H17V19.4222C17 18.2377 16.6494 17.0796 15.9923 16.094L13.9296 13H10.0704L8.0077 16.094C7.35063 17.0796 7 18.2377 7 19.4222V20ZM13.3915 20L12.848 19.1304C12.4563 18.5037 11.5437 18.5037 11.152 19.1304L10.6085 20H13.3915ZM7 4H17V4.5778C17 5.41567 16.8246 6.24029 16.4894 7H7.51065C7.17543 6.24029 7 5.41567 7 4.5778V4ZM8.73703 9L10.0704 11H13.9296L15.263 9H8.73703Z"
      fill="currentColor"
    />
  </svg>
);

export default svg;
