const svg = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 12V8C16 6.34315 14.6569 5 13 5H11C9.34315 5 8 6.34315 8 8V10H5C3.34315 10 2 11.3431 2 13V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V15C22 13.3431 20.6569 12 19 12H16ZM13 7H11C10.4477 7 10 7.44772 10 8V17H14V8C14 7.44772 13.5523 7 13 7ZM16 14V17H20V15C20 14.4477 19.5523 14 19 14H16ZM5 12H8V17H4V13C4 12.4477 4.44772 12 5 12Z"
      fill="currentColor"
    />
  </svg>
);

export default svg;
