import React from "react";
import PropTypes from "prop-types";

import "./style.less";

const AppPage = ({ className, coverHeader, children }) => {
  return (
    <main
      className={`app-page ${className} ${
        coverHeader ? "app-page-cover-header" : ""
      }`}
    >
      {children}
    </main>
  );
};

AppPage.defaultProps = {
  className: "",
  coverHeader: false,
};

AppPage.propTypes = {
  className: PropTypes.string,
  coverHeader: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default AppPage;
