import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
// import { BigNumber } from "bignumber.js";

import Web3Utils from "web3-utils";
import Web3EthContract from "web3-eth-contract";

import * as S from "../../../store/selectors";

// import { Link } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Space,
  Typography,
  Tag,
  Button,
  Tooltip,
  message,
} from "antd";
import {
  OraculaIcon,
  CommentBubbleIcon,
  UsersIcon,
  EditIcon,
  HourglassIcon,
  LeaderboardIcon,
  ZapIcon,
  StopIcon,
} from "../../Icons";
import Statistic from "../../Statistic";
import AnimatedNumbers from "../../AnimatedNumbers";

import "./style.less";

const { useBreakpoint } = Grid;
const { Title, Text, Paragraph } = Typography;

const EventCardAuthor = ({ ...event }) => {
  const breakpoint = useBreakpoint();

  const eventContracts = useSelector(S.app.selectEventContracts);
  const gradient = useSelector(S.event.selectCategories).find(
    ({ id }) => event.categoryId === id
  ).gradient;

  const [eventContract, setEventContract] = useState(null);

  const [totalBets, setTotalBets] = useState("0");
  const [users, setUsers] = useState("0");

  useEffect(() => {
    if (!event.idOnContract) return;

    const { abi, address } = eventContracts.find(
      (item) => item.id === event.contractId
    );

    const eventContract = new Web3EthContract(JSON.parse(abi), address);

    setEventContract(eventContract);
  }, [event.contractId, event.idOnContract, eventContracts]);

  const getTotalBets = useCallback(async () => {
    const { totalBets } = await eventContract.methods
      .eventInfo(event.idOnContract)
      .call();

    return totalBets;
  }, [eventContract, event.idOnContract]);

  const getEventParticipants = useCallback(async () => {
    const { players } = await eventContract.methods
      .getAllBetsInEvent(event.idOnContract)
      .call();

    const participants = [...new Set(players)];

    return participants;
  }, [eventContract, event.idOnContract]);

  useEffect(() => {
    if (event.idOnContract !== null && eventContract) {
      (async () => {
        const [totalBets, participants] = await Promise.all([
          getTotalBets(),
          getEventParticipants(),
        ]);

        setTotalBets(totalBets);
        setUsers(participants.length);
      })();
    }
  }, [event.idOnContract, eventContract, getTotalBets, getEventParticipants]);

  return (
    <div className="event-card-author">
      <div className="event-card-author-image">
        <div
          className="event-card-author-image-overlay"
          style={{
            backgroundImage: `linear-gradient(177.8deg, rgba(255, 255, 255, 0) 1.85%, rgba(23, 182, 217, 0) 34.48%, rgb(${gradient.stop}) 98.15%)`,
          }}
        />

        <img
          src={breakpoint.lg ? event.cardImage : event.coverImages[0].image}
          alt={event.title}
        />
      </div>

      <div className="event-card-author-info">
        <div className="event-card-author-info-inner">
          <Row gutter={[0, 20]}>
            <Col span={12}>
              <Row gutter={[20, { sm: 20, xs: 10 }]}>
                <Col md={4} sm={5} span={12}>
                  {event.status === "PENDING" && (
                    <Tooltip title="Event moderation takes place within 24 hours">
                      <Tag color="orange">
                        <Space size={5}>
                          <HourglassIcon />
                          Under moderation
                        </Space>
                      </Tag>
                    </Tooltip>
                  )}

                  {event.status === "ACCEPTED" && (
                    <Tag color="green">
                      <Space size={5}>
                        <ZapIcon />
                        Published
                      </Space>
                    </Tag>
                  )}

                  {event.status === "REJECTED" && (
                    <Tag color="red">
                      <Space size={5}>
                        <StopIcon />
                        Rejected
                      </Space>
                    </Tag>
                  )}
                </Col>

                <Col
                  md={8}
                  sm={7}
                  span={12}
                  style={{ textAlign: breakpoint.sm ? "right" : "left" }}
                >
                  <Space>
                    <Button
                      size="small"
                      onClick={() => message.info("Coming soon!")}
                    >
                      <EditIcon />
                      Edit event
                    </Button>

                    <Button
                      size="small"
                      onClick={() => message.info("Coming soon!")}
                    >
                      <LeaderboardIcon />
                      Assign result
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Space direction="vertical">
                <Title level={2} ellipsis={{ rows: 1 }}>
                  {event.title}
                </Title>

                <Text>
                  <Paragraph ellipsis={{ rows: 2 }} style={{ marginBottom: 0 }}>
                    {event.description}
                  </Paragraph>
                </Text>
              </Space>
            </Col>

            <Col span={12} style={{ marginTop: "auto" }}>
              <Row gutter={[20, { sm: 20, xs: 10 }]}>
                <Col sm={4} span={6}>
                  <Space direction="vertical">
                    <Title level={5}>Participants</Title>

                    <Statistic icon={<UsersIcon />}>
                      <AnimatedNumbers
                        value={users}
                        decimals={0}
                        style={{ fontSize: breakpoint.xl ? 32 : 22 }}
                      />
                    </Statistic>
                  </Space>

                  {/* {users.length ? (
                    <AvatarGroup list={users} />
                  ) : (
                    <Statistic icon={<UsersIcon />}>
                      <AnimatedNumbers value={0} decimals={0} />
                    </Statistic>
                  )} */}
                </Col>

                <Col sm={4} span={6}>
                  <Space direction="vertical">
                    <Title level={5}>Amount of bets</Title>

                    <Statistic icon={<OraculaIcon />}>
                      <AnimatedNumbers
                        value={Web3Utils.fromWei(totalBets)}
                        style={{ fontSize: breakpoint.xl ? 32 : 22 }}
                      />
                    </Statistic>
                  </Space>
                </Col>

                <Col sm={4} span={12}>
                  <Space direction="vertical">
                    <Title level={5}>Comments</Title>

                    <Statistic icon={<CommentBubbleIcon />}>
                      <AnimatedNumbers
                        value={0}
                        decimals={0}
                        style={{ fontSize: breakpoint.xl ? 32 : 22 }}
                      />
                    </Statistic>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

EventCardAuthor.propTypes = {
  event: PropTypes.object,
};

export default EventCardAuthor;
