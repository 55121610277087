import React, { useContext, useState, useEffect } from "react";

import Web3Utils from "web3-utils";

import { EventContext } from "../../../contexts/EventContext";

import { Row, Col, Collapse, Space, Typography } from "antd";
import Container from "../../Container";
import EventBetsForm from "../EventBetsForm";
import EventBetsAwait from "../EventBetsAwait";
import EventBetsFormClaim from "../EventBetsClaim";
import AnimatedNumbers from "../../AnimatedNumbers";

import HeaderHighlight from "../../../assets/images/highlights/event-collapse-header-highlight.svg";

import "./style.less";

const { Panel } = Collapse;
const { Title, Text } = Typography;

const alphabet = [..."abcdefghijklmnopqrstuvwxyz".toLocaleUpperCase()];

const EventBetsHeader = ({ title, label, coefficient, isUserBet }) => (
  <div className="event-bets-collapse-header">
    <div
      className={
        isUserBet
          ? "event-bets-collapse-header-label event-bets-collapse-header-label-bet"
          : "event-bets-collapse-header-label"
      }
    >
      {label}
    </div>

    <Row align="middle">
      <Col span={6}>
        <Title level={4}>{title}</Title>
      </Col>

      <Col span={6}>
        <Space direction="vertical" size={0}>
          <Text type="secondary">
            <small>Current ratio</small>
          </Text>

          {coefficient <= "0" ? (
            "-"
          ) : (
            <AnimatedNumbers
              value={Web3Utils.fromWei(coefficient)}
              style={{ fontSize: 22 }}
            />
          )}
        </Space>
      </Col>
    </Row>

    <img
      className="event-bets-collapse-header-highlight"
      src={HeaderHighlight}
      alt="Highlight"
    />
  </div>
);

const EventBets = () => {
  const {
    event,
    coefficients,
    outcomeId,
    isOutcomeSet,
    isEventBetsEnd,
    isEventStart,
    isEventEnd,
    isUserInEvent,
    userOutcomeId,
  } = useContext(EventContext);

  const [activeOption, setActiveOption] = useState("");

  useEffect(() => {
    if (isOutcomeSet) {
      setActiveOption(outcomeId);
    }
  }, [outcomeId, isOutcomeSet]);

  return (
    <div className="event-bets">
      <Container>
        <Row gutter={[20, 10]}>
          <Col md={3} span={12}>
            <Title level={2}>Options</Title>
          </Col>

          <Col md={9} span={12}>
            <Row gutter={[0, { sm: 20, xs: 10 }]}>
              <Col span={12}>
                <Collapse
                  accordion
                  destroyInactivePanel
                  activeKey={activeOption}
                  onChange={setActiveOption}
                >
                  {event.options.map((option, index) => (
                    <Panel
                      key={option.id}
                      className={`event-bets-item
                          ${
                            isOutcomeSet
                              ? outcomeId === option.id
                                ? "event-bets-item-win"
                                : "event-bets-item-lose"
                              : ""
                          }
                        `}
                      header={
                        <EventBetsHeader
                          title={option.title}
                          label={alphabet[index]}
                          coefficient={
                            coefficients.find(
                              ({ outcomeId }) => outcomeId === option.id
                            ).coefficient
                          }
                          isUserBet={
                            isUserInEvent && userOutcomeId === option.id
                          }
                        />
                      }
                      collapsible={
                        isOutcomeSet && outcomeId !== option.id
                          ? "disabled"
                          : ""
                      }
                      showArrow={false}
                    >
                      {!isEventBetsEnd && (
                        <EventBetsForm optionId={option.id} />
                      )}

                      {isEventBetsEnd && !isOutcomeSet && (
                        <EventBetsAwait
                          title={
                            !isEventStart
                              ? "End of the bet. Wait the event to start."
                              : !isEventEnd
                              ? "Start of the event. Wait for completion."
                              : "End of the event. Wait for the result."
                          }
                        />
                      )}

                      {isEventEnd && isOutcomeSet && (
                        <EventBetsFormClaim optionId={option.id} />
                      )}
                    </Panel>
                  ))}
                </Collapse>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventBets;
