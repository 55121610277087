import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { BigNumber } from "bignumber.js";
import Web3Utils from "web3-utils";
import Web3EthContract from "web3-eth-contract";
import * as S from "../../../store/selectors";

import { Link } from "react-router-dom";
import {
  Grid,
  Row,
  Col,
  Space,
  Avatar,
  Typography,
  Button,
  Tooltip,
  message,
} from "antd";
import { StatisticCard } from "../../Card";
import {
  EditIcon,
  UserPlusIcon,
  TelegramIcon,
  TwitterIcon,
  CopyIcon,
  VerifyIcon,
  ShareIcon,
} from "../../Icons";
import Container from "../../Container";
import Statistic from "../../Statistic";
import AnimatedNumbers from "../../AnimatedNumbers";

import "./style.less";

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const ProfileHeader = ({
  account,
  nickname,
  email,
  avatar,
  cover,
  telegram,
  twitter,
  oraculaBalance,
  isPublicProfile,
  isVerifiedEmail,
  score,
  onVerifyEmail,
}) => {
  const breakpoint = useBreakpoint();

  const eventContracts = useSelector(S.app.selectEventContracts);

  const [earnings, setEarnings] = useState("0");
  const [win, setWin] = useState(0);
  const [lose, setLose] = useState(0);

  useEffect(() => {
    const { abi, address } = eventContracts.find(({ name }) => name === "CORE");
    const contract = new Web3EthContract(JSON.parse(abi), address);

    const getEventCoefficients = async (eventId, outcomeId) => {
      const { 0: optionsIds, 1: coefficients } = await contract.methods
        .getCoefficients(eventId)
        .call();

      const outcomesIds = optionsIds;
      // process.env.NODE_ENV === "development" // TODO prod
      //   ? optionsIds
      //   : await Promise.all(
      //       optionsIds.map(
      //         async (id) =>
      //           await contract.methods.getReverseOutcome(eventId, id).call()
      //       )
      //     );

      return coefficients[outcomesIds.findIndex((id) => id === outcomeId)];
    };

    const getEventsWithOutcome = async (eventIds, betAmounts, outcomeIds) => {
      const res = await Promise.all(
        eventIds.map(async (id, i) => {
          const { outcomeSet, realOutcome } = await contract.methods
            .eventInfo(id)
            .call();

          if (!outcomeSet) return;

          const outcomeId = await contract.methods
            .getReverseOutcome(id, realOutcome)
            .call();
          const coefficient = await getEventCoefficients(id, outcomeId);

          return {
            id,
            amount: betAmounts[i],
            coefficient,
            isWin: outcomeIds[i] === outcomeId,
          };
        })
      );

      return res.filter((item) => item !== undefined);
    };

    (async () => {
      const { eventIds, betAmounts, outcomeIds } = await contract.methods
        .getAllUserBets(account)
        .call();
      const eventsWithOutcome = await getEventsWithOutcome(
        eventIds,
        betAmounts,
        outcomeIds
      );

      const totalEarnings = eventsWithOutcome.reduce((acc, item) => {
        if (item.isWin) {
          const amount = new BigNumber(Web3Utils.fromWei(item.amount))
            .multipliedBy(Web3Utils.fromWei(item.coefficient))
            .toFixed();
          acc = new BigNumber(acc).plus(amount).toFixed();
        }

        return acc;
      }, "0");
      setEarnings(totalEarnings);

      const uniqueEventsWithOutcome = [
        ...new Map(
          eventsWithOutcome.map((item) => [item["id"], item])
        ).values(),
      ];
      setWin(uniqueEventsWithOutcome.filter(({ isWin }) => isWin).length);
      setLose(uniqueEventsWithOutcome.filter(({ isWin }) => !isWin).length);
    })();
  }, [account, eventContracts]);

  return (
    <>
      <div className="profile-header">
        <div className="profile-header-inner">
          <Container>
            <Row align={breakpoint.sm ? "middle" : "top"} gutter={[20, 20]}>
              <Col
                span={12}
                style={{ textAlign: breakpoint.sm ? "left" : "center" }}
              >
                <Space
                  align={breakpoint.sm ? "start" : "center"}
                  direction={breakpoint.sm ? "horizontal" : "vertical"}
                  size={20}
                >
                  <Avatar
                    src={avatar}
                    shape="square"
                    size={breakpoint.xl ? 160 : 140}
                  />

                  <Space direction="vertical" size={10}>
                    <Space>
                      <Title
                        level={3}
                        copyable={{
                          text: `${window.location.origin}/user/${account}`,
                          tooltips: <Text>Share</Text>,
                          icon: <ShareIcon />,
                        }}
                      >
                        {nickname}
                      </Title>

                      {email.length ? (
                        isVerifiedEmail ? (
                          <Tooltip placement="top" title="Verified account">
                            <Text type="success">
                              <VerifyIcon />
                            </Text>
                          </Tooltip>
                        ) : (
                          <Button
                            className="default-white-blur"
                            size="small"
                            onClick={onVerifyEmail}
                          >
                            Verify email
                          </Button>
                        )
                      ) : (
                        ""
                      )}
                    </Space>

                    <Text copyable={{ text: account, icon: <CopyIcon /> }}>
                      {`${account.substring(0, 6)}...${account.substring(
                        account.length - 4,
                        account.length
                      )}`}
                    </Text>

                    <Title level={4}>
                      <AnimatedNumbers
                        value={Web3Utils.fromWei(oraculaBalance)}
                        style={{ fontSize: "inherit" }}
                      />{" "}
                      $ORC
                    </Title>

                    <Space>
                      {!isPublicProfile && (
                        <Link to="editor">
                          <Button
                            className="default-white-blur"
                            size={breakpoint.md ? "middle" : "small"}
                          >
                            <EditIcon />
                            Edit profile
                          </Button>
                        </Link>
                      )}

                      {isPublicProfile && (
                        <Button
                          className="default-white-blur"
                          size={breakpoint.md ? "middle" : "small"}
                          onClick={() => message.info("Coming soon!")}
                        >
                          <UserPlusIcon />
                          Follow
                        </Button>
                      )}

                      {telegram && (
                        <a
                          href={telegram}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            className="default-white-blur"
                            icon={<TelegramIcon />}
                            size={breakpoint.md ? "middle" : "small"}
                          />
                        </a>
                      )}

                      {twitter && (
                        <a
                          href={twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            className="default-white-blur"
                            icon={<TwitterIcon />}
                            size={breakpoint.md ? "middle" : "small"}
                          />
                        </a>
                      )}
                    </Space>
                  </Space>
                </Space>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="profile-header-cover">
          <img src={cover} alt="Profile cover" />
        </div>
      </div>

      <div className="profile-header-statistic">
        <Container>
          <Row
            gutter={[
              { sm: 20, xs: 10 },
              { sm: 20, xs: 10 },
            ]}
          >
            <Col xl={3} sm={6} span={12}>
              <StatisticCard type="pink" title="Total earnings">
                <Statistic>
                  <AnimatedNumbers value={earnings} />
                </Statistic>
              </StatisticCard>
            </Col>

            <Col xl={3} sm={6} span={12}>
              <StatisticCard type="green" title="Score">
                <Statistic>
                  <AnimatedNumbers value={score} decimals={0} />
                </Statistic>
              </StatisticCard>
            </Col>

            <Col xl={3} span={6}>
              <StatisticCard type="orange" title="Win">
                <Statistic>
                  <AnimatedNumbers value={win} decimals={0} />
                </Statistic>
              </StatisticCard>
            </Col>

            <Col xl={3} span={6}>
              <StatisticCard type="blue" title="Lose">
                <Statistic>
                  <AnimatedNumbers value={lose} decimals={0} />
                </Statistic>
              </StatisticCard>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

ProfileHeader.defaultProps = {
  account: "",
  nickname: "",
  email: "",
  avatar: null,
  cover: null,
  telegram: "",
  twitter: "",
  oraculaBalance: "0",
  isPublicProfile: true,
  isVerifiedEmail: false,
  score: 0,
  onVerifyEmail: () => null,
};

ProfileHeader.propTypes = {
  account: PropTypes.string,
  nickname: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string,
  cover: PropTypes.string,
  telegram: PropTypes.string,
  twitter: PropTypes.string,
  oraculaBalance: PropTypes.string,
  isPublicProfile: PropTypes.bool,
  isVerifiedEmail: PropTypes.bool,
  score: PropTypes.number,
  onVerifyEmail: PropTypes.func,
};

export default ProfileHeader;
