const svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width="1em"
    height="1em"
    viewBox="0 0 58 58"
  >
    <path
      fill="currentColor"
      d="M29 48.291a11.263 11.263 0 0 1-5.16-1.281 9.162 9.162 0 0 1-5.036-8.335v-3.57c0-.844.683-1.527 1.527-1.527h17.308a1.525 1.525 0 0 1 1.526 1.526v3.053A10.195 10.195 0 0 1 29 48.29ZM21.857 36.57v2.107a6.35 6.35 0 0 0 3.388 5.586 8.24 8.24 0 0 0 3.755.946 7.142 7.142 0 0 0 7.112-7.051V36.6l-14.256-.03ZM21.734 24.36a1.527 1.527 0 0 1-1.312-.763 4.457 4.457 0 0 0-7.876 0 1.532 1.532 0 0 1-2.655-1.526 7.507 7.507 0 0 1 13.156 0 1.526 1.526 0 0 1-1.313 2.29ZM46.798 24.36a1.527 1.527 0 0 1-1.313-.763 4.457 4.457 0 0 0-7.875 0 1.532 1.532 0 0 1-2.656-1.526 7.508 7.508 0 0 1 13.157 0 1.526 1.526 0 0 1-1.313 2.29Z"
    />
    <path
      fill="currentColor"
      d="M29 58a29 29 0 1 1 29-29 29.028 29.028 0 0 1-29 29Zm0-54.948A25.945 25.945 0 0 0 3.053 29a25.946 25.946 0 0 0 44.294 18.347 25.947 25.947 0 0 0 7.6-18.348A25.978 25.978 0 0 0 28.999 3.052l.001.001Z"
    />
  </svg>
);

export default svg;
