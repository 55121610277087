import React from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";

import "./style.less";

const FormatInput = ({
  value,
  placeholder,
  suffix,
  decimalScale,
  onChange,
  onBlur,
}) => {
  return (
    <NumberFormat
      className="format-input"
      value={value}
      type="text"
      placeholder={placeholder}
      thousandSeparator=" "
      allowNegative={false}
      decimalScale={decimalScale}
      fixedDecimalScale={true}
      isNumericString={true}
      allowLeadingZeros={true}
      suffix={suffix}
      renderText={(value, props) => <div {...props}>{value}</div>}
      onValueChange={onChange}
      onBlur={onBlur}
    />
  );
};

FormatInput.defaultProps = {
  value: 0,
  placeholder: "Amount",
  decimalScale: 0,
};

FormatInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffix: PropTypes.node,
  placeholder: PropTypes.string,
  decimalScale: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default FormatInput;
