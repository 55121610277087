import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import api from "../../api";
import * as S from "../../store/selectors";
import * as AT from "../../store/actionTypes";

import {
  Modal,
  Row,
  Col,
  Typography,
  Button,
  Form,
  Input,
  message,
} from "antd";
import { CloseIcon } from "../Icons";

const { useForm } = Form;
const { Text } = Typography;

const VerifyEmail = ({ isVisible, onCancel }) => {
  const dispatch = useDispatch();
  const [form] = useForm();

  const profileInfo = useSelector(S.profile.selectProfileInfo);

  const [isGetCodeLoading, setIsGetCodeLoading] = useState(false);
  const [isSendCodeLoading, setIsSendCodeLoading] = useState(false);

  const onGetCode = async () => {
    setIsGetCodeLoading(true);
    const { result, error } = await api.user.onGetVerifyCode();
    setIsGetCodeLoading(false);

    if (error) {
      return message.warning(error.message);
    }

    message.success(result.message);
  };

  const onSubmit = async ({ code }) => {
    setIsSendCodeLoading(true);
    const { result, error } = await api.user.onSendVerifyCode({ code });
    setIsSendCodeLoading(false);

    if (error) {
      return message.warning(error.message);
    }

    message.success(result.message);
    form.resetFields();
    onCancel();

    const { info } = await api.user.getProfile();
    dispatch({
      type: AT.profile.SET_PROFILE_INFO,
      payload: { info },
    });
  };

  const onSubmitFailed = () => {
    message.warning("Check the correctness field");
  };

  return (
    <Modal
      width={400}
      wrapClassName="verify-email-modal"
      title="Verify email"
      footer={null}
      centered={true}
      destroyOnClose={true}
      visible={isVisible}
      onCancel={onCancel}
      closeIcon={<CloseIcon width="18" height="18" />}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onSubmit}
        onFinishFailed={onSubmitFailed}
      >
        <Form.Item
          name="code"
          label={false}
          extra={`Email: ${profileInfo.email}`}
          validateTrigger={["onBlur"]}
          rules={[{ required: true }]}
        >
          <Row gutter={10} wrap={false}>
            <Col flex={2}>
              <Input size="small" placeholder="Code" />
            </Col>

            <Col flex={1}>
              <Button block loading={isGetCodeLoading} onClick={onGetCode}>
                Get code
              </Button>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            block
            loading={isSendCodeLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default VerifyEmail;
