import * as AT from "./actionsTypes";

const initialState = {
  info: null,
  isActive: false,
  isAuthenticated: false,
  isProfileLoading: true,

  oracula: {
    balance: "0",
  },
};

const profileReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case AT.SET_PROFILE_INFO:
      const { info } = payload;

      return {
        ...state,
        info,
      };

    case AT.SET_IS_ACTIVE:
      const { isActive } = payload;

      return {
        ...state,
        isActive,
      };

    case AT.SET_IS_AUTHENTICATED:
      const { isAuthenticated } = payload;

      return {
        ...state,
        isAuthenticated,
      };

    case AT.SET_IS_PROFILE_LOADING:
      const { isProfileLoading } = payload;

      return {
        ...state,
        isProfileLoading,
      };

    case AT.SET_ORACULA_BALANCE:
      const { balance } = payload;

      return {
        ...state,
        oracula: {
          ...state.oracula,
          balance,
        },
      };

    default:
      return state;
  }
};

export default profileReducer;
