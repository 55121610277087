import { createStore } from "redux";
import createEnhancers from "./enhancers";
import rootReducer from "./rootReducer";

const initialState = {};

const store = createStore(
  rootReducer,
  Object.assign({}, initialState),
  createEnhancers()
);

export default store;
